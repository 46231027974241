import { useContext, useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import TextAreaInputField from "../inputs/TextAreaInputField";
import Dropdown from "../inputs/Dropdown";
import { get, post, put } from "../../utility/fetch";
import { get as gets } from "../../utility/services";
import UnratifiedPromotionalChange from "../data update dropdowns/UnratifiedPromotionalChange";
import UnratifiedPersonnelTransfer from "../data update dropdowns/UnratifiedPersonnelTransfer ";
import UneffectedSteppings from "../data update dropdowns/Un-effectedSteppings";
import NameDeletion from "../data update dropdowns/NameDeletion";
import Nomenclature_TSA_Development from "../data update dropdowns/Nomenclature_TSA_Development";
import PayrollCorrection from "../data update dropdowns/PayrollCorrection";
import UnratifiedAccountChange from "../data update dropdowns/UnratifiedAccountChange";
import UnratifiedNameChange from "../data update dropdowns/UnratifiedNameChange";
import UploadButton from "../inputs/UploadButton";
import Cookies from "js-cookie";
import ApproverDropdown from "../inputs/ApproverDropdown";
import Notification from "../../utility/notification";
import { RiDeleteBinLine } from "react-icons/ri";
import MyContext from "../../utility/Context";
import InitiateExit from "./InitiateExit";
import { feedback } from "../modal/feedback";
import OnboardingDetails from "../page modifiers/OnboardingDetails";
import OnboardingDetailsEdit from "../page modifiers/OnboardingDetailsEdit";
import axios from "axios";
import SelectInput from "../inputs/SelectInput";

const DataUpdate = () => {
    const { setUpdateToBeViewed, updateToBeViewed, setUserRin, userRin, myRecord, setMyRecord, sentBack } = useContext(MyContext);

    const [documentArray, setdocumentArray] = useState([])
    const [requiredDocumentCount, setrequiredDocumentCount] = useState(0)
    const [UpdateOptions, setUpdateOptions] = useState([])
    const [approvers, setApprovers] = useState([])
    const [through, setThrough] = useState([])
    const [updateRecords, setupdateRecords] = useState(false);
    const [UpdateMenu, setUpdateMenu] = useState("");
    const [urgency, setUrgency] = useState("");
    const [mdas, setMdas] = useState([]);
    const [userProfile, setUserProfile] = useState(null)

    const [approvalMDA, setapprovalMDA] = useState([])
    const [employeeName, setemployeeName] = useState([])
    const [staffId, setStaffId] = useState(null)
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(Number)
    const [recordId, setRecordId] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [comment, setComment] = useState('')




    const authStatus = Cookies.get('authStatus');
    const [mFiles, setMFiles] = useState([])
    const [m_File, setM_File] = useState('')
    const [mDocs, setMDocs] = useState([])


    const [docNames, setDocNames] = useState([])
    const [thoroughMda, setThroughMda] = useState([])
    const [throughName, setThroughName] = useState([])
    const [throughArray] = useState([]);
    const [formValues, setFormValues] = useState({
        subject: updateToBeViewed?.subject || '',
        details: updateToBeViewed?.details || ''
    })

    const deleteDoc = (doc) => {
        let newRegularArr = documentArray.filter((id) => id.name !== doc);
        let newMdocsArr = mDocs.filter((mdoc) => mdoc.label !== doc);
        setdocumentArray(newRegularArr);
        setMDocs(newMdocsArr);
    }






    const [userDependants, setUserDependants] = useState({});
    const [userEducation, setUserEducation] = useState({});
    const [userProfessional, setUserProfessional] = useState({});
    const [userNextOfKins, setUserNextOfKins] = useState({});
    const [wrongStaffId, setWrongStaffId] = useState(false);
    const [payload, setPayload] = useState({});

    const initiatorId = Cookies.get('userId')

    console.log(documentArray, searchValue)
    useEffect(() => {

        if (updateToBeViewed && myRecord) {
            console.log(updateToBeViewed)
            window.scrollTo(0, 0);
            document.title = "EDSG Profile";
            fetchMdas();
            getUpdateTypes();


            console.log(staffId)


            setUserProfile(updateToBeViewed?.updateRequestEmployeeRecord)
            setUserDependants(updateToBeViewed?.updateRequestEmployeeRecord);
            setUserEducation(updateToBeViewed?.updateRequestEmployeeRecord?.updateRequestEducationalRecords);
            setUserProfessional(updateToBeViewed?.updateRequestEmployeeRecord?.updateRequestProfessionalQualifications);
            setUserNextOfKins(updateToBeViewed?.updateRequestEmployeeRecord);
            setUserRin(updateToBeViewed?.employeeRIN)
            setRecordId || 0(updateToBeViewed?.id || 0)
        }
    }, []);

    useEffect(() => {
        if (!sentBack) {
            const fetchData = async () => {
                if (userRin && !staffId) {
                    await fetchUserData(userRin);
                }
                await fetchMdas();
                await getUpdateTypes();
                setMyRecord(false);
                setUserRin(null);
                window.scrollTo(0, 0);
                document.title = 'EDSG - Dashboard';

                const fetchDetails = async (id) => {
                    const dependants = await get(`dependants/${id}`);
                    const education = await get(`education/${id}`);
                    const professional = await get(`profcerts/${id}`);
                    const nextOfKin = await get(`nok/${id}`);

                    const result = {};

                    if (dependants.length > 0) result.dependants = dependants;
                    if (education.length > 0) result.education = education;
                    if (professional.length > 0) result.professional = professional;
                    if (nextOfKin.length > 0) result.nextOfKin = nextOfKin;

                    return result;
                };


                try {

                    const staffResponse = staffId ? await fetchDetails(staffId) : null;
                    const userResponse = userId ? await fetchDetails(userId) : null;

                    if (staffResponse) {
                        await fetchProfile(staffId);
                        if (staffResponse.dependants) setUserDependants(staffResponse.dependants);
                        if (staffResponse.education) setUserEducation(staffResponse.education);
                        if (staffResponse.professional) setUserProfessional(staffResponse.professional);
                        if (staffResponse.nextOfKin) setUserNextOfKins(staffResponse.nextOfKin);
                    }

                    if (userResponse) {
                        await fetchProfile(userId);
                        if (userResponse.dependants) setUserDependants(userResponse.dependants);
                        if (userResponse.education) setUserEducation(userResponse.education);
                        if (userResponse.professional) setUserProfessional(userResponse.professional);
                        if (userResponse.nextOfKin) setUserNextOfKins(userResponse.nextOfKin);
                    }

                    setWrongStaffId(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setWrongStaffId(true);
                }
            };

            fetchData();
        }
    }, [staffId, userId, sentBack]);








    const clearForm = () => {
        setFormValues({
            subject: '',
            details: ''
        })
        setUrgency("")
        setUpdateMenu('')
        setapprovalMDA([])
        setThroughName([])
        setemployeeName([])
        setThroughMda([])
        setdocumentArray([]);
        setUpdateToBeViewed({})
        setMyRecord(false);
        setUserId('')
        setUserDependants([])
        setUserEducation([])
        setUserProfessional([])
        setUserNextOfKins([])
        setApprovers([]);
        setUserRin('')
        setStaffId('')
        setUserProfile(null)
        setPayload({})
        setRecordId || 0(0)
    }







    console.log(documentArray, approvers, authStatus)

    const handlechange = (e) => {
        const { name, value } = e.target
        setFormValues((prev) => (
            {
                ...prev,
                [name]: value
            }
        ))
    }

    const getUpdateTypes = async () => {
        try {
            const Response = await get(`UpdateType/list`)
            if (Response) {
                console.log(Response)
                setUpdateOptions(Response.map((option) => ({ value: option.id, label: option.typeTitle })))

            }
            else {
                Notification({
                    type: 'danger',
                    title: 'Error',
                    message: 'Something went wrong please refresh your page'
                })
            }
        } catch (error) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: 'Failed to fetch update types'
            })
        }
    }


    const isFormValid = (data) => {
        const missingFields = [];

        const validateFields = (obj, prefix = '') => {
            for (const key in obj) {
                if (key === 'middleName' || key === 'mothersMaidenName' || key === 'remarks' || key === 'mothersMaidenName' || key === 'salaryStructureByTypeId'
                    || key === 'updateRequestProfessionalQualifications' || key === 'dateOfConfirmation' || key === 'certExpirationDate' || key === 'nextApproverId'
                    || key === 'depName' || key === 'fileNumber' || key === 'depAddress' || key === 'depRelation' || key === 'depDoB' || key === 'depMobile') {
                    continue;
                }
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    validateFields(obj[key], `${prefix}${key}.`);
                } else {
                    if (obj[key] === null || obj[key] === undefined || (typeof obj[key] === 'string' && obj[key].trim() === '')) {
                        missingFields?.push(`${prefix}${key}`);
                    } else if (key === 'nin' || key === 'mobile') {
                        // Validate nin and mobile fields with optional country code prefix and exactly 11 digits
                        if (!/^(\+?\d{1,3})?\d{11}$/.test(obj[key])) {
                            missingFields?.push(`${prefix}${key}`);
                        }
                    }
                }
            }
        }

        validateFields(data);

        console.log("Missing fields:", missingFields);

        return { isValid: missingFields?.length === 0, missingFields };
    };

    const renameFields = (fields) => {
        const renamedFields = fields.map((field) => {
            let fieldName = field.replace('updateRequestEmployeeRecord.', '');
            console.log(fieldName);

            if (!fieldName.startsWith('staffId')) {
                fieldName = fieldName.replace(/Id$/, '');
            }
            if (fieldName.toLowerCase() === 'depdob') {
                fieldName = 'Dep Dob';
            }
            if (fieldName.toLowerCase() === 'nokdob') {
                fieldName = 'Nok Dob';
            }
            if (fieldName == 'salaryStructure') {
                fieldName = 'Salary Structure ( Grade/Level )';
            }

            if (fieldName.startsWith('updateRequestEducationalRecords.')) {
                fieldName = ` educational records(${fieldName.replace('updateRequestEducationalRecords.', '')})`;
            } else if (fieldName.startsWith('updateRequestProfessionalQualifications.')) {
                fieldName = ` professional qualifications(${fieldName.replace('updateRequestProfessionalQualifications.', '')})`;
            }
            return fieldName;
        });
        return renamedFields;
    };


    const submit = async () => {
        const combinedDocs = [...documentArray, ...mDocs];
        const approverlist = [...through, ...approvers]

        if (approverlist.length <= 0) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: "Please select an approver"
            })
            return
        }

        if (formValues?.details?.length < 5) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: "Details should be at least 5 characters"
            })
            return
        }

        if (formValues?.subject?.length < 5) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: "Subject should be at least 5 characters"
            })
            return
        }

        // if (requiredDocumentCount > documentArray?.length) {
        //     Notification({
        //         type: 'danger',
        //         title: 'Error',
        //         message: 'Please upload all required documents'
        //     })
        //     return
        // } else if (documentArray?.length > requiredDocumentCount) {
        //     Notification({
        //         type: 'danger',
        //         title: 'Error',
        //         message: "You've exceeded the total number of required documents"
        //     })
        //     return
        // }

        const document = combinedDocs.map((doc) => ({
            filePath: doc.fileurl || doc.name, // Use fileurl if available, otherwise use name
            userId: Number(userID)
        }));

        let userData;

        if (payload) {
            console.log("UserData before transformation:", payload);

            userData = {
                ...payload,
                firstAppCadre: payload?.firstAppCadre?.label?.toString() || payload?.firstAppCadre?.toString(),
                currentCadre: payload?.currentCadre?.label?.toString() || payload?.currentCadre?.toString(),
                mdaId: payload.mdaId?.id || payload?.mdaId || 0,
                salaryStructureId: payload.salaryStructureId?.value || payload?.salaryStructureId || 0,
                bankId: payload.bankId?.value || payload?.bankId || 0,
                religionId: payload.religionId?.id || payload?.religionId || 0,
            };

            console.log("UserData after transformation:", userData);
        }



        let payLoad = {
            updateId: recordId || 0,
            subject: formValues?.subject,
            from: fullName,
            details: formValues?.details,
            employeeRIN: Number(payload?.employeeRIN),
            urgencyStatus: urgency,
            initiatorUserId: Number(userID),
            employeeUserID: Number(userID),
            nextApproverId: approverlist[0]?.userID,
            updateTypeId: UpdateMenu,
            updateRequestToApprovers: approverlist,
            updateRequestDocuments: document,
            updateRequestMFiles: document,
            actionTaken: 'Submit',
            updateRequestEmployeeRecord: userData
        }
        console.log(payLoad, payload)

        const { isValid, missingFields } = isFormValid(payLoad);

        if (true) {
            try {
                const Response = await post(`update/submit-update`, payLoad)
                if (Response !== 0) {
                    Notification({
                        type: 'success',
                        title: 'Success',
                        message: ' Your Update Request has been Successfully Submitted'
                    })
                    clearForm()
                } else if (Response == 0) {
                    Notification({
                        type: 'danger',
                        title: 'Error',
                        message: 'Failed to Submit'
                    })
                } else {
                    Notification({
                        type: 'danger',
                        title: 'Error',
                        message: 'Something went wrong'
                    })
                }
            } catch (error) {
                Notification({
                    type: 'danger',
                    title: 'Error',
                    message: 'Something went wrong'
                })
            }
        } else {
            const missingFieldsArray = [];
            if (missingFields) {
                missingFieldsArray.push(...missingFields);
            }

            const displayMissingFields = renameFields(missingFieldsArray)


            const missingFieldNames = displayMissingFields.map(fieldName => {
                // Convert camelCase field names to sentence case
                const formattedFieldName = fieldName.replace(/([A-Z])/g, ' $1').toLowerCase();
                return formattedFieldName;
            }).join(", ");
            const feedbackMessage = `Please fill in all required fields: ${missingFieldNames} before submitting.`;
            console.log(isValid, feedbackMessage, missingFieldNames, missingFieldsArray)
            Notification({
                type: 'warning',
                title: "Form not complete",
                message: feedbackMessage,
                iconType: "warning",
            });
            console.log("Please fill in all required fields before submitting.");
        }


        console.log(Response)
    }

    const fetchProfile = async (userId) => {
        await get(`bio/${userId}`)
            .then(response => {
                setUserProfile(response?.data)
                console.log("User DATA::", response);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }



    const handleInputChange = (value) => {
        console.log(value);
        if (!isNaN(value) && value?.length <= 11) {
            // Validate if the value is a number and has a length of 11
            setUserRin(value)
        } else {
            setUserRin(value)
        }
        console.log('Rin', value, userRin)
    };

    const fetchUserData = async () => {
        setLoading(true);
        clearForm()
        await get(`fetchuser?staffid=${userRin}`)
            .then(response => {
                setUserId(response?.id)
                setStaffId(response?.staffID)
                console.log(staffId)
                feedback({
                    title: "Success",
                    text: "Successfully fetched user records",
                    iconType: "success",
                });
                setLoading(false);
                console.log("User DATA::", response);
            })
            .catch(error => {
                feedback({
                    title: "Failed",
                    text: "Failed to fetch records",
                    iconType: "error",
                });
                setLoading(false);
                console.error('Error fetching states', error)
            })
    }

    const combinedDocs = [...documentArray, ...mDocs];
    const approverlist = [...through, ...approvers]
    console.log(approverlist, payload)

    const save = async () => {

        const combinedDocs = [...documentArray, ...mDocs];
        const approverlist = [...through, ...approvers]
        console.log(approverlist)
        if (approverlist.length <= 0) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: "Please select an approver"
            })
            return
        }

        if (formValues?.details?.length < 5) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: "Details should be at least 5 characters"
            })
            return
        }

        if (formValues?.subject?.length < 5) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: "Subject should be at least 5 characters"
            })
            return
        }

        // if (documentArray?.length > requiredDocumentCount) {
        //     Notification({
        //         type: 'danger',
        //         title: 'Error',
        //         message: "You've exceeded the total number of required documents"
        //     })
        //     return
        // } else if ((documentArray?.length < requiredDocumentCount)) {
        //     Notification({
        //         type: 'danger',
        //         title: 'Error',
        //         message: `You're expected to upload the total number of ${requiredDocumentCount} document(s)`
        //     })
        //     return
        // }


        const document = combinedDocs.map((doc) => ({
            filePath: doc.fileurl || doc.name,
            userId: Number(userID)
        }));

        console.log(userRin, userEducation, userProfessional, payload)
        let userData;

        if (payload) {
            console.log("UserData before transformation:", payload);

            userData = {
                ...payload,
                firstAppCadre: payload?.firstAppCadre?.label?.toString() || payload?.firstAppCadre?.toString(),
                currentCadre: payload?.currentCadre?.label?.toString() || payload?.currentCadre?.toString(),
                mdaId: payload.mdaId?.id || payload?.mdaId || 0,
                salaryStructureId: payload.salaryStructureId?.value || payload?.salaryStructureId || 0,
                bankId: payload.bankId?.value || payload?.bankId || 0,
                religionId: payload.religionId?.id || payload?.religionId || 0,
            };

            console.log("UserData after transformation:", userData);
        }

        let payLoad = {
            subject: formValues?.subject,
            from: fullName,
            details: formValues?.details,
            employeeRIN: Number(payload?.employeeRIN),
            urgencyStatus: urgency,
            initiatorUserId: Number(userID),
            employeeUserID: Number(userID),
            nextApproverId: approverlist[0]?.userID,
            updateTypeId: UpdateMenu,
            updateRequestToApprovers: approverlist,
            updateRequestDocuments: document,
            updateRequestMFiles: document,

            updateRequestEmployeeRecord: userData,
            actionTaken: 'Save',
        }
        console.log(payLoad, 'loade', payload)

        const { isValid, missingFields } = isFormValid(payLoad);
        if (true) {
            if (recordId !== 0 && recordId !== undefined && recordId !== null) {
                try {
                    const Response = await put(`update/update-update/${recordId}`, payLoad)
                    if (Response !== 0) {
                        Notification({
                            type: 'success',
                            title: 'Success',
                            message: ' Your Update Request has been Successfully Saved'
                        })
                        clearForm()
                    } else if (Response == 0) {
                        Notification({
                            type: 'danger',
                            title: 'Error',
                            message: 'Failed to Submit'
                        })
                    }else {
                        Notification({
                            type: 'danger',
                            title: 'Error',
                            message: 'Something went wrong'
                        })
                    }
                } catch (error) {
                    Notification({
                        type: 'danger',
                        title: 'Error',
                        message: 'Something went wrong'
                    })
                }
            } else {
                try {
                    const Response = await post(`update/save-update`, payLoad)
                    if (Response) {
                        Notification({
                            type: 'success',
                            title: 'Success',
                            message: ' Your Update Request has been Successfully Saved'
                        })
                        clearForm()
                    }
                    else {
                        Notification({
                            type: 'danger',
                            title: 'Error',
                            message: 'Something went wrong'
                        })
                    }
                } catch (error) {
                    Notification({
                        type: 'danger',
                        title: 'Error',
                        message: 'Something went wrong'
                    })
                }
            }
        } else {
            const missingFieldsArray = [];
            if (missingFields) {
                missingFieldsArray.push(...missingFields);
            }

            const displayMissingFields = renameFields(missingFieldsArray)


            const missingFieldNames = displayMissingFields.map(fieldName => {
                // Convert camelCase field names to sentence case
                const formattedFieldName = fieldName.replace(/([A-Z])/g, ' $1').toLowerCase();
                return formattedFieldName;
            }).join(", ");
            const feedbackMessage = `Please fill in all required fields: ${missingFieldNames} before saving.`;
            console.log(isValid, feedbackMessage, missingFieldNames)
            Notification({
                type: 'warning',
                title: "Form not complete",
                message: feedbackMessage,
                iconType: "warning",
            });
            console.log("Please fill in all required fields before submitting.");
        }

    }

    console.log(payload)


    const fullName = Cookies.get('fullName')
    const userID = Cookies.get('userId')

    const handleUpdateChange = (field, value) => {
        const selectedValue = value && value?.target ? value?.target.value : value;
        if (field === "dropDown") {
            setUpdateMenu(Number(selectedValue))
            if (selectedValue === '1') {
                setrequiredDocumentCount(5)
            }
            else if (selectedValue === '2') {
                setrequiredDocumentCount(7)
            } else if (selectedValue === '3') {
                setrequiredDocumentCount(3)
            } else if (selectedValue === '4') {
                setrequiredDocumentCount(3)
            } else if (selectedValue === '5') {
                setrequiredDocumentCount(4)
            } else if (selectedValue === '6') {
                setrequiredDocumentCount(3)
            } else if (selectedValue === '7') {
                setrequiredDocumentCount(4)
            } else if (selectedValue === '8') {
                setrequiredDocumentCount(4)
            } else if (selectedValue === '9') {
                setrequiredDocumentCount(5)
            }
            console.log('state of DropDown', selectedValue)
        }
    }


    const handleSelectInputChange = async (value) => {
        console.log(value);

        await FetchMFiles(value);
    };

    const handleSelectInput = (value) => {
        console.log(value);
        setMDocs(prevMDocs => [...prevMDocs, value]);
    };


    const receiveImage = (value) => {
        console.log(value)
    }

    // const fetchUpdate = async (userID) => {
    //     const Response = await get(`update/${userID}`)
    //     console.log(Response);
    //     if (Response) {
    //         setupdateRecords(Response.data);

    //     }
    //     else {
    //         console.log("not found")
    //     }
    // }

    const fetchMdas = async () => {
        const Response = await gets(`ServiceManagement/get_mdas`)
        console.log(Response);
        if (Response) {
            setMdas(Response);

        }
        else {
            console.log("not found")
        }
    }

    useEffect(() => {
        setThrough(
            throughName?.map((item) => ({
                userID: Number(item?.value),
                mdaId: Number(thoroughMda.value),
                name: item?.label
            }))
        );
    }, [throughName]);


    useEffect(() => {
        setApprovers(
            employeeName?.map((item) => ({
                userID: Number(item?.value),
                mdaId: Number(approvalMDA.value) || 2,
                name: item?.label
            }))
        );
    }, [employeeName]);


    const formatDate = (timestamp) => {
        if (timestamp === null || timestamp === undefined) {
            return
        }
        const dateObject = new Date(timestamp);
        const formattedDate = dateObject.toISOString().split('T')[0];
        return formattedDate;
    };

    const FetchMFiles = async (searchValue) => {
        console.log('Fetch', searchValue)
        await axios.get(`https://edogoverp.com/services/api/dashboard/searchdocuments?user_id=${parseInt(
            userID
        )}&DocTitle=${searchValue}`)
            .then(response => {
                const mFileList = response?.data.map((item, index) => ({
                    value: index,
                    label: item?.title,
                    fileurl: item?.fileurl,
                }));
                setMFiles(mFileList)
                console.log("User DATA::", response, mFileList);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }


    console.log(mFiles, documentArray, mDocs, userEducation, userEducation, userProfile, userNextOfKins, userDependants, updateToBeViewed, myRecord, sentBack)
    return (
        <div className="w-100 flex space-between">
            <div className="col-6">
                <div className="col-5">
                    <InitiateExit
                        fetchUserData={fetchUserData}
                        userRin={userRin}
                        myRecord={myRecord}
                        setUserRin={handleInputChange}
                        setComment={setComment}
                        setExitReason={setComment}
                        setMyRecord={setMyRecord}
                        setDocumentArray={setdocumentArray}
                    />
                </div>
                <div className="w-100 flex space-between">
                    <div className="col-6 flex flex-v-center   searchtext">
                        <img className="m-r-10" src="/Vector.svg" alt="edsg" />
                        <span className="center-text bold-text">Initiate Request/Data Update</span>
                    </div>
                    <div className="col-half">
                        <Dropdown
                            kenprop={"Choose Update Type"}
                            options={UpdateOptions}
                            onChange={(value) => { handleUpdateChange("dropDown", value) }}
                        /></div>
                </div>
                <p>Required Documents:</p>
                <br />

                <div>

                    {UpdateMenu === 6 && (
                        <NameDeletion />
                    )}
                    {UpdateMenu === 8 && (
                        <Nomenclature_TSA_Development />
                    )}
                    {UpdateMenu === 7 && (
                        <Nomenclature_TSA_Development />
                    )}
                    {UpdateMenu === 9 && (
                        <PayrollCorrection />
                    )}
                    {UpdateMenu === 3 && (
                        <UneffectedSteppings />
                    )}
                    {UpdateMenu === 4 && (
                        <UnratifiedAccountChange />
                    )}
                    {UpdateMenu === 5 && (
                        <UnratifiedNameChange />
                    )}
                    {UpdateMenu === 2 && (
                        <UnratifiedPersonnelTransfer />
                    )}
                    {UpdateMenu === 1 && (
                        <UnratifiedPromotionalChange />
                    )}
                </div>
                <label>Subject</label>
                <InputField
                    placeholder='Subject'
                    onChange={handlechange}
                    name="subject"
                    value={formValues?.subject}


                />
                <br />
                <label>From</label>
                <InputField
                    placeholder='Staff full name'
                    value={fullName}
                    disabled='disabled'
                />
                <br />
                <div>
                    <ApproverDropdown
                        approverType="Through"
                        setApproverName={setThroughName}
                        setApproverMda={setThroughMda}
                        approverMda={thoroughMda}
                        approverName={throughName}
                        isMulti={true}

                    />

                </div>

                <div>
                    <ApproverDropdown
                        approverType="To"
                        setApproverName={setemployeeName}
                        setApproverMda={setapprovalMDA}
                        approverMda={approvalMDA}
                        approverName={employeeName}
                        isMulti={true}
                        to={employeeName}
                        setTo={setemployeeName}
                        type={'Update'}
                        isPSHR={authStatus?.toLowerCase()}


                    />

                </div>
                <br />
                <div>{throughArray.map((e) => <p>{e.name}</p>)}</div>
                {/* <InputField
                    placeholder='Through:'
                /> */}

                <div>
                    {(userProfile) &&
                        <OnboardingDetailsEdit
                            userId={userId}
                            staffId={staffId}
                            userProfile={userProfile}
                            userDependants={userDependants}
                            userEducation={userEducation}
                            userProfessional={userProfessional}
                            userNextOfKins={userNextOfKins}
                            review={true}
                            disabled={false}
                            authStatus={authStatus}
                            type={'Update'}
                            setPayload={setPayload}
                            payload={payload}
                            canEdit={true}
                            employeeRin={userRin}
                            setUserRin={setUserRin}
                        />

                    }
                </div>
                <br />
                <label>Details</label>
                <TextAreaInputField
                    placeholder='Details:'
                    onChange={handlechange}
                    name="details"
                    value={formValues?.details}
                />
                <br />
                <div className="col-10 flex flex-v-center">
                    <div>Urgency Status:</div>
                    <button onClick={() => setUrgency('Regular')} className={`status-btn m-r-10 ${urgency == 'Regular' && 'onUrgent'}`}>Regular</button>
                    <button onClick={() => setUrgency('Urgent')} className={`status-btn m-r-10 ${urgency == 'Urgent' && 'onUrgent'}`}>Urgent</button>
                    <button onClick={() => setUrgency('Super Urgent')} className={`status-btn ${urgency == 'Super Urgent' && 'onUrgent'}`}>Super Urgent</button>


                </div>
                <br />
                <br />
                <div className="col-6">
                    <div>Attach Documents</div>
                    <br />
                    <div className="m-b-20">
                        <UploadButton
                            setDocNames={setDocNames}
                            setdocumentArray={setdocumentArray}
                            sendImagg={receiveImage}
                            userID={userID}
                        />

                    </div>

                    {documentArray?.map((item, index) => (
                        <div key={index} className="m-t-10 flex" >
                            <a href={item.path} target="_blank" className="m-r-10">
                                {item.name}
                            </a>
                            <RiDeleteBinLine color="red" className="pointer" onClick={() => deleteDoc(item.name)} />
                        </div>
                    ))}

                    <br />

                    <br />
                    <div className="m-b-20">
                        <label >M-Files</label>
                        <SelectInput
                            kenprop={"Choose M-file"}
                            options={mFiles}
                            onChange={(value) => { handleSelectInput(value) }}
                            onInputChange={(value) => { handleSelectInputChange(value) }}

                        />
                    </div>

                    {mDocs?.map((item, index) => (
                        <div key={index} className="m-t-10 flex" >
                            <a href={item.fileurl} target="_blank" className="m-r-10">
                                {item.label}
                            </a>
                            <RiDeleteBinLine color="red" className="pointer" onClick={() => deleteDoc(item.label)} />
                        </div>
                    ))}
                </div>
                <br />
                <div className="m-t-20">
                    <button className="save-drafts" onClick={save}>Save Draft</button>
                    <br />
                    <br />
                    <button className="submit-btn" onClick={submit}>Submit</button>
                </div>

            </div>
            <div className="col-6 m-t-40 m-l-20 ">
                {myRecord === true && sentBack === true &&
                    <div>
                        <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center ">Approver Journey</div>
                        <div className="reviewer-card">
                            <table className="reviewer-table">
                                <thead>
                                    <tr>
                                        <th className="center-text">Assigned Officers</th>
                                        <th className="center-text">In-Tray</th>
                                        <th className="center-text">Out-Tray</th>
                                        <th className="center-text">Action Taken</th>
                                        <th className="center-text">Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {updateToBeViewed?.updateRequestToApprovers?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="center-text">{item?.name || item?.approverName}</td>
                                            <td className="center-text">{formatDate(item?.recievedDate || item?.createdAt)}</td>
                                            <td className="center-text">{formatDate(item?.approvedOn || item?.modifiedAt)}</td>
                                            <td className="center-text">{item?.actionTaken}</td>
                                            <td className="center-text">{item?.comment}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default DataUpdate;