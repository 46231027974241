import { useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import TextAreaInputField from "../inputs/TextAreaInputField";
import Dropdown from "../inputs/Dropdown";
import { get, post } from "../../utility/fetch";
import { get as gets } from "../../utility/services";
import UnratifiedPromotionalChange from "../data update dropdowns/UnratifiedPromotionalChange";
import UnratifiedPersonnelTransfer from "../data update dropdowns/UnratifiedPersonnelTransfer ";
import UneffectedSteppings from "../data update dropdowns/Un-effectedSteppings";
import NameDeletion from "../data update dropdowns/NameDeletion";
import Nomenclature_TSA_Development from "../data update dropdowns/Nomenclature_TSA_Development";
import PayrollCorrection from "../data update dropdowns/PayrollCorrection";
import UnratifiedAccountChange from "../data update dropdowns/UnratifiedAccountChange";
import UnratifiedNameChange from "../data update dropdowns/UnratifiedNameChange";
import UploadButton from "../inputs/UploadButton";
import Cookies from "js-cookie";
import ApproverDropdown from "../inputs/ApproverDropdown";
import Notification from "../../utility/notification";
import { RiDeleteBinLine } from "react-icons/ri";
import OnboardingDetails from "../page modifiers/OnboardingDetails";

const DisplayUpdateDetails = ({ userId, recordToBeReviewed, staffId, userDependants, userEducation, userNextOfKins, userProfile, userProfessional, userRecord, employeeRIN, updateType }) => {

    const [documentArray, setdocumentArray] = useState([])
    const [requiredDocumentCount, setrequiredDocumentCount] = useState(0)
    const [UpdateOptions, setUpdateOptions] = useState([])
    const [approvers, setApprovers] = useState([])
    const [through, setThrough] = useState([])
    const [updateRecords, setupdateRecords] = useState(false);
    const [urgency, setUrgency] = useState("");
    const [payload, setPayload] = useState({})
    const [mdas, setMdas] = useState([]);
    const [approvalMDA, setapprovalMDA] = useState([])
    const [employeeName, setemployeeName] = useState([])
    const [docNames, setDocNames] = useState([])
    const [thoroughMda, setThroughMda] = useState([])
    const [throughName, setThroughName] = useState([])
    const [throughArray] = useState([]);
    const [formValues, setFormValues] = useState({
        subject: '',
        details: ''
    })
    const deleteDoc = (doc) => {
        let newarr = documentArray.filter((id) => id.name !== doc)
        setdocumentArray(newarr)
    }

    console.log(documentArray)

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'EDSG - Dashboard';
        setUrgency(recordToBeReviewed?.urgencyStatus)
    }, []);

    const clearForm = () => {
        setFormValues({
            subject: '',
            details: ''
        })
        setUrgency("")
        setapprovalMDA([])
        setThroughName([])
        setemployeeName([])
        setThroughMda([])
        setdocumentArray([]);

        // {







        //     updateRequestToApprovers: approvers,
        //     updateRequestThroughApprovers: through,
        //     updateRequestDocuments: documentArray.map((document)=>(
        //         {filePath: document.name}
        //     )),
        //     updateRequestMFiles:documentArray.map((document)=>(
        //         {filePath: document.name}
        //     ))
        // }
    }
    // const UpdateOptions = [
    //     { value: "Name D", label: "Name Deletion" },
    //     { value: "TSA Dev", label: "Nomenclature/TSA Development" },
    //     { value: "TSA Cor", label: "Nomenclature/TSA Correction" },
    //     { value: "Pay Cor", label: "Payroll Correction" },
    //     { value: "Uneff Step", label: "Un-effected Steppings" },
    //     { value: "Unrat Account", label: "Unratified Account Change" },
    //     { value: "Unrat Name", label: "Unratified Name Change" },
    //     { value: "Unrat Person", label: "Unratified Personnel Transfer" },
    //     { value: "Unrat Promotion", label: "Unratified Promotional Change" },
    // ]


    console.log(documentArray, recordToBeReviewed)

    const handlechange = (e) => {
        const { name, value } = e.target
        setFormValues((prev) => (
            {
                ...prev,
                [name]: value
            }
        ))
    }



    const receiveImage = (value) => {
        console.log(value)
    }

    // const fetchUpdate = async(userId)=>  {
    //     const Response = await get (`update/${userId}`)
    //     console.log(Response);
    //     if (Response) {
    //         setupdateRecords(Response.data);

    //     }
    //     else{
    //         console.log("not found")
    //     }
    // }

    // const fetchMdas = async()=>  {
    //     const Response = await gets (`ServiceManagement/get_mdas`)
    //     console.log(Response);
    //     if (Response) {
    //         setMdas(Response);

    //     }
    //     else{
    //         console.log("not found")
    //     }
    // }

    useEffect(() => {
        setThrough(
            throughName?.map((item) => ({
                userId: Number(item?.value),
                mdaId: Number(approvalMDA.value),
                name: item?.label
            }))
        );
    }, [throughName]);


    useEffect(() => {
        setApprovers(
            employeeName?.map((item) => ({
                userId: Number(item?.value),
                mdaId: Number(approvalMDA.value),
                name: item?.label
            }))
        );
    }, [employeeName]);

    console.log(userProfile, userEducation, userProfessional)

    let selectedValue = recordToBeReviewed?.updateTypeId

    const authStatus = Cookies.get('authStatus').toLocaleLowerCase()

    console.log(selectedValue)
    return (
        <div className="w-100 flex space-between m-t-20">
            <div className="col-12">
                <div className="w-100 flex space-between">
                    <div className="col-6 flex flex-v-center   searchtext">
                        <img className="m-r-10" src="/Vector.svg" alt="edsg" />
                        <span className="center-text bold-text">Data Update</span>
                    </div>
                    {/* <div className="col-half">
                        <Dropdown
                            kenprop={"Choose Update Type"}
                            options={UpdateOptions}
                            value={''}
                        />
                    </div> */}
                </div>
                <br />

                <div>
                    <h3 className=" m-t-40 m-b-20  bottom-border">{updateType?.typeTitle}</h3>

                    <p className="m-b-10 bold-text">Required Documents:</p>
                    {selectedValue === 6 && (
                        <NameDeletion />
                    )}
                    {selectedValue === 8 && (
                        <Nomenclature_TSA_Development />
                    )}
                    {selectedValue === 7 && (
                        <Nomenclature_TSA_Development />
                    )}
                    {selectedValue === 9 && (
                        <PayrollCorrection />
                    )}
                    {selectedValue === 3 && (
                        <UneffectedSteppings />
                    )}
                    {selectedValue === 4 && (
                        <UnratifiedAccountChange />
                    )}
                    {selectedValue === 5 && (
                        <UnratifiedNameChange />
                    )}
                    {selectedValue === 2 && (
                        <UnratifiedPersonnelTransfer />
                    )}
                    {selectedValue === 1 && (
                        <UnratifiedPromotionalChange />
                    )}
                </div>

                <label>Subject</label>
                <InputField
                    placeholder='Subject'
                    name="subject"
                    value={recordToBeReviewed?.subject}
                    disabled


                />
                <br />

                <OnboardingDetails
                    userId={userId}
                    staffId={staffId}
                    userProfile={userProfile}
                    userDependants={userDependants}
                    userEducation={userEducation}
                    userProfessional={userProfessional}
                    userNextOfKins={userNextOfKins}
                    disabled={true}
                    review={true}
                    setPayload={setPayload}
                    payload={payload}
                    userRecord={userRecord}
                    authStatus={authStatus}
                    canEdit={false}
                    employeeRIN={employeeRIN}
                />

                <br />
                <div>{throughArray.map((e) => <p>{e.name}</p>)}</div>
                {/* <InputField
                    placeholder='Through:'
                /> */}
                <br />

                <label>Details</label>
                <TextAreaInputField
                    placeholder='Details:'
                    name="details"
                    value={recordToBeReviewed?.details}
                    disabled
                />
                <br />
                <div className="col-10 flex flex-v-center">
                    <div className="m-r-10">Urgency Status: </div>

                    <button className={`status-btn m-r-10 ${urgency == `${urgency}` && 'onUrgent'}`}>{recordToBeReviewed?.urgencyStatus}</button>


                </div>
                <br />
                <br />

                <br />


            </div>



        </div>
    );
}

export default DisplayUpdateDetails;