const UnratifiedAccountChange = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Promotion letter</p>
                    <p>2: Appointment letter</p>
                    <p>3: Biometric with new account number</p>
                    </div>
                    
                </div>
    )
}

export default UnratifiedAccountChange;