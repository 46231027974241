import InputField from "../inputs/InputField"


const  InitiateExit =({setUserRin,fetchUserData, setExitReason,setComment, setMyRecord, setDocumentArray, })=>{

    return(
        <div className="flex-col ">
              <label>Employee's RIN/Staff ID</label>
              <div className="m-b-20">
                <InputField
                  placeholder='Input employees RIN/Staff ID'
                  
                  onChange={(value) => setUserRin(value?.target?.value)}
                                
                />
              </div>
              

              <button className=" submit-btn prev-step"  onClick={()=>{fetchUserData(); setComment(''); setExitReason(''); setMyRecord(false); ; setDocumentArray([])}}>Fetch User Data</button>

          </div>
    )
}

export default InitiateExit