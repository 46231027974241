/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";

// import Loader from "react-loader-spinner";
// import Moment from "react-moment";
// import moment from "moment";
import ProfilePix from "../../assets/images/profile-pix.jpg";
import { get, post } from "../../utility/fetch";
import { getMfile } from "../../utility/globalfetch"

import DatePicker from "react-datepicker";
import TextAreaInputField from "../inputs/TextAreaInputField";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';


import axios from "axios";
import Cookies from "js-cookie";
import { feedback } from "../modal/feedback";
import OfficialDetails from "../layouts/profileDetails/OfficialDetails";
import PersonalDetails from "../layouts/profileDetails/PersonalDetails";
import EducationDetails from "../layouts/profileDetails/EducationDetails";
import ProfessionalDetails from "../layouts/profileDetails/ProfessionalDetails";
import DependantDetails from "../layouts/profileDetails/DependantDetails";
import NextOfKinsDetails from "../layouts/profileDetails/NextOfKinsDetails";
import InputField from "../inputs/InputField";
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";


const DownloadExitRecord = ({ userId, recordOnReview, closeModal, recordToBeReviewed, userDependants, userEducation, userNextOfKins, userProfessional, wrongStaffId, currentCadre,firstAppCadre }) => {
    const [currentForm, setCurrentForm] = useState("official-details");
    const [isUpdating, setIsUpdating] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const [recipientMda, setrecipientMda] = useState([])
    const [toName, setToName] = useState([])
    const [approvers, setApprovers] = useState([])
    const [thoroughMda, setThroughMda] = useState([])
    const [throughName, setThroughName] = useState([])
    const [through, setThrough] = useState([])
    const [mFiles, setMFiles] = useState([])
    const [user, setUser] = useState({})
    const [comment, setComment] = useState('')
    const [exitReason, setExitReason] = useState('')
    const [documentArray, setDocumentArray] = useState([])
    const [receiveImage, setReceiveImage] = useState([])
    const [recordId, setRecordId] = useState('')
    const [userRin, setUserRin] = useState(Number)

   


    const pdfExportComponent = React.useRef(null);



    const userID = Cookies.get("userId");

    useEffect(() => {
        setApprovers(
            toName?.map((item) => ({
                userId: Number(item?.value),
                mdaId: recipientMda.value,
                name: item?.label
            }))
        );
    }, [toName]);

    useEffect(() => {
        setThrough(
            throughName?.map((item) => ({
                userId: Number(item?.value),
                mdaId: thoroughMda.value,
                name: item?.label
            }))
        );
    }, [throughName]);


    const addDefaultSrc = (ev) => {
        ev.target.src = ProfilePix;
    };


    const FetchMFiles = async () => {
        await axios.get(`https://edogoverp.com/services/api/dashboard/searchdocument?user_id=${parseInt(
            userID
        )}&DocTitle=${searchValue}`)
            .then(response => {
                const mFileList = response?.recordToBeReviewed.map((item) => ({
                    name: `${item.name}`,
                    value: item.id,
                }));
                setMFiles(mFileList)
                console.log("User recordToBeReviewed::", response);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }


    console.log(throughName)
    console.log('comment:', comment, toName)


    console.log('PROFILE', userId, userDependants[0], userEducation[0], userNextOfKins, userProfessional)


    function calculateDuration(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        const years = end.diff(start, 'years');
        start.add(years, 'years');
        const months = end.diff(start, 'months');
        start.add(months, 'months');
        const days = end.diff(start, 'days');

        return {
            years,
            months,
            days
        };
    }

    const currentDate = moment().format('YYYY-MM-DD')
    const duration = calculateDuration(recordToBeReviewed?.dateOfFirstApp, currentDate);

    console.log('hey', duration, currentDate)

    return (
        <div className="overlay">


            <button className="svg close-btn" onClick={closeModal}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
                    </path>
                </svg>
            </button>

            <div className="modal-content">
                <div className="w-100 p-r m-t-20 ">


                    <div className="flex space-between ">

                        <button
                            onClick={() => {
                                if (pdfExportComponent?.current) {
                                    pdfExportComponent?.current.save();
                                }
                            }}

                            className="download-btn"
                        >
                            Download
                        </button>
                    </div>



                    {isUpdating ? (
                        <div>
                            {/* <Loader type="ThreeDots" color="green" height={100} width={100} /> */}
                        </div>

                    ) : (
                        <div className="m-l-10">
                            <PDFExport
                                paperSize="A2"
                                margin="2mm"
                                fileName={'Staff Exit.pdf'}
                                ref={pdfExportComponent}
                                // paperSize="A3"
                                // margin="2mm"
                                scale={1}
                                keepTogether=".p"
                                forcePageBreak=".page-break"
                            // fileName={`${pdfName}.pdf`}
                            // ref={pdfExportComponent}
                            >
                                <div>
                                    <div className="center-text">
                                        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
                                    </div>
                                </div>
                                <h2 className="m-b-10 m-t-20 center-text">Staff Exit</h2>
                                <div className="bottom-border no-margin m-b-60"></div>

                                <div className="col-6">
                                    <div className="flex row space-between ">
                                        <div className="col-5 no-padding">
                                            <label>Employee's Rin</label>
                                            <InputField
                                                placeholder='Input employee’s RIN'
                                                value={recordOnReview?.employeeRIN}
                                                disabled='disabled'
                                            />
                                        </div>
                                        <div className="col-7  no-p-r">
                                            <label>Mda</label>
                                            <InputField
                                                placeholder='Ministry of Housing and Urban Development'
                                                value={recordOnReview?.ministry}
                                                disabled='disabled'
                                            />
                                        </div>
                                    </div>
                                    <div className="m-b-20 ">
                                        <label>Full Name</label>
                                        <InputField
                                            placeholder='Staff full name'
                                            value={recordOnReview?.staffFullName}
                                            disabled='disabled'
                                        />
                                    </div>
                                    <div className="col-8 no-p-l ">
                                        <label>Exit Reason</label>
                                        <TextAreaInputField
                                            value={recordOnReview?.reason}
                                            placeholder='Exit Reason'
                                            disabled

                                        />
                                    </div>
                                </div>


                                <div>
                                    <label className="m-b-20 m-l-10">Uploaded Documents: </label>
                                    {recordToBeReviewed?.exitRequestDocuments?.map((item) => {
                                        // Split the file path based on the file separator ("/" or "\")
                                        const pathParts = item?.filePath.split(/[\/\\_]/);

                                        // Extract the last part of the path, which is the filename
                                        const filename = pathParts[pathParts.length - 1];

                                        return (
                                            <div className="m-t-10 flex" key={item?.id}>
                                                <a href={item?.filePath} target="_blank" className="m-r-10 uploads">
                                                    {filename}
                                                </a>
                                            </div>
                                        );
                                    })}

                                </div>
                                <div className="w-100 user-full-details">

                                    {wrongStaffId === true ? (
                                        <>
                                            <br />
                                            <p>
                                                <i>
                                                    Update your staffId with your valid National Identity Number
                                                    to view your profile
                                                </i>
                                            </p>
                                        </>
                                    ) : (
                                        <div className="w-100 flex">
                                            <div className="left">
                                                <div className="content w-100">
                                                    <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Official Details</h3>
                                                    <OfficialDetails data={recordToBeReviewed} currentCadre={currentCadre} firstAppCadre={firstAppCadre} />

                                                    <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Personal Details</h3>
                                                    <PersonalDetails data={recordToBeReviewed} />

                                                    <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Educational Details</h3>
                                                    <EducationDetails data={userEducation} />

                                                    <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Professional Details</h3>
                                                    <ProfessionalDetails data={userProfessional} />

                                                    <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Dependant Details</h3>
                                                    <DependantDetails data={userDependants} />

                                                    <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Next Of Kin Details</h3>
                                                    <NextOfKinsDetails data={userNextOfKins} />
                                                </div>
                                                <div className="col-8">


                                                    <div className="m-l-10 m-t-40">
                                                        <label>Comment</label>
                                                        <TextAreaInputField
                                                            placeholder='Details:'
                                                            value={recordOnReview?.comment}
                                                            disabled
                                                        />
                                                    </div>



                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="w-100 m-t-60 center-text profile-brief">
                                                    <img
                                                        onError={addDefaultSrc}
                                                        src={
                                                            (recordToBeReviewed && recordToBeReviewed?.profilePicture) ||
                                                            ProfilePix
                                                        }
                                                        alt={recordToBeReviewed && recordToBeReviewed?.firstName}
                                                    />
                                                    <div className="brief m-t-20">
                                                        <h4>
                                                            {`${(recordToBeReviewed && recordToBeReviewed?.firstName) || ""} ${(recordToBeReviewed && recordToBeReviewed?.lastName) || ""
                                                                }`}
                                                        </h4>
                                                        <hr />
                                                        <p className="  m-b-10">
                                                            <b className=" m-b-10">Engagement Date: </b>
                                                            <div className=" m-t-10">
                                                                {recordToBeReviewed?.dateOfFirstApp}

                                                            </div>
                                                        </p>
                                                        <p className="  m-b-10">
                                                            <b>Duration on Grade: </b>
                                                            <div className=" m-t-10">
                                                                {duration?.years} years, {duration?.months} months, {duration?.days} day(s)

                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </PDFExport >
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DownloadExitRecord;
