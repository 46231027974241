const UnratifiedPersonnelTransfer = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Posting letter</p>
                    <p>2: Appointment letter</p>
                    <p>3: Change of name (if any)</p>
                    </div>
                    <div className="col-7">
                    <p>4: LPC</p>
                    <p>5: Former School</p>
                    <p>6: Present School</p>
                    <p>7: Biometric</p>
                    </div>
                </div>
    )
}

export default UnratifiedPersonnelTransfer;