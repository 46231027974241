import React, { useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";

const EducationRecordForm = ({
  isOpen, 
  onClose,
  index,
  certificates,
  degrees,
  handleUpdateChange,
  handleDateChangeUpdate,
  updateEduRecord,
  id,
  fields
}) => {

    const [selectedDate, setSelectedDate] = useState(null)


    if (!isOpen) {
        return null; 
      }

      const resetForm = () =>{
        setSelectedDate(null)
      }

      const handleDate = (field, date, recordIndex) => {
        const formattedDateWithoutTimestamp = date
        ? new Date(date).getFullYear().toString()
        : "";
    
        setSelectedDate(date)
    
        handleDateChangeUpdate(field, date, recordIndex)
        };
      return (
        <div className="overlay">
          <button className="svg close-btn" onClick={()=>{onClose(); resetForm();}}>
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
                </path>
            </svg>
          </button>
          <form className="col-4 modal-container " key={index} name={`edu-update-${index}`}>
            <div key={index} className="qualification-form m-t-30">
              <div className="m-b-20">
              <label >Name Of School</label>
                <InputField
                  placeholder="Name Of School"
                  required
                  onChange={(value) => handleUpdateChange("schoolName", value, index)}
                />
              </div>
    
              <div className="m-b-20">
              <label >Course Of Study</label>
                <InputField
                  placeholder="Course Of Study"
                  required
                  onChange={(value) => handleUpdateChange("course", value, index)}
                />
              </div>
    
              <div className="m-b-20">
                <div className="col-7 no-padding no-margin">
                <label >Certificate Obtained</label>
                  <Dropdown
                    options={certificates}
                    placeholder="Select Certificate Obtained"
                    required
                    onChange={(value) => handleUpdateChange("certificate", value, index)}
                  />
                </div>
                <div className="col-5 no-p-r no-margin">
                <label >Year of graduation</label>
                  <DatePicker
                    className="input-field p-r-5 p-l-5"
                    placeholderText="Year of graduation"
                    required
                    selected={selectedDate}
                    dateFormat="yyyy"
                    onChange={(date) => handleDate("graduateYear", date, index)}
                    showYearDropdown={true}
                    showYearPicker
                    scrollableYearDropdown
                    yearDropdownItemNumber={200}
                  />
                </div>
              </div>
    
              <div className="m-b-20">
              <label >Class of Degree</label>
                <Dropdown
                  options={degrees}
                  placeholder="Select Class of Degree (Where Applicable)"
                  onChange={(value) => handleUpdateChange("classOfDegree", value, index)}
                />
              </div>
    
              <div className="m-b-20">
              <label >Remarks</label>
                <TextAreaInputField
                  placeholder="Remarks"
                  onChange={(value) => handleUpdateChange("remarks", value, index)}
                />
              </div>
    
              <div className="flex space-between">
                <button
                  className="btn m-t-20 m-b-20"
                  onClick={(e) => updateEduRecord(e, id, index)}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      );
};

export default EducationRecordForm;
