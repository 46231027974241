/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useState } from 'react';

const TextAreaInputField = (props) => {

  const [isValid, setIsValid] = useState(true);

  const handleBlur = (event) => {
    const inputValue = event.target.value;
    const isInputValid = props.required ? inputValue.trim() !== '' : true;
    setIsValid(isInputValid);
  };
 return(
 <div>

    <textarea className={`text-area-input-field ${isValid ? '' : 'invalid'}`} 
      placeholder={props.placeholder}
      name={props.name}
      id={props.name}
      value={props.value}
      rows={props.rows}
      onChange={props.onChange}
      onBlur={handleBlur}
      disabled={props.disabled}
      onKeyPress={props.onKeyPress}
      onKeyDown={props.onKeyDown}
    />
  </div>
 )
 };

export default TextAreaInputField;
