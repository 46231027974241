import React, { useState, useEffect } from "react";
import Bio from "../forms/Bio";
import { Button, Container, Step, StepLabel, Stepper } from "@mui/material";

import PersonalInfo from "../forms/PersonalInfo";
import EmploymentRecords from "../forms/EmploymentRecords";
import EducationalQualifications from "../forms/EducationalQualifications";
import ProfessionalQualification from "../forms/ProfessionalQualificaton";
import Relatives from "../forms/Relatives";
import Declaration from "../forms/Declaration";
import { get } from "../../utility/fetch";
import BioEdit from "../forms/BioEdit";
import EmploymentRecordsEdit from "../forms/EmploymentRecordsEdit";
import PersonalInfoEdit from "../forms/PersonalInfo edit";

const steps = ["Bio", "Personal Information", "Employment Records", "Educational Qualifications", "Professional Qualifications", "Relatives", "Declarations"];

const OnboardingDetailsEdit = ({ userId, userEducation, setUserRin, employeeRin, staffId, userDependants, userProfile, userNextOfKins, userProfessional, disabled, review, type, setPayload, payload, authStatus, canEdit }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [deps, setDeps] = useState([]);
  const [kins, setKins] = useState([]);
  const [professional, setProfessional] = useState([]);
  const [education, setEducation] = useState([]);
  const [submit, setSubmit] = useState(false);


  const handleFormDataChange = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    console.log(data)
  };

  const handleFormDataChangeEdu = (data) => {
    setEducation((prevData) => {
      const updatedData = prevData.map((record) => {
        if (record.id === data.id) {
          return { ...record, ...data }; // Update existing record
        }
        return record;
      });

      const existingRecord = prevData.find((record) => record.id === data.id);
      if (!existingRecord) {
        // If the data is for a new record (not found in the existing data), add it
        return [...prevData, data];
      }

      return updatedData;
    });
  };

  const handleFormDataChangeProf = (data) => {
    setProfessional((prevData) => ({ ...prevData, data }));
    console.log(professional)
  };
  const handleFormDataChangeDeps = (data) => {
    setDeps((prevData) => ({ ...prevData, ...data }));
    console.log(deps)
  };

  const handleFormDataChangeNok = (data) => {
    setKins((prevData) => ({ ...prevData, ...data }));
    console.log(kins)
  };

  function formatPhoneNumber(phoneNumber) {
    // Check if the phoneNumber starts with '0' and has 11 digits
    if (/^0\d{10}$/.test(phoneNumber)) {
      // Replace '0' with '+234'
      return '+234' + phoneNumber.substring(1);
    }
    // If the phoneNumber is already in the desired format, return it as is
    else if (/^\+234\d{10}$/.test(phoneNumber)) {
      return phoneNumber;
    }
  }

  const handleStepClick = (step) => {
    setActiveStep(step);
  };


  const depData = Array.isArray(deps) && deps.length > 0 ? deps[0] : deps;
  const nokData = Array.isArray(kins) && kins.length > 0 ? kins[0] : kins;

  const depName = depData ? depData?.depName : '';
  const depAddress = depData ? depData?.depAddress : '';
  const depRelation = depData ? depData?.depRelation : '';
  const depDoB = depData ? depData?.depDoB : '';
  const depMobile = depData ? formatPhoneNumber(depData?.depMobile) : '';

  const nokName = nokData ? nokData?.nokName : userNextOfKins?.nokName? userNextOfKins[0]?.nokName : '';
  const nokAddress = nokData ? nokData?.nokAddress  : userNextOfKins?.nokAddress? userNextOfKins[0]?.nokAddress : '';
  const nokRelation = nokData ? nokData?.nokRelation: userNextOfKins?.nokRelation? userNextOfKins[0]?.nokRelation : '';
  const nokDoB = nokData ? nokData?.nokDoB : userNextOfKins?.nokDoB? userNextOfKins[0]?.nokDoB : '';
  const nokMobile = nokData ? formatPhoneNumber(nokData?.nokMobile) : ( userNextOfKins?.nokMobile)? formatPhoneNumber( userNextOfKins[0]?.nokMobile) : '';

  


  useEffect(() => {

    setPayload({
      lastName: formData?.lastName || userProfile?.lastName || '',
      firstName: formData?.firstName || userProfile?.firstName || '',
      middleName: formData?.middleName || userProfile?.middleName || '',
      title: formData?.title || userProfile?.title || '',
      gender: formData?.gender || userProfile?.gender || '',
      dateOfBirth: formData?.dateOfBirth || userProfile?.dob || '',
      stateId: formData?.stateId || userProfile?.stateId || 0,
      maritalStatus: formData?.maritalStatus || userProfile?.maritalStatus || '',
      mothersMaidenName: formData?.mothersMaidenName || userProfile?.mothersMaidenName || '',
      userId: formData?.userId || userProfile?.lastName || '',
      nationalityId: formData?.nationalityId || userProfile?.nationality?.id || 19 || 0,
      lgaId: 0,
      homeTown: formData?.homeTown || userProfile?.homeTown || '',
      permHomeAddress: formData?.permHomeAddress || userProfile?.permHomeAddress || '',
      residentialAddress: formData?.residentialAddress || userProfile?.residentialAddress || '',
      languageSpoken: formData?.languageSpoken || userProfile?.languageSpoken || '',
      mobile: formatPhoneNumber(formData?.mobile) || formatPhoneNumber(userProfile?.mobile) || '',
      religionId: formData?.religionId || userProfile?.religion?.id || userProfile?.religionId?.id || userProfile?.religion?.id || 0,
      nin: formData?.nin || userProfile?.nin || '',
      dateOfFirstApp: formData?.dateOfFirstApp || userProfile?.dateOfFirstApp || '',
      fileNumber: formData?.fileNumber || userProfile?.fileNumber || '',
      gradeOfFirstApp: formData?.gradeOfFirstApp || userProfile?.gradeOfFirstApp || '',
      stepOfFirstApp: formData?.stepOfFirstApp || userProfile?.stepOfFirstApp || '',
      firstAppCadre: formData?.firstAppCadre || formData?.firstAppCadre?.label || userProfile?.firstAppCadre || '',
      staffID: formData?.staffID || userProfile?.staffId || userProfile?.staffID || '',
      dateOfConfirmation: formData?.dateOfConfirmation || userProfile?.dateOfConfirmation || '' || null,
      currentCadre: formData?.currentCadre || formData?.currentCadre?.label || userProfile?.currentCadre || '',
      currentLevel: formData?.currentLevel || userProfile?.currentLevel || '',
      currentStep: formData?.currentStep || userProfile?.currentStep || '',
      dateOfPresentPosition: formData?.dateOfPresentPosition || userProfile?.dateOfPresentPosition || '',
      mdaId: formData?.mdaId?.value || formData?.mdaId || userProfile?.mda?.id || userProfile?.mdaId || 0,
      email: formData?.email || userProfile?.email || '',
      officialEmail: formData?.officialEmail || userProfile?.officialEmail || '',
      updateRequestEducationalRecords: education || '',
      updateRequestProfessionalQualifications: professional || '',
      depName: depName || '',
      depAddress: depAddress || '',
      depRelation: depRelation || '',
      depDoB: depDoB || '',
      depMobile: depMobile || '',
      nokName: nokName || '',
      nokAddress: nokAddress || '',
      nokRelation: nokRelation || '',
      nokDoB: nokDoB || '',
      nokMobile: nokMobile || '',
      profilePicture: formData?.profilePicture || userProfile?.profilePicture || '',
      signature: formData?.signature || userProfile?.signature || '',
      dateOfAppointment: formData?.dateOfFirstApp || userProfile?.dateOfConfirmation || '',
      bankId: formData?.bankId || userProfile?.bankId || 0,
      salaryStructureId: formData?.salaryStructureId || userProfile?.salaryStructureId || 0,
      salaryStructureByTypeId: formData?.salaryStructureByTypeId || userProfile?.salaryStructureByTypeId || '',
      salaryAccountNumber: formData?.salaryAccountNumber || userProfile?.salaryAccountNumber || '',
      employeeRIN:  formData?.employeeRIN || employeeRin,

    });

    console.log(formData, kins, deps, userNextOfKins)
  }, [formData, kins, deps, education, professional, userProfile, ])

  useEffect(() => {
    setEducation(userEducation)
    setDeps(userDependants)
    setKins(userNextOfKins)
    setProfessional(userProfessional)
  }, [userProfile, userEducation, userNextOfKins, userProfessional, userNextOfKins, userDependants])




  const clearFormData = () => {
    setFormData({});
  }


  console.log('ID from post', education, 'setPayload', payload?.firstAppCadre, payload?.currentCadre, payload, nokData, depData);

  console.log('ID from post', employeeRin, 'userdependant', userDependants, 'userEdu', userEducation);



  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BioEdit handleNext={handleNext} rin={employeeRin} staffId={staffId} payload={payload} disabled={disabled} handleFormData={handleFormDataChange} data={userProfile} review={review} authStatus={authStatus.toLowerCase()} />;
      case 1:
        return <PersonalInfoEdit handleBack={handleBack} authStatus={authStatus.toLowerCase()} staffId={staffId} payload={payload} disabled={disabled} handleFormData={handleFormDataChange} handleNext={handleNext} ID={userId} data={userProfile} review={review} />;
      case 2:
        return <EmploymentRecordsEdit handleBack={handleBack} staffId={staffId} payload={payload} authStatus={authStatus.toLowerCase()} type={type} disabled={disabled} handleNext={handleNext} handleFormData={handleFormDataChange} ID={userId} data={userProfile} review={review} />;
      case 3:
        return <EducationalQualifications handleBack={handleBack} staffId={staffId} canEdit={canEdit} type={type} disabled={disabled} ID={userId} handleFormData={handleFormDataChangeEdu} handleNext={handleNext} data={userEducation} review={review} />;
      case 4:
        return <ProfessionalQualification handleBack={handleBack} staffId={staffId} canEdit={canEdit} type={type} disabled={disabled} ID={userId} handleFormData={handleFormDataChangeProf} handleNext={handleNext} data={userProfessional} review={review} />;
      case 5:
        return <Relatives handleBack={handleBack} canEdit={canEdit} staffId={staffId} handleNext={handleNext} type={type} disabled={disabled} ID={userId} handleFormDataDeps={handleFormDataChangeDeps} handleFormData={handleFormDataChangeNok} dataNOK={userNextOfKins} dataDEP={userDependants} review={review} />;
      case 6:
        return <Declaration handleBack={handleBack} data={userProfile} payload={payload} clear={clearFormData} staffId={staffId} handleFormData={handleFormDataChange} authStatus={authStatus.toLowerCase()} type={type} disabled={disabled} ID={userId} review={review} submit={setSubmit} />;
      default:
        return "Unknown step";
    }
  };

  return (
    <div>
      <div className="col-12 flex space-between m-t-30">
        <div>
          <h2 className="searchtext center-text bold-text m-b-40">Employee Details</h2>
          <Container>
            <div>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => handleStepClick(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <div>
                  <p>All steps completed</p>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );

};

export default OnboardingDetailsEdit;
