import { useState, useEffect, useContext } from "react";

import { get } from "../../utility/fetch";
import Cookies from "js-cookie";
import ReviewModal from "../modal/ReviewModal";
import { feedback } from "../modal/feedback";
import UpdateReviewModal from "../modal/UpdateReviewModal";
import MyContext from "../../utility/Context";
import OnboardingDetails from "../page modifiers/OnboardingDetails";
import moment from "moment";
import OnboardingDetailsEdit from "../page modifiers/OnboardingDetailsEdit";


const MyRecords = ({ history }) => {



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [payload, setPayload] = useState()
    const [userDependants, setUserDependants] = useState({});
    const [userEducation, setUserEducation] = useState({});
    const [userProfessional, setUserProfessional] = useState({});
    const [userNextOfKins, setUserNextOfKins] = useState({});
    const [userProfile, setUserProfile] = useState({})
    const [staffId, setStaffId] = useState(Number);

    const [currentCadre, setCurrentCadre] = useState('')
    const [firstAppCadre, setFirstAppCadre] = useState('')

    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        console.log("closeModal")
        setIsModalOpen(false)
        setMyRecord(false)
        console.log("closeModal", isModalOpen)
    };
    const { userRin, setExitToBeViewed, setUserRin, setMyRecord, setSentBack, setUpdateToBeViewed } = useContext(MyContext);

    const userId = Cookies.get('userId')
    const authStatus = Cookies.get('authStatus');

    useEffect(() => {
        setUpdateToBeViewed({});
        const fetchData = async () => {
            window.scrollTo(0, 0);
            document.title = "EDSG Profile";

            await fetchProfile();

            console.log(staffId);

            const fetchDetails = async (id) => {
                const dependants = await get(`dependants/${id}`);
                const education = await get(`education/${id}`);
                const professional = await get(`profcerts/${id}`);
                const nextOfKin = await get(`nok/${id}`);
            
                const result = {};
            
                if (dependants.length > 0) result.dependants = dependants;
                if (education.length > 0) result.education = education;
                if (professional.length > 0) result.professional = professional;
                if (nextOfKin.length > 0) result.nextOfKin = nextOfKin;
            
                return result;
            };
            
            try {
                const staffResponse = staffId ? await fetchDetails(staffId) : null;
                const userResponse = userId ? await fetchDetails(userId) : null;

                if (staffResponse ) {
                    if (staffResponse.dependants) setUserDependants(staffResponse.dependants);
                    if (staffResponse.education) setUserEducation(staffResponse.education);
                    if (staffResponse.professional) setUserProfessional(staffResponse.professional);
                    if (staffResponse.nextOfKin) setUserNextOfKins(staffResponse.nextOfKin);
                    console.log(userDependants, userNextOfKins, staffResponse, userResponse);

                }

                if (userResponse) {
                    if (userResponse.dependants) setUserDependants(userResponse.dependants);
                    if (userResponse.education) setUserEducation(userResponse.education);
                    if (userResponse.professional) setUserProfessional(userResponse.professional);
                    if (userResponse.nextOfKin) setUserNextOfKins(userResponse.nextOfKin);
                    console.log(userDependants, userNextOfKins, staffResponse, userResponse);

                }
                console.log(userDependants, userNextOfKins, staffResponse, userResponse);
            } catch (error) {
            }


        };

        fetchData();
    }, [staffId, userId]);




    const fetchProfile = async () => {
        await get(`bio/${userId}`)
            .then(response => {
                setUserProfile(response?.data)
                setStaffId(response?.data?.staffId)
                console.log("User DATA::", response);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }





    if (loading) {
        return (
            <div className="processing">
                <p className="processing-text">Fetching Records...</p>
            </div>
        );
    }

    const selectedRecord = (record) => {
        console.log('Clicked on exit record:', record);
        setUserRin(record?.rin)
        setMyRecord(true)
        setSentBack(false)
    }


    function calculateDuration(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        const years = end.diff(start, 'years');
        start.add(years, 'years');
        const months = end.diff(start, 'months');
        start.add(months, 'months');
        const days = end.diff(start, 'days');

        return {
            years,
            months,
            days
        };
    }

    const currentDate = moment().format('YYYY-MM-DD')
    const duration = calculateDuration(userProfile?.dateOfFirstApp, currentDate);

    console.log('hey', duration, currentDate)

    const formatDate = (timestamp) => {
        if (timestamp === null || timestamp === undefined) {
            return
        }
        const dateObject = new Date(timestamp);
        const formattedDate = dateObject.toISOString().split('T')[0];
        return formattedDate;
    };


    return (
        <div className="w-100">
            <button className="submit-btn m-b-20  col-4 float-right" onClick={() => { selectedRecord(userProfile); history?.push('/admin-portal/data-update') }}>Initiate Update Request</button>
            <div className="bottom-border" />

            <div>
                <OnboardingDetailsEdit
                    userId={userId}
                    staffId={staffId}
                    userProfile={userProfile}
                    userDependants={userDependants}
                    userEducation={userEducation}
                    userProfessional={userProfessional}
                    userNextOfKins={userNextOfKins}
                    review={true}
                    disabled={true}
                    authStatus={'authStatus'}
                    type={'Update'}
                    setPayload={setPayload}
                    payload={payload}
                    canEdit={false}
                    employeeRin={userRin}
                    setUserRin={setUserRin}
                />
            </div>
        </div>
    )
}

export default MyRecords;