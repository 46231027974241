import React from 'react';
import PortalRoutes from '../routes/PortalRoutes';
import Sidebar from './layouts/Sidebar';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import menuList from '../config/sidebarMenu';

function UserPortal(props) {
  const { history } = props;

  const ToSupport = () => {
    window.location.href = 'https://greenzonetechnologies.atlassian.net/servicedesk/customer/portals';
  }

  return (
    <div className="container">
      <Sidebar history={history} menuList={menuList} />
      <div className="portal">
        <Header navList={['Home', 'Notification', 'Set Reminder']} />
        <div className="content main-content">
          <PortalRoutes />
          <div style={{bottom: '10%', left: '88%', position: 'fixed'}}>
            <img onClick={ToSupport} style={{width: '140px', height: '180px', cursor: 'pointer'}} alt='support' src="/Support.svg"></img>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default UserPortal;
