import React from "react";

const Card = ({ status, count, onClick, active }) =>{
    return (
        <div className={`  ${active ? 'card-active' : 'card'} m-r-20`} onClick = {onClick}>
            <div className="cardStatus">{status}</div>
            <div className="cardCount">{count}</div>
            <div className="inner"></div>
        </div>
    );
}


export default Card;