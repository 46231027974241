// Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import SidebarMenu from '../../config/sidebarMenu';
import EdsgLogo from '../../assets/images/edsg-logo-250.png';

const Sidebar = ({ history }) => {
  const { location: { pathname } } = history;
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      const items = await SidebarMenu();
      setMenuList(items);
    };

    fetchMenuItems();
  }, []);

  return (
    <nav className="page-sidebar">
      <div className="sidebar-header">
        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
      </div>

      <div className="sidebar-menu">
        <ul className="menu-items">
          {menuList.map((item) => (
            <MenuItem key={item.title} item={item} pathname={pathname} />
          ))}
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ item, pathname }) => {
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      <li className={`${pathname === item.href ? 'active' : ''}`}>
        {item.icon}
        {item.children ? (
          <>
            <Link
              onClick={() => setIsShowingSub(!isShowingSub)}
              to="#"
              className="has-sub-menu"
            >
              <span className="title">{item.title}</span>
            </Link>
            {item.children && <IoIosArrowBack className={`${isShowingSub ? 'open' : ''} arrow`} />}
          </>
        ) : (
          <Link to={item.href}>
            <span className="title">{item.title}</span>
          </Link>
        )}
      </li>
      {item.children && isShowingSub && (
        <ul className={`${isShowingSub ? 'show' : ''} sub-menu`}>
          {item.children && item.children.map((sub) => (
            <li className={`${pathname === sub.href ? 'active' : ''}`} key={sub.title}>
              <Link to={sub.href}>{sub.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
