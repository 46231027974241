import React, { useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { feedback } from "../modal/feedback";

const UpdateNokEdit = ({
  isOpen,
  onClose,
  index,
  relationship,
  handleUpdateChange,
  handleDateChangeUpdate,
  updateRecord,
  id,
  isFormValid,
}) => {

  const [selectedDate, setSelectedDate] = useState('');
  const [mobile, setMobile] = useState('');

  if (!isOpen) {
    return null;
  }

  const resetForm = () => {
    setSelectedDate(null);
    setMobile("");
  };

  const handleInput = (field, value) => {
    const selectedValue = value.target.value;
    if (field === "nokMobile") {
      // Validate if the value is a number and has a length of 11
      if (!isNaN(selectedValue) && selectedValue.length <= 11) {
        handleUpdateChange("nokMobile", selectedValue);
        setMobile(selectedValue);
        console.log('mobile', selectedValue);
      } else {
        // Display an error or handle the invalid input accordingly
        console.error("Invalid input for nokMobile");
        feedback({
          title: "Failed",
          text: "Invalid input for Mobile Number",
          iconType: "error",
        });
      }
    }
  };

  // const handleUpdate = async (e, value) => {
  //   if (isFormValid()) {
  //     await updateRecord(e, value);
  //   }
  // };

  const handleDate = (field, date, record) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setSelectedDate(date);

    handleDateChangeUpdate(field, date, record);
  };

  return (
    <div className="overlay">
      <button className="svg close-btn" onClick={() => { onClose(); resetForm(); }}>
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
          </path>
        </svg>
      </button>
      <form className="col-4 modal-container " >
        <div className="qualification-form m-t-30">
          <div className="m-b-10 m-t-30">
            <label>Name</label>
            <InputField
              placeholder='Name'
              required
              onChange={(value) => handleUpdateChange("nokName", value)}
            />
          </div>

          <div className="m-b-10">
            <label>Address</label>
            <InputField
              placeholder='Address'
              required
              onChange={(value) => handleUpdateChange("nokAddress", value)}
            />
          </div>

          <div className="m-b-10">
            <div className="col-7 no-padding no-margin">
              <label>Relationship</label>
              <Dropdown
                options={relationship}
                placeholder='Select Relationship'
                required
                onChange={(value) => handleUpdateChange("nokRelation", value)}
              />
            </div>
            <div className="col-5 no-p-r no-margin">
              <label>Date Of Birth</label>
              <DatePicker className="input-field p-r-5 p-l-5"
                placeholderText="Date Of Birth"
                required
                selected={selectedDate}
                dateFormat="dd/MM/yyyy"
                isClearable
                onChange={(date) => handleDate("nokDoB", date)}
                showMonthDropdown
                showYearDropdown={true}
                scrollableYearDropdown
                yearDropdownItemNumber={200}
              />
            </div>
          </div>

          <div className="m-b-10">
            <label>Phone</label>
            <InputField
              placeholder='Phone Number'
              required
              onChange={(value) => handleInput("nokMobile", value)}
              value={mobile}
            />
          </div>

          <div className="flex space-between">
            <button
              className="btn m-t-20 m-b-20"
              onClick={(e) => {updateRecord(e, id); resetForm()}}
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateNokEdit;
