import { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { post } from "../../utility/fetch";

const EducationForm = ({ index, handleInputChange, clearForm, handleDateChange, selectedDate, handleRemoveQualification }) => {

  const certificates = [
    { value: "FSLC", label: "FSLC" },
    { value: "WAEC", label: "WAEC" },
    { value: "NECO", label: "NECO" },
    { value: "GCE", label: "GCE" },
    { value: "NABTEB", label: "NABTEB" },
    { value: "OND", label: "OND" },
    { value: "HND", label: "HND" },
    { value: "BSC", label: "BSC" },
    { value: "BENG", label: "BENG" },
    { value: "BA", label: "BA" },
    { value: "BL", label: "BL" },
    { value: "MBA", label: "MBA" },
    { value: "MEND", label: "MEND" },
    { value: "MED", label: "MED" },
    { value: "TCII", label: "TCII" },
    { value: "LLB", label: "LLB" },
    { value: "LLM", label: "LLM" },
    { value: "MSC", label: "MSC" },
    { value: "PGD", label: "PGD" },
    { value: "PHD", label: "PHD" },
    { value: "NCE", label: "NCE" },
    { value: "B.ED", label: "B.ED" },
    { value: "DIPLOMA", label: "DIPLOMA" },
    { value: "DBA", label: "DBA" },
    { value: "DVM", label: "DVM" },
    { value: "MBBS", label: "MBBS" },
    { value: "OD", label: "OD" },
    { value: "BDS", label: "BDS" },
    { value: "BNSC", label: "BNSC" },
    { value: "BMLS", label: "BMLS" },
    { value: "RN", label: "RN" },
    { value: "PharmD", label: "PharmD" },
  ];

  const degrees = [
    { value: "First Class", label: "First Class Honours" },
    { value: "Second Class", label: "Second Class Honours" },
    { value: "Third Class", label: "Third Class Honours" },
    { value: "Pass ", label: "Pass" },
  ]





  const Reset = () => {
    document.querySelector('form[name="edu-form"]').reset()
    console.log('reset')
  }



  return (
    <form name="edu-form">
      <div>
        <button className="btn" onClick={Reset}>clear inputs</button>
      </div>
      <div key={index} className="qualification-form m-t-30">
        <div className="m-b-20">
          <label >Name Of School</label>
          <InputField
            placeholder="Name Of School"
            required
            onChange={(value) => {
              handleInputChange("schoolName", value, index)
            }}
          />
        </div>

        <div className="m-b-20">
          <label >Course Of Study</label>
          <InputField
            placeholder="Course Of Study"
            required
            onChange={(value) => {
              handleInputChange("course", value, index)
            }}
          />
        </div>

        <div className="m-b-20">
          <div className="col-7 no-padding no-margin">
            <label >Certificate Obtained</label>
            <Dropdown
              options={certificates}
              placeholder="Select Certificate Obtained"
              required
              onChange={(value) => {
                handleInputChange("certificate", value, index);
              }} />
          </div>
          <div className="col-5 no-p-r no-margin">
            <label >Year of graduation</label>
            <DatePicker className="input-field p-r-5 p-l-5"
              placeholderText="Year of graduation"
              selected={selectedDate}
              required
              dateFormat="yyyy"
              isClearable
              onChange={handleDateChange}
              showYearDropdown={true}
              showYearPicker
              scrollableYearDropdown
              yearDropdownItemNumber={200}
            />
          </div>
        </div>

        <div className="m-b-20">
          <label >Class of Degree</label>
          <Dropdown
            options={degrees}
            placeholder="Select Class of Degree (Where Applicable)"
            onChange={(value) => {
              handleInputChange("classOfDegree", value, index);
            }} />
        </div>

        <div className="m-b-20">
          <label >Remarks</label>
          <TextAreaInputField
            placeholder="Remarks"

            onChange={(value) => {
              handleInputChange("remarks", value, index);
            }} />
        </div>

        {/* <button className="edit-btn m-b-20" onClick={() => handleRemoveQualification(index)}>Remove Qualification</button> */}
      </div>
    </form>
  );

};

export default EducationForm;