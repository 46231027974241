import Select from "react-select";
import TextAreaInputField from "../inputs/TextAreaInputField";
import ApproversJourney from "../page modifiers/ApproversJourney";
import TakeAction from "../page modifiers/TakeAction";
import InputField from "../inputs/InputField";
import { useState, useEffect } from "react";
import { get, put } from "../../utility/fetch";
import { feedback } from "./feedback";
import UpdateRequestDetails from "../page modifiers/UpdateRequestDetails";
import Cookies from "js-cookie";
import AssignMemo from "../page modifiers/AssignMemo";
import DownloadUpdateRecord from "../ReviewRecordsDetails/DownloadUpdateRecord";

const UpdateReviewModal = ({ recordToBeReviewed, closeModal, userID, fetchAssignedUpdate, fetchUpdate, type, userRecord, userRin }) => {
    const [toName, setToName] = useState([])
    const [userId, setUserId] = useState(Number)
    const [reviewerComment, setReviewerComment] = useState('')
    const [action, setAction] = useState('')
    const [assignerComment, setAssignerComment] = useState('')
    const [assignee, setAssignee] = useState({
        assigneeName: "",
        assigneeUserId: 0,
        assigneeMDAId: 0
    })
    const [loading, setLoading] = useState(false);
    const approverName = Cookies.get("fullName")
    const [userDependants, setUserDependants] = useState([]);
    const [userEducation, setUserEducation] = useState([]);
    const [userProfessional, setUserProfessional] = useState([]);
    const [userNextOfKins, setUserNextOfKins] = useState([]);
    const [userProfile, setUserProfile] = useState({})
    const [staffId, setStaffId] = useState('');
    const [isDownload, setIsDownload] = useState(false);
    const [wrongStaffId, setWrongStaffId] = useState(false);
    const [UpdateOption, setUpdateOption] = useState([])




    const approverUserId = Cookies.get('userId')

    const openDownloadModal = () => { setIsDownload(true) }
    const closeDownloaModal = () => {
        console.log("closeModal")
        setIsDownload(false)
        console.log("closeModal", isDownload)
    };


    const getUpdateType = async (updateTypeId) => {
        try {
            const Response = await get(`UpdateType/updateType/id/${updateTypeId}`)
            if (Response) {
                console.log(Response)
                setUpdateOption(Response)

            }
            else {
                Notification({
                    type: 'danger',
                    title: 'Error',
                    message: 'Something went wrong please refresh your page'
                })
            }
        } catch (error) {
            Notification({
                type: 'danger',
                title: 'Error',
                message: 'Failed to fetch update types'
            })
        }
    }

    const fetchUserData = async () => {
        await get(`fetchuser?staffid=${userRin}`)
            .then(response => {
                setUserId(response?.id)
                setStaffId(response?.staffID)
                console.log(staffId)

                console.log("User DATA::", response);
            })
            .catch(error => {

                console.error('Error fetching states', error)
            })
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "EDSG Profile";

        setToName(recordToBeReviewed?.updateRequestToApprovers)

        console.log(staffId)


        setUserProfile(userRecord && userRecord)
        setUserDependants(userRecord && userRecord);
        setUserEducation(userRecord && userRecord?.updateRequestEducationalRecords);
        setUserProfessional(userRecord && userRecord?.updateRequestProfessionalQualifications);
        setUserNextOfKins(userRecord && userRecord);
        getUpdateType(recordToBeReviewed?.updateTypeId)

    }, []);





    console.log(userProfile, userEducation, userProfessional)

    // const user={name: "David Obiageli"}
    // if (!isOpen) {
    //     return null; // Don't render anything if the modal is closed.
    // }




    const assignReview = async () => {
        let payload
        console.log('assign toggled', action, reviewerComment, recordToBeReviewed?.id, recordToBeReviewed?.status, assignee)
        setLoading(true)

        payload = {
            details: assignerComment,
            updateId: recordToBeReviewed?.id,
            approverName: approverName,
            approverUserId: Number(approverUserId),
            assigneeName: assignee[0]?.assigneeName,
            assigneeUserId: assignee[0]?.assigneeUserId,
            assigneeMDAId: assignee[0]?.assigneeMDAId
        }
        console.log("pay;oad", payload)
        await put(`update/assign-memo/update/${recordToBeReviewed?.id}/assign-memo`, payload)
            .then(response => {
                console.log("Assign Memo::", response);
                feedback({
                    title: "Success",
                    text: "Successfully Assigned",
                    iconType: "success",
                });
                setLoading(false)
                fetchAssignedUpdate()
            })
            .catch(error => {
                console.error('Error fetching states', error)
                feedback({
                    title: "Failed",
                    text: "Could Not Handle Request",
                    iconType: "error",
                });
                setLoading(false)

            })
    }

    console.log(toName, reviewerComment, recordToBeReviewed?.id, recordToBeReviewed?.status)

    const review = async () => {
        let payload
        console.log(toName, reviewerComment, recordToBeReviewed?.id, recordToBeReviewed?.status)
        setLoading(true)
        if (reviewerComment === '' || reviewerComment === undefined || reviewerComment === null) {
            feedback({
                title: "No Comment",
                text: "Comment required",
                iconType: "warning",
            });
            setLoading(false)
            return
        }
        if (reviewerComment.length < 5) {
            feedback({
                title: "Comment To Short",
                text: "Comment should be at least 5 characters",
                iconType: "warning",
            });
            setLoading(false)
            return
        }
        if (action === "Back For Review") {
            payload = {
                approverUserId: Number(approverUserId),
                approvalStatus: "Back For Review",
                comment: reviewerComment,
                actionTaken: action
            }
        } else if (action === '' || action === 'Select Action') {
            feedback({
                title: "No Action Selected",
                text: "Select an action to take",
                iconType: "warning",
            });
            setLoading(false)
            return
        } else {
            payload = {
                approverUserId: Number(approverUserId),
                approvalStatus: "Approved",
                comment: reviewerComment,
                actionTaken: action
            }
        }
        await put(`update/Update-approval-status/update/${recordToBeReviewed?.id}`, payload)
            .then(response => {
                console.log("Review::", response);
                if (action === "Approved") {
                    feedback({
                        title: "Success",
                        text: `Successfully ${action}`,
                        iconType: "success",
                    });
                } else {
                    feedback({
                        title: "Success",
                        text: `Successfully Sent ${action}`,
                        iconType: "success",
                    });
                }
                fetchUpdate()
                setLoading(false)
                closeModal()
                setAction('')
                setReviewerComment('')
            })
            .catch(error => {
                console.error('Error fetching states', error)
                feedback({
                    title: "Failed",
                    text: "Could Not Handle Request",
                    iconType: "error",
                });
                setLoading(false)

            })
    }

    if (loading) {
        return (
            <div className="processing">
                <p className="processing-text center-text">Processing Request...</p>
            </div>
        );
    }

    console.log('Clicked on record:', recordToBeReviewed);

    recordToBeReviewed?.updateRequestDocuments?.map((item, index) => (
        console.log('Clicked on record:', item.id)

    ))


    return (
        <div className="overlay">
            <button className="svg close-btn" onClick={closeModal}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
                    </path>
                </svg>
            </button>

            <div className="modal-content">
                <div className="w-100">
                    <div className="flex flex-v-center space-between m-b-20">
                        <div className="flex flex-v-center">
                            <img src="/Vector.svg" alt="staff request" />
                            <div className="m-l-10 searchtext bold-text">Update Review</div>
                        </div>
                        <div className="flex space-between ">
                            {/* <div className="m-r-10 col-5 no-p-r no-margin">
                                <Dropdown
                                placeholder={'Select MDA'}
                                />
                                </div>
                            <button className="edit-btn  m-r-10">Return to Edit Mode</button> */}
                            <button
                                onClick={openDownloadModal}
                                // disabled = {staffId ? false : true}
                                className="download-btn"
                            >
                                To Download Page
                            </button>
                        </div>
                    </div>

                    <div className="seperator"></div>
                    <div className="w-100 bg-white">
                        <div className="col-6 m-t-40">
                            <div className="flex row space-between m-l-20">
                                <div className="col-5 no-padding">
                                    <label> Employee’s RIN</label>
                                    <InputField
                                        placeholder='Input employee’s RIN'
                                        value={recordToBeReviewed?.employeeRIN || ''}
                                        disabled='disabled'
                                    />
                                </div>
                                <div className="m-b-20 m-l-20">
                                    <label>Initiator's full name</label>
                                    <InputField
                                        placeholder='Staff full name'
                                        value={recordToBeReviewed?.from || ''}
                                        disabled='disabled'
                                    />
                                </div>

                            </div>

                            <div className="m-b-20 m-l-20">
                                <label>To</label>
                                <Select
                                    label='To'
                                    color='rgba(120,120,120,0.8)'
                                    className='m-b-20 w-w00'
                                    onChange={(value) => {
                                        console.log(value);
                                    }}
                                    isMulti={true}
                                    value={toName?.map(name => ({ value: name?.id, label: name?.name }))}
                                    isDisabled
                                />

                            </div>
                        </div>
                        {/* <div className="p-l-30 flex flex-v-center employeeDetail-banner m-b-40">
                            <img src="/+ icon.png" alt="icon" />
                            <p className="m-l-10">
                                Show Employee Details
                            </p>
                            
                        </div> */}
                        <UpdateRequestDetails
                            userId={userId}
                            staffId={staffId}
                            userProfile={userProfile}
                            userDependants={userDependants}
                            userEducation={userEducation}
                            userProfessional={userProfessional}
                            userNextOfKins={userNextOfKins}
                            recordToBeReviewed={recordToBeReviewed}
                            userRecord={userRecord}
                            employeeRIN={recordToBeReviewed?.employeeRIN}
                            updateType={UpdateOption}

                        />
                        {/* <div className="col-8 m-b-20 m-l-20">
                            <TextAreaInputField
                                value ={recordToBeReviewed?.comment}
                                disabled = 'disabled'
                            />
                        </div> */}
                        <div className="m-l-30">
                            <div>Uploaded Documents:</div>
                            {recordToBeReviewed?.updateRequestDocuments?.map((item) => {
                                // Split the file path based on the file separator ("/" or "\")
                                const pathParts = item?.filePath.split(/[\/\\_]/);

                                // Extract the last part of the path, which is the filename
                                const filename = pathParts[pathParts.length - 1];

                                return (
                                    <div className="m-t-10 flex" key={item?.id}>
                                        <a href={item?.filePath} target="_blank" className="m-r-10 uploads">
                                            {filename}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>

                        <div>
                            <ApproversJourney
                                recordToBeReviewed={recordToBeReviewed}
                                toName={toName}
                                type={type}
                            />
                        </div>

                        {/* <div>
                            <AssignMemo
                                assignReview={assignReview}
                                setAssignerComment={setAssignerComment}
                                setAssignee={setAssignee}
                            />
                        </div> */}
                        {Number(approverUserId) !== recordToBeReviewed?.initiatorUserId && (
                            <div className="m-b-40">
                                <TakeAction
                                    setAction={setAction}
                                    setReviewerComment={setReviewerComment}
                                    review={review}
                                    status={recordToBeReviewed?.approvalStatus}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isDownload && (
                <DownloadUpdateRecord
                    userId={userId}
                    recordOnReview={recordToBeReviewed}
                    userRecord={userRecord}
                    closeModal={closeDownloaModal}
                    userDependants={userDependants}
                    userEducation={userEducation}
                    userProfessional={userProfessional}
                    userNextOfKins={userNextOfKins}
                    wrongStaffId={wrongStaffId}
                    employeeRIN={recordToBeReviewed?.employeeRIN}

                />
            )}
        </div>
    )
}

export default UpdateReviewModal;
