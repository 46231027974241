const Nomenclature_TSA_Development = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Inter-cadre letter</p>
                    <p>2: Appointment letter</p>
                    <p>3: Change of name (if any)</p>
                    <p>4: Biometric</p>
                    </div>
                </div>
    )
}

export default Nomenclature_TSA_Development;