import React from 'react';

import InputField from '../../inputs/InputField';
import ApproverDropdown from '../../inputs/ApproverDropdown';
import { useEffect, useState } from 'react';


const OfficialDetails = ({ data, currentCadre, firstAppCadre }) => {

  const [approvers, setApprovers] = useState([])
  const [recipientMda, setrecipientMda] = useState([])
  const [toName, setToName] = useState([])


 console.log(currentCadre,firstAppCadre)

  useEffect(() => {
    setApprovers(
      toName?.map((item) => ({
        userId: Number(item?.value),
        mdaId: recipientMda?.value,
        name: item?.label
      }))
    );
  }, [toName]);


  <ApproverDropdown
    approverType=""
    setApproverName={setToName}
    setApproverMda={setrecipientMda}
    approverMda={recipientMda}
    approverName={toName}
  />
  return (
    <div className="w-100 official-details">
      {!data || data === null ? (
        <p className='m-t-10'>No data to be displayed </p>
      ) : (
        <>
          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>Staff ID:</label>
              <InputField
                disabled
                className="w-100 m-b-10"
                label="Staff ID:  "
                value={data?.staffId || ''}
              />
            </div>

            <div className="col-6 no-margin m-b-20">
              <label>Email Address:</label>
              <InputField
                className="w-100 m-b-10"
                label="Email Address:  "
                value={data?.email || ''}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>Assigned Role:</label>
              <InputField
                className="w-100 m-b-10"
                label="Assigned Role:  "
                value={currentCadre?.label || currentCadre || ''}
                disabled
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Current Level:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.currentLevel || ''}
                disabled
              />
            </div>


          </div>
          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>Current Step:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.currentStep || ''}
                disabled
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Cadre Of First Appointment:</label>
              <InputField
                className="w-100 m-b-10"
                value={firstAppCadre?.label || firstAppCadre ||  ''}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>Salary Grade Level Of First Appointment:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.gradeOfFirstApp || ''}
                disabled
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Step Of First Appointment:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.stepOfFirstApp || ''}
                disabled
              />
            </div>
          </div>
          <div className='row'>
            <div className="col-6 no-margin m-b-20">
              <label>Date Of First Appointment:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.dateOfFirstApp || ''}
                disabled
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Employee Number:</label>
              <InputField
                className="w-100 m-b-10"
                value={data?.employeeNumber || ''}
                disabled
              />
            </div>

          </div>

          <div className="row">

            <div className="col-6 no-margin m-b-20">
              <label>File Number:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.fileNumber || ''}
                disabled
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Date Of Confirmation Of Employment:</label>
              <InputField
                className="w-100 m-b-10"
                label="Current Level:  "
                value={data?.dateOfConfirmation || ''}
                disabled
              />
            </div>

          </div>

          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>Date Of Present Position:</label>
              <InputField
                className="w-100 m-b-10"
                value={data?.dateOfPresentPosition || ''}
                disabled
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Salary Account Number::</label>
              <InputField
                className="w-100 m-b-10"
                label="Salary Account Number:  "
                value={data?.salaryAccountNumber || ''}
                disabled
              />
            </div>


          </div>


          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>Salary Domiciled Bank:</label>
              <InputField
                disabled
                className="w-100 m-b-10"
                label="Salary Domiciled Bank:  "
                value={data?.salaryDomiciledBank || ''}
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>BVN:</label>
              <InputField
                disabled
                className="w-100 m-b-10"
                label="BVN:  "
                value={data?.bvn || ''}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 no-margin m-b-20">
              <label>NIN::</label>
              <InputField
                disabled
                className="w-100 m-b-10"
                label="NIN:  "
                value={data?.nin || ''}
              />
            </div>
            <div className="col-6 no-margin m-b-20">
              <label>Assigned MDA:</label>
              <InputField
                className="w-100 m-b-10"
                label="Assigned MDA:  "
                value={data.mda && Object.keys(data?.mda).length !== 0 ? data?.mda?.name : ''}
                disabled
              />
            </div>
          </div>

        </>
      )}
    </div>
  )
};

export default OfficialDetails;
