import { useState } from "react";
import TextAreaInputField from "../inputs/TextAreaInputField";



const ReviewerComment = ({ isOpen, onClose, setComment, respondToComent }) => {
    const user = { name: "David Obiageli" }
    if (!isOpen) {
        return null; // Don't render anything if the modal is closed.
    }

    return (
        <div className="overlay">
            <button className="svg close-btn" onClick={onClose}>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
                    </path>
                </svg>
            </button>
            <div className="flex flex-h-center">
                <div className="col-6 modal-content ">
                    <div className="w-100">


                        <div className="col-11 m-t-10 m-l-20">
                            <label>Comment</label>
                            <TextAreaInputField
                                onChange={(e) => { setComment(e?.target?.value) }}
                            />
                        </div>
                        <div className="col-3 m-l-20 center-text">
                            <button className=" comment-btn" onClick={respondToComent}>Send</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    )
}

export default ReviewerComment;