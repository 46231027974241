import React, { useState } from "react";

const Dropdown = ({ options = [], disabled, selectedValue, onChange, kenprop, placeholder, labelKey = "label", valueKey = "value", required }) => {
  const [isValid, setIsValid] = useState(true);

  const handleBlur = (event) => {
    const selectedOption = event.target.value;
    const isInputValid = required ? selectedOption !== '' : true;
    console.log(isInputValid)
    setIsValid(isInputValid);
  };
  
  return (
    <div className={`dropdown-input select  ${isValid ? '' : 'invalid'}`} >
      <select className={`dropdown-input select  ${isValid ? '' : 'invalid'}`} required={required} disabled={disabled} value={selectedValue} onBlur={handleBlur} onChange={(e) => onChange(e.target.value)}>
        {kenprop && <option style={{fontStyle:'italic', fontWeight: 'bolder'}} value="">{kenprop}</option>}
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option[valueKey]}>
            {option[labelKey]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
