import { useState } from "react";
import Dropdown from "../inputs/Dropdown";
import TextAreaInputField from "../inputs/TextAreaInputField";
import ActionDropdown from "../inputs/ActionDropdown";
const TakeAction = ({ setReviewerComment, setAction, status, type, review , approve}) => {


    const [isToggled, setisToggled] = useState(false);


    const toggleAction = () => {
        setisToggled(!isToggled);
    };

    return (
        <div className="w-100">
            <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center space-between">
                <div className="bold-text">
                    Take Action
                </div>
                <div>
                    <button className="collapse-btn" onClick={toggleAction}>
                        {isToggled ? 'Collapse' : 'Expand'}
                    </button>
                </div>
            </div>
            {isToggled &&
                <div className="col-8 m-l-20">
                    <div className=" searchtext bold-text m-b-30">
                        Input Your Comment
                    </div>
                    <div className="m-b-20">
                        <TextAreaInputField
                            placeholder='Details'
                            onChange={(e) => { setReviewerComment(e?.target?.value) }}
                            required
                        />
                    </div>

                    {type !== 'Onboarding' ?
                        <div className="flex row space-between">

                            <div className="col-6 no-p-l">
                                <ActionDropdown
                                    setAction={setAction}
                                    status={status}
                                />
                            </div>

                            <div className="col-6 ">
                                <button className="submit-btn " onClick={review} >Submit Review</button>
                            </div>
                        </div>
                        :
                        <div className="flex row space-between">
                            <div className="col-6 ">
                                <button className="submit-btn " onClick={review} >Send back for review</button>
                            </div>

                            <div className="col-6 ">
                                <button className="submit-btn " onClick={approve} >Approve</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default TakeAction;