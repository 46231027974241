import React, { useContext, useState } from "react";
import RequestModal from "../../modal/RequestModal";
import MyContext from "../../../utility/Context";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import { del, get } from "../../../utility/fetch";
import { feedback } from "../../modal/feedback";
import UpdateRequestDetails from "../../page modifiers/UpdateRequestDetails";
import UpdateReviewModal from "../../modal/UpdateReviewModal";
import DeleteConfirmationModal from "../../modal/DeleteConfirmation";

const UpdateTable = ({ records, history, cardStatus, deleteUpdate, }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateRecordToBeViewed, setUpdateRecordToBeViewed] = useState(undefined)
  
  const [recordToDelete, setRecordToDelete] = useState(null);
  
  
  const { setUpdateToBeViewed, setUserRin, setMyRecord, sentBack, setSentBack } = useContext(MyContext);
  
  const closeModal = () =>{ setIsModalOpen(false); setUserRin('');};

  const [isModalOpenDel, setIsModalOpenDel] = useState(false);
  const closeModalDel = () => {setIsModalOpenDel(false); setUserRin('');}


  const selectedRecord = (record) => {
    console.log(record)
    if (cardStatus === 'Review') {
      console.log('Clicked on update record:', record);
      setUpdateToBeViewed(record);
      setMyRecord(true)
      setSentBack(true)
    } else {
      console.log('Clicked on update record:', record);
      setUpdateToBeViewed(record);
      setMyRecord(true)
    }
  }

  const viewJourney = (record) => {

    console.log('Clicked on update record:', record);
    setUpdateRecordToBeViewed(record);
    setUserRin(record?.employeeRIN)
    setIsModalOpen(true)
    console.log('Clicked on update record:', isModalOpen);
  }

  const formatDate = (timestamp) => {
    const dateObject = new Date(timestamp);
    const formattedDate = dateObject.toISOString().split('T')[0];
    return formattedDate;
  }

  const handleDeleteConfirmation = (recordId) => {
    setRecordToDelete(recordId);
    setIsModalOpenDel(true);
  };

  const confirmDelete = () => {
    // Call the deleteUpdate function with the record ID to delete
    if (recordToDelete) {
      deleteUpdate(recordToDelete);
      setIsModalOpenDel(false); // Close the modal after deletion
    }
  };


  return (
    <div className="w-100">
      <table className="bordered-table">
        <thead>
          <tr>
            <th className="center-text">Subject</th>
            <th className="center-text">Initiated By</th>
            <th className="center-text">Date Created</th>
            {cardStatus !== 'Saved' &&
              <th className="center-text">Status</th>
            }
            <th className="center-text">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(records) && records?.map((item, index) => (
            <tr key={index}>
              <td>{item?.subject}</td>
              <td>{item?.from}</td>
              <td>{formatDate(item?.createdAt)}</td>
              {cardStatus !== 'Saved' &&
                <td className="center-text">{item?.approvalStatus}</td>
              }
              <td className="center-text">
                {item?.actionTaken === 'Save' || item?.actionTaken === 'Back For Review' ? (
                  <div className="flex space-around  flex-v-center">
                    <div>
                      <RiEdit2Line className="pointer" style={{ fontSize: '20px', color: 'green' }} onClick={() => { selectedRecord(item); history?.push('/admin-portal/data-update') }} />
                    </div>

                    {item?.actionTaken === 'Save' &&
                      <div>
                        <RiDeleteBinLine style={{ fontSize: '20px', color: 'red' }} className="pointer" onClick={() => handleDeleteConfirmation(item?.id)} />
                      </div>
                    }
                  </div>

                ) : (
                  <button className="btn" onClick={() => { viewJourney(item); }}>View Details</button>
                )}
              </td>

            </tr>
          ))}

        </tbody>
      </table>
      {isModalOpen === true && (
        <UpdateReviewModal
          closeModal={closeModal}
          recordToBeReviewed={updateRecordToBeViewed}
          userRecord={updateRecordToBeViewed?.updateRequestEmployeeRecord}
          userID={updateRecordToBeViewed?.employeeUserId}
        />
      )}

      {isModalOpenDel === true && (
       <DeleteConfirmationModal
       closeModal={closeModalDel}
       confirmDelete={confirmDelete}
     />
      )}


    </div>
  );
};

export default UpdateTable;
