import React, { useState } from "react";

const InputField = (props) => {
  const [isValid, setIsValid] = useState(true);

  const handleBlur = (event) => {
    const inputValue = event.target.value;
    const isInputValid = props.required ? inputValue.trim() !== '' : true;
    setIsValid(isInputValid);
  };

  return (
    <div>
      <input
        className={`input-field ${isValid ? '' : 'invalid'}`}
        required={props.required}
        placeholder={props.placeholder}
        type={props.type || 'text'}
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        onBlur={handleBlur}
        disabled={props.disabled}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        min={props.minDate}
        max={props.maxDate}
      />
    </div>
  );
};

export default InputField;
