import React, { useState } from 'react';
import SelectInput from '../inputs/SelectInput';
import ApproverDropdown from '../inputs/ApproverDropdown';

function CreateAuthorisedUser({ setemployeeName, setapprovalMDA, employeeName, approvalMDA, roleList, handleChange, authoriseUser }) {
  const [isContentVisible, setContentVisible] = useState(false);

  

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  return (
    <div>
      <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center space-between">
        <div className="bold-text">Authorise a staff</div>
        <div>
          <button className="collapse-btn" onClick={toggleContent}>
            {isContentVisible ? 'Collapse' : 'Expand'}
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div className="m-b-20 col-6  ">
          <div>
            <label >Assign Role</label>
            <SelectInput
              options={roleList}
              onChange={(value) => handleChange('role', value)}


            />
          </div>
          <div className='m-t-10'>
            <ApproverDropdown
              approverType="Staff"
              setApproverName={setemployeeName}
              setApproverMda={setapprovalMDA}
              approverMda={approvalMDA}
              approverName={employeeName}
              isMulti={false}
              notAuthorized={true}
            />
            <button onClick={authoriseUser} className='submit-btn m-t-20'>Authorize</button>
          </div>

        </div>
      )}
    </div>
  );
}

export default CreateAuthorisedUser;
