

const Reports = () => {

    return (
        <div className="w-100">
            <table className="bordered-table">
                <thead>
                    <tr>
                        <th className="center-text">Subject</th>
                        <th className="center-text">Initiated By</th>
                        <th className="center-text">Date Created</th>
                        <th className="center-text">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    
                </tbody>
            </table>
        </div>
    )
}

export default Reports;