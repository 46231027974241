import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ReactNotifications } from 'react-notifications-component';

import Home from './components/home';
import Connect from './components/home/connect';

import ProtectedRoute from './routes/ProtectedRoute';
import UserPortal from './components/UserPortal';

function App() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <div>
        <ReactNotifications />
        <Switch>
          <Route path="/" exact component={Connect} />

          <ProtectedRoute path="/admin-portal/" component={UserPortal} />
          <Route render={() => <h1>Error 404. Page not found.</h1>} />
        </Switch>

      </div>
     
    </Router>
  );
}

export default App;
