import { useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { useEffect } from "react";


const ProfForm = ({ index, handleInputChange, handleDateChange, selectedDate, handleDateYearChange, year, setReset,reset, handleRemoveQualification }) => {

    // useEffect(()=>{
    //     setReset(Reset)
    // }, [])
    const certificates = [
        { value: "FSLC", label: "FSLC" },
        { value: "WAEC", label: "WAEC" },
        { value: "NECO", label: "NECO" },
        { value: "GCE", label: "GCE" },
        { value: "NABTEB", label: "NABTEB" },
        { value: "OND", label: "OND" },
        { value: "HND", label: "HND" },
        { value: "BSC", label: "BSC" },
        { value: "BENG", label: "BENG" },
        { value: "BA", label: "BA" },
        { value: "BL", label: "BL" },
        { value: "MBA", label: "MBA" },
        { value: "MEND", label: "MEND" },
        { value: "MED", label: "MED" },
        { value: "TCII", label: "TCII" },
        { value: "LLB", label: "LLB" },
        { value: "LLM", label: "LLM" },
        { value: "MSC", label: "MSC" },
        { value: "PGD", label: "PGD" },
        { value: "PHD", label: "PHD" },
        { value: "NCE", label: "NCE" },
        { value: "B.ED", label: "B.ED" },
        { value: "DIPLOMA", label: "DIPLOMA" },
        { value: "DBA", label: "DBA" },
        { value: "DVM", label: "DVM" },
        { value: "MBBS", label: "MBBS" },
        { value: "OD", label: "OD" },
        { value: "BDS", label: "BDS" }
    ];

    const [expDate, setExpDate] = useState(null)

    const Reset = () => {
        document.querySelector('form[name="prof"]').reset()
        handleDateChange(null);
        handleDateYearChange(null)
       


        console.log('reset')
    }


    return (
        <form name="prof">
            <div>
                <button className="btn" onClick={Reset}>clear inputs</button>
            </div>
            <div key={index} className="qualification-form m-t-30">
                <div className="float-right m-t-20 m-b-20">
                </div>
                <div className="m-b-20">
                    <label >Certification Type</label>
                    <InputField
                        placeholder='Certification Type'
                        required
                        onChange={(value) => handleInputChange("certType", value)}
                    />
                </div>

                <div className="m-b-20">
                    <label >Certificate Obtained</label>
                    <InputField
                        placeholder='Certificates Obtained'
                        required
                        onChange={(value) => handleInputChange("certObtained", value)}

                    />
                </div>

                <div className="m-b-20">
                    <div className="col-7 no-padding no-margin">
                        <label >Certificate/License Number</label>
                        <InputField
                            placeholder='Certificate/License Number'
                            required
                            onChange={(value) => handleInputChange("certNumber", value)}
                        />
                    </div>
                    <div className="col-5 no-p-r no-margin">
                        <label >Expiration Date</label>
                        <DatePicker className="input-field p-r-5 p-l-5"
                            selected={selectedDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            onChange={handleDateChange}
                            placeholderText="Expiration Date"
                            showMonthDropdown
                            showYearDropdown={true}
                            scrollableYearDropdown
                            yearDropdownItemNumber={200}
                            required />
                    </div>
                </div>

                <div className="m-b-20">
                    <label >Year of attaining certification</label>
                    <DatePicker className="input-field p-r-5 p-l-5"
                        selected={year}
                        dateFormat="yyyy"
                        isClearable
                        onChange={handleDateYearChange}
                        placeholderText="Year of attaining certification"
                        showYearPicker

                        required />
                </div>

                <div className="m-b-20">
                    <label >Remarks</label>
                    <TextAreaInputField
                        placeholder='Remarks'
                        onChange={(value) => handleInputChange("remarks", value)}
                    />
                </div>
                {/* <button className="edit-btn m-b-20" onClick={() => handleRemoveQualification(index)}>Remove Qualification</button> */}
            </div>
        </form>
    )

}

export default ProfForm