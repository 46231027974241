import { useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { put, del } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import UpdateNok from "./UpdateNok";
import UpdateNokEdit from "./UpdateNokEdit";

const DisplayNokEdit = ({ userId, setNokRecord, nokRecord, index, disabled, handleFormData, canEdit }) => {
  const [updateForm, setUpdateForm] = useState(nokRecord || {});
  const [formStates, setFormStates] = useState({
    updateForm: { ...nokRecord },
    selectedDate: nokRecord?.nokDoB ? new Date(nokRecord?.nokDoB) : null,
    isEditing: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  useEffect(() => {
    setFormStates({
      updateForm: { ...nokRecord },
      selectedDate: nokRecord?.nokDoB ? new Date(nokRecord?.nokDoB) : null,
      isEditing: true,
    });
  }, []);

  const toggleEdit = (e) => {
    e.preventDefault();
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      isEditing: !prevFormStates.isEditing,
    }));
  };

  const openModal = (id) => {
    console.log(id)
    setSelectedRecordId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUpdateForm({})
  };

  console.log(formStates.updateForm)

  const updateRecord = async (e, id) => {
    e.preventDefault();
    try {
      const response = await put(`nok/${id}`, formStates.updateForm);
      setNokRecord(response?.data);
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
      closeModal();
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.message,
        iconType: "error",
      });
    }
  };

  const deleteRecord = async (e, id) => {
    e.preventDefault();
    try {
      const response = await del(`nok/${id}`);
      setNokRecord(null);
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.message,
        iconType: "error",
      });
    }
  };

  const handleDateChangeUpdate = (field, date) => {
    const formattedDateWithoutTimestamp = date ? new Date(date).toLocaleDateString() : "";
    setUpdateForm((prevData) => ({
      ...prevData,
      nokDoB: formattedDateWithoutTimestamp,
    }));
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      selectedDate: date,
      updateForm: {
        ...prevFormStates.updateForm,
        [field]: formattedDateWithoutTimestamp,
      },
    }));
    handleFormData(formStates.updateForm);
  };

  const handleUpdateChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    setUpdateForm((prevData) => ({
      ...prevData,
      [field]: selectedValue,
    }));
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      updateForm: {
        ...prevFormStates.updateForm,
        [field]: selectedValue,
      },
    }));
    handleFormData(formStates.updateForm);
  };

  const relationship = [
    { value: "Brother", label: "Brother" },
    { value: "Cousin", label: "Cousin" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Father in law", label: "Father in law" },
    { value: "Mother", label: "Mother" },
    { value: "Mother in law", label: "Mother in law" },
    { value: "Nephew", label: "Nephew" },
    { value: "Niece", label: "Niece" },
    { value: "Sister", label: "Sister" },
    { value: "Son", label: "Son" },
    { value: "Spouse", label: "Spouse" },
    { value: "Uncle", label: "Uncle" },
    { value: "Granddaughter", label: "Granddaughter" },
    { value: "Grandson", label: "Grandson" },
    { value: "Grandfather", label: "Grandfather" },
    { value: "Grandmother", label: "Grandmother" },
  ];

  return (
    <div>
      <div className="qualification-form m-t-30">
        <div className="m-b-20 m-t-30">
          <label>Name</label>
          <InputField
            placeholder="Name"
            onChange={(value) => handleUpdateChange("nokName", value)}
            required
            value={nokRecord?.nokName}
            disabled={disabled}
          />
        </div>

        <div className="m-b-20">
          <label>Address</label>
          <InputField
            placeholder="Address"
            onChange={(value) => handleUpdateChange("nokAddress", value)}
            required
            value={nokRecord?.nokAddress}
            disabled={disabled}
          />
        </div>

        <div className="m-b-20">
          <div className="col-7 no-padding no-margin">
            <label>Relationship</label>
            <InputField
              placeholder="Select Relationship"
              onChange={(value) => handleUpdateChange("nokRelationship", value)}
              required
              value={nokRecord?.nokRelation}
              disabled={disabled}
            />
          </div>
          <div className="col-5 no-p-r no-margin">
            <label>Date Of Birth</label>
            <DatePicker
              className="input-field p-r-5 p-l-5"
              placeholderText="Date Of Birth"
              selected={nokRecord?.nokDoB ? new Date(nokRecord?.nokDoB) : null}
              onChange={(date) => handleDateChangeUpdate("nokDoB", date)}
              required
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown={true}
              scrollableYearDropdown
              yearDropdownItemNumber={200}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="m-b-20">
          <label>Phone</label>
          <InputField
            placeholder="Phone Number"
            required
            onChange={(value) => handleUpdateChange("nokMobile", value)}
            value={nokRecord?.nokMobile}
            disabled={disabled}
          />
        </div>

        {canEdit === true && (
          <div>
            <div className="flex space-between">
              <button className="btn m-t-20 m-b-20" onClick={(e)=>{openModal(nokRecord?.id)}}>
                Edit
              </button>
              <button className="edit-btn m-t-20 m-b-20" onClick={(e) => deleteRecord(e, nokRecord?.id)}>
                Remove Next Of Kin
              </button>
            </div>
          </div>
        )}
        <div>
          <UpdateNokEdit
            isOpen={isModalOpen}
            onClose={closeModal}
            formState={formStates}
            relationship={relationship}
            handleUpdateChange={handleUpdateChange}
            handleDateChangeUpdate={handleDateChangeUpdate}
            updateRecord={(e) => updateRecord(e, nokRecord?.id)}
            id={selectedRecordId}
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayNokEdit;
