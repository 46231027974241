import { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { get, post, put } from "../../utility/fetch";
import { feedback } from "../modal/feedback";


const PersonalInfoEdit = (props) => {
  const [formData, setFormData] = useState();
  const [nationality, setNationality] = useState([]);
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [religion, setReligion] = useState([]);

  const [mobile, setMobile] = useState({})
  const [nin, setNin] = useState('')


  useEffect(() => {
    if (props?.data) {
      setFormData({
        nationalityId: props?.data?.nationalityId || props?.data?.nationality?.id || 0,
        stateId: props?.data?.stateId || props?.data?.state?.id || 0,
        lga: props?.data?.lga || "",
        homeTown: props?.data?.homeTown || "",
        permHomeAddress: props?.data?.permHomeAddress || "",
        residentialAddress: props?.data?.residentialAddress || "",
        languageSpoken: props?.data?.languageSpoken || "",
        mobile: props?.rinData?.phoneNumber || props?.data?.mobile ||  props?.payload?.mobile ||  "",
        religionId: props?.data?.religionId || props?.data?.religion?.id ||  props?.data?.religion || 0,
        nin: props?.rinData?.nin || props?.data?.nin || "",
    });    
    }
    if (!formData?.mobile) {
      setFormData({
        nationalityId: props?.payload?.nationalityId || props?.data?.nationalityId || props?.data?.nationality?.id || 0,
        stateId: props?.payload?.stateId || props?.data?.stateId || props?.data?.state?.id || 0,
        lga: props?.payload?.lga || props?.data?.lga || "",
        homeTown: props?.payload?.homeTown || props?.data?.homeTown || "",
        permHomeAddress: props?.payload?.permHomeAddress || props?.data?.permHomeAddress || "",
        residentialAddress: props?.payload?.residentialAddress || props?.data?.residentialAddress || "",
        languageSpoken: props?.payload?.languageSpoken || props?.data?.languageSpoken || "",
        mobile: props?.payload?.phoneNumber || props?.rinData?.phoneNumber || props?.data?.mobile  || props?.payload?.mobile || "",
        religionId: props?.payload?.religionId || props?.data?.religion?.id || props?.data?.religionId || 0,
        nin: props?.payload?.nin || props?.rinData?.nin || props?.data?.nin || "",
      });
    }


    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
  }, [props?.data]);

  useEffect(() => {
    fetchNationality();
    fetchStates();
    fetchReligion();
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
  }, []);

  useEffect(() => {
    props?.handleFormData(formData);

  }, [formData])



  const id = props.ID


  const fetchUserProfile = async (id) => {
    try {
      const response = await post(`bio /${id}`);
      console.log(response)
      const id = response?.id
      props.handleFormData();
      if (response?.code === 1) {
        props.onIDUpdate(response?.data);
        props.handleNext();
        feedback({
          title: "Success",
          text: response?.message,
          iconType: "success",
        });
      }
      console.log(id)
      console.log('response:', response)
    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('failed to update perfonal info', response)
    }
  }

  console.log(id, formData, props?.payload?.mobile)

  const fetchReligion = async () => {
    await get('religion')
      .then(response => {
        setReligion(response.data)
      })
      .catch(error => {
        console.error('Error fetching religion', error)
      })
  }

  const fetchStates = async () => {
    await get('state')
      .then(response => {
        setStates(response.data)
      })
      .catch(error => {
        console.error('Error fetching states', error)
      })
  }

  const fetchNationality = async () => {
    await get('nationality')
      .then(response => {
        setNationality(response.data)
      })
      .catch(error => {
        console.error('Error fetching nationality', error)
      })
  }



  const putPersonalInfoEdit = async (data) => {
    try {
      const response = await put(`bio/personalInfoEdit/${id}`, data)
      props.handleFormData(data);
      console.log('personalInfoEdit', data);
      console.log('response', response);
      props.handleNext();
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });

    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('failed to update perfonal info', response)
    }
  }

  const handleInputChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "mobile" || field === "nin") {
      // Validate if the value is a number and has a length of 11
      if (!isNaN(selectedValue) && selectedValue.length <= 11) {
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));

        if (field === "mobile") {
          setMobile(selectedValue);
        } else if (field === "nin") {
          setNin(selectedValue);
        }
        console.log(field, ': ', selectedValue)

      } else {
        // For other fields, proceed without validation
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
      }
    } else if (field === "nationalityId") {
      console.log(selectedValue)
      if (selectedValue !== 19) {
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
          stateId: 38
        }));
        setStateId(38)

      } else {
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));
    }
  }
  const isFormValid = () => {

    const missingFields = [];

    // Check if all required fields are filled
    Object.entries(formData).forEach(([fieldName, value]) => {
      if (value === "" || value === null || value === undefined) {
        missingFields.push(fieldName);
      } else if (fieldName === "nin" || fieldName === "mobile") {
        // Validate nin and mobile fields with exactly 11 digits
        if (!/^\d{11}$/.test(value)) {
          missingFields.push(fieldName);
        }
      }


    });
    console.log("Missing fields:", missingFields);

    return { isValid: missingFields.length === 0, missingFields };
  }



  const handleSubmit = (e) => {
    if (!props?.review) {
      e.preventDefault();
      const { isValid, missingFields } = isFormValid();
      if (isValid) {
        putPersonalInfoEdit(formData);
        console.log("Form submitted:", formData);
        // Implement your logic to handle the form submission
      } else {
        if (missingFields) {
          const missingFieldNames = missingFields.map(fieldName => {
            // Convert camelCase field names to sentence case
            const formattedFieldName = fieldName.replace(/([A-Z])/g, ' $1').toLowerCase();
            return formattedFieldName;
          }).join(", ");

          const feedbackMessage = `Please fill in all required fields (${missingFieldNames}) before submitting.`;
          console.log(feedbackMessage);
          feedback({
            title: "Form not complete",
            text: feedbackMessage,
            iconType: "warning",
          });
        } else {
          console.log("Missing fields array is undefined.");
          // Handle the situation where missingFields array is undefined
        }
      }
    } else {
      props?.handleNext();
    }
  };


  return (
    <div className="form-step" data-step="2">
      <div className="m-b-20">
        <label>Nationality</label>
        <Dropdown
          options={nationality}
          placeholder='Select Nationality'
          onChange={(value) => handleInputChange("nationalityId", Number(value))}
          required
          labelKey="name"
          valueKey="id"
          selectedValue={formData?.nationalityId}
          disabled={props?.disabled}

        />
      </div>
      <div className="m-b-20">
        <label>State Of Origin</label>
        <Dropdown
          options={states}
          placeholder='Select State Of Origin'
          onChange={(value) => handleInputChange("stateId", Number(value))}
          required
          labelKey="name"
          valueKey="id"
          selectedValue={formData?.stateId || ""}
          disabled={props?.disabled}

        />
      </div>
      {/* <div className="m-b-20">
        <label>Local Government Of Origin</label>
        <InputField
          placeholder='Local Government Of Origin'
          onChange={(value) => handleInputChange("lga", value)}
          required
          value={formData?.lga}
          disabled={props?.disabled}

        />
      </div> */}
      <div className="m-b-20">
        <label>Home Town</label>
        <InputField
          placeholder='Home Town'
          disabled={props?.disabled}

          onChange={(value) => handleInputChange("homeTown", value)}
          required
          value={formData?.homeTown}
        />
      </div>
      <div className="m-b-20">
        <label>Permanent Home Address</label>
        <InputField
          placeholder='Permanent Home Address'
          onChange={(value) => handleInputChange("permHomeAddress", value)}
          required
          value={formData?.permHomeAddress}
          disabled={props?.disabled}

        />
      </div>
      <div className="m-b-20">
        <label>Residential Address</label>
        <InputField
          placeholder='Residential Address(If different from Permanent Home Address)'
          onChange={(value) => handleInputChange("residentialAddress", value)}
          value={formData?.residentialAddress}
          disabled={props?.disabled}

        />
      </div>
      <div className="m-b-20">
        <label>Language</label>
        <InputField
          placeholder='Language Spoken'
          disabled={props?.disabled}

          onChange={(value) => handleInputChange("languageSpoken", value)}
          required
          value={formData?.languageSpoken}
        />
      </div>
      <div className="m-b-20">
        <label>Phone Number</label>
        <InputField
          placeholder='Phone Number'
          onChange={(value) => handleInputChange("mobile", value)}
          required
          value={formData?.mobile}
          disabled={props?.disabled}

        />
      </div>
      <div className="m-b-20">
        <label>Religion</label>
        <Dropdown
          options={religion}
          placeholder='Select Religion'
          onChange={(value) => handleInputChange("religionId", Number(value))}
          required
          labelKey="name"
          valueKey="id"
          selectedValue={formData?.religionId?.id || formData?.religionId }
          disabled={props?.disabled}

        />
      </div>
      <div className="m-b-20">
        <label>National Identification Number (NIN)</label>
        <InputField
          placeholder='National Identification Number (NIN)'
          onChange={(value) => handleInputChange("nin", value)}
          required
          disabled={props?.authStatus !== 'ps-hr'}

          value={nin || formData?.nin}
        />
      </div>
      {props?.download !== true &&
        <div className="flex space-between">
          {props?.review !== false &&
            <button className="col-5  submit-btn next-step" onClick={props?.handleBack}>Previous</button>}
          <button className="col-5  submit-btn next-step" onClick={handleSubmit}>Next</button>
        </div>
      }
    </div>
  )
}

export default PersonalInfoEdit;