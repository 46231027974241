import Select from "react-select";
import TextAreaInputField from "../inputs/TextAreaInputField";
import ApproversJourney from "../page modifiers/ApproversJourney";
import TakeAction from "../page modifiers/TakeAction";
import InputField from "../inputs/InputField";
import React, { useState, useEffect } from "react";
import { get, put } from "../../utility/fetch";
import { feedback } from "./feedback";
import ExitEmployeeDetails from "../page modifiers/ExitEmployeeDetails";
import AssignMemo from "../page modifiers/AssignMemo";
import Cookies from "js-cookie";
import { PDFExport } from "@progress/kendo-react-pdf";
import PdfSave from "../layouts/PdfSave";
import DownloadExit from "./DownloadExit";
import DownloadExitRecord from "../ReviewRecordsDetails/DownloadExitRecord";

const ReviewModal = ({ recordToBeReviewed, closeModal, userID, requestsOnQueue, fetchAssignedExits, type }) => {
    const [throughName, setThroughName] = useState([])
    const [toName, setToName] = useState([])
    const [userId, setUserId] = useState(Number)
    const [reviewerComment, setReviewerComment] = useState('')
    const [action, setAction] = useState('')
    const [loading, setLoading] = useState(false);
    const [assignerComment, setAssignerComment] = useState('')
    const [isDownload, setIsDownload] = useState(false);
    const pdfExportComponent = React.useRef(null);
    const [staffId, setStaffId] = useState(Number)
    const [user, setUser] = useState({})
    const [userRin, setUserRin] = useState(Number)

    const [currentForm, setCurrentForm] = useState("official-details");
    const [isUpdating, setIsUpdating] = useState(false);
    const [recordToBeDownloaded, setrecordToBeDownloaded] = useState({})
    const [searchValue, setSearchValue] = useState('')
    const [recipientMda, setrecipientMda] = useState([])
    const [approvers, setApprovers] = useState([])
    const [thoroughMda, setThroughMda] = useState([])
    const [through, setThrough] = useState([])
    const [mFiles, setMFiles] = useState([])
    const [comment, setComment] = useState('')
    const [exitReason, setExitReason] = useState('')
    const [documentArray, setDocumentArray] = useState([])
    const [receiveImage, setReceiveImage] = useState([])
    const [recordId, setRecordId] = useState('')


    const [userDependants, setUserDependants] = useState([]);
    const [userEducation, setUserEducation] = useState([]);
    const [userProfessional, setUserProfessional] = useState([]);
    const [userNextOfKins, setUserNextOfKins] = useState([]);
    const [wrongStaffId, setWrongStaffId] = useState(false);

    const [currentCadre, setCurrentCadre] = useState('')
    const [firstAppCadre, setFirstAppCadre] = useState('')
    const [presMda, setPresMda] = useState('')




    const [assignee, setAssignee] = useState({
        assigneeName: "",
        assigneeUserId: 0,
        assigneeMDAId: 0
    })

    const openDownloadModal = () => { setIsDownload(true) }
    const closeDownloaModal = () => {
        console.log("closeModal")
        setIsDownload(false)
        console.log("closeModal", isDownload)
    };


    const fetchProfile = async () => {
        console.log(userId)
        await get(`bio/${userId}`)
            .then(response => {
                setrecordToBeDownloaded(response?.data)

                console.log("User recordToBeReviewed::", response);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }

    const fetchUserData = async () => {
        await get(`fetchuser?staffid=${userRin}`)
            .then(response => {
                setUserId(response?.id)
                setStaffId(response?.staffID)
                console.log(staffId)
                setUser(response)

                console.log("User DATA::", response);
            })
            .catch(error => {

                console.error('Error fetching states', error)
            })
    }



    const fetchDatas = async () => {
        const currentCadreData = await roleById(recordToBeDownloaded?.currentCadre);
        const firstAppCadreData = await roleById(recordToBeDownloaded?.firstAppCadre);

        setCurrentCadre(currentCadreData);
        setFirstAppCadre(firstAppCadreData);
    };

    const roleById = async (id) => {
        try {
            const response = await get(`role/${id}`)
            console.log('response', response);

            return { value: response?.data?.id, label: response?.data?.name }


        } catch (response) {

            console.error('failed to update perfonal info', response)
        }
    }


    useEffect(() => {

    }, []);

    useEffect(() => {
        setThroughName(recordToBeReviewed?.exitRequestThroughApprovers?.map(record => record?.name))
        setToName(recordToBeReviewed?.exitRequestToApprovers?.map(record => record?.name))
        setUserRin(recordToBeReviewed?.employeeRIN)


        window.scrollTo(0, 0);
        document.title = 'EDSG - Dashboard';
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsUpdating(true);
            window.scrollTo(0, 0);
            document.title = "EDSG Profile";

            await fetchUserData();
            await fetchProfile()
            fetchDatas()

            setIsUpdating(true);
            window.scrollTo(0, 0);
            document.title = "EDSG Profile";


            console.log(userRin)


            const dependants = await get(`dependants/${staffId}`);

            const education = await get(`education/${staffId}`);

            const professional = await get(`profcerts/${staffId}`);
            const nextOfKin = await get(`nok/${staffId}`);

            console.log(dependants, education, professional, nextOfKin)

            setUserDependants(dependants && dependants);
            setUserEducation(education && education);
            setUserProfessional(professional && professional);
            setUserNextOfKins(nextOfKin && nextOfKin);
            setIsUpdating(false);
            setWrongStaffId(false);

        };

        fetchData();

    }, [ staffId, userRin]);




    console.log(userRin, userId)



    const approverName = Cookies.get("fullName")
    const currentUser = Number(Cookies.get("userId"))


    // const user={name: "David Obiageli"}
    // if (!isOpen) {
    //     return null; // Don't render anything if the modal is closed.
    // }






    const review = async () => {
        let payload
        console.log(action, reviewerComment, recordToBeReviewed?.id, recordToBeReviewed?.status)
        setLoading(true)
        if (reviewerComment.length < 5) {
            feedback({
                title: "Comment too short",
                text: "Comment should not be less than 5 characters",
                iconType: "warning",
            });
            setLoading(false)
            return
        }
        if (reviewerComment === '' || reviewerComment === undefined || reviewerComment === null) {
            feedback({
                title: "No Comment",
                text: "Comment required",
                iconType: "warning",
            });
            setLoading(false)
            return
        }
        if (action === "Back For Review") {
            payload = {
                approverUserId: Number(userID),
                approvalStatus: "Back For Review",
                comment: reviewerComment,
                actionTaken: action
            }
        } else if (action === '' || action === 'Select Action') {
            feedback({
                title: "No Action Selected",
                text: "Select an action to take",
                iconType: "warning",
            });
            setLoading(false)
            return
        } else {
            payload = {
                approverUserId: Number(userID),
                approvalStatus: "Approved",
                comment: reviewerComment,
                actionTaken: action
            }
        }
        await put(`ExitRequest/update-approval-status/exist/${recordToBeReviewed?.id}`, payload)
            .then(response => {
                console.log("Review::", response);
               if (response.statusCode === 500) {
                feedback({
                    title: "failed",
                    text: `Failed to ${action}`,
                    iconType: "error",
                });
               }else if(response?.statusCode === 400){
                feedback({
                    title: "failed",
                    text: `Failed to ${action}`,
                    iconType: "error",
                });
               }else{
                if (action === "Back For Review") {
                    feedback({
                        title: "Success",
                        text: `Successfully sent ${action}`,
                        iconType: "success",
                    });
                }else if (action === "Approved"){
                    feedback({
                        title: "Success",
                        text: `Successfully ${action}`,
                        iconType: "success",
                    });
                }
               }
                
                setLoading(false)
                requestsOnQueue()
                closeModal()

            })
            .catch(error => {
                console.error('Error fetching states', error)
                feedback({
                    title: "Failed",
                    text: "Could Not Handle Request",
                    iconType: "error",
                });
                setLoading(false)

            })
    }


    const assignReview = async () => {
        let payload
        console.log('assign toggled', action, reviewerComment, recordToBeReviewed?.id, recordToBeReviewed?.status, assignee)
        setLoading(true)

        payload = {
            details: assignerComment,
            exitId: recordToBeReviewed?.id,
            approverName: approverName,
            approverUserId: Number(userID),
            assigneeName: assignee[0]?.assigneeName,
            assigneeUserId: assignee[0]?.assigneeUserId,
            assigneeMDAId: assignee[0]?.assigneeMDAId
        }
        console.log("pay;oad", payload)
        await put(`ExitRequest/assign-memo/exit/${recordToBeReviewed?.id}/assign-memo`, payload)
            .then(response => {
                console.log("Assign Memo::", response);
                feedback({
                    title: "Success",
                    text: "Successfully Assigned",
                    iconType: "success",
                });
                setLoading(false)
                fetchAssignedExits()
            })
            .catch(error => {
                console.error('Error fetching states', error)
                feedback({
                    title: "Failed",
                    text: "Could Not Handle Request",
                    iconType: "error",
                });
                setLoading(false)

            })
    }

    if (loading) {
        return (
            <div className="processing">
                <p className="processing-text center-text">Processing Request...</p>
            </div>
        );
    }

    console.log('Clicked on record:', approverName, assignee, assignerComment, recordToBeReviewed);

    recordToBeReviewed?.exitRequestDocuments?.map((item, index) => (
        console.log('Clicked on record:', item.id)

    ))


    return (
        <div className="overlay">
            <button className="svg close-btn" onClick={closeModal}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
                    </path>
                </svg>
            </button>

            <div className="modal-content">
                <div className="w-100">
                    <div className="flex flex-v-center space-between m-b-20">
                        <div className="flex flex-v-center">
                            <img src="/Vector.svg" alt="staff request" />
                            <div className="m-l-10 searchtext bold-text">Exit Review</div>
                        </div>
                        <div className="flex space-between ">
                            {/* <div className="m-r-10 col-5 no-p-r no-margin">
                                <Dropdown
                                placeholder={'Select MDA'}
                                />
                                </div>
                            <button className="edit-btn  m-r-10">Return to Edit Mode</button> */}
                            <button
                                onClick={openDownloadModal}
                                disabled={staffId ? false : true}
                                className="download-btn"
                            >
                                To Download Page
                            </button>
                        </div>
                    </div>

                    <div className="seperator"></div>
                    <div className="w-100 bg-white">
                        <div className="col-6 m-t-40">
                            <div className="flex row space-between m-l-20">
                                <div className="col-5 no-padding">
                                    <label> Employee’s RIN</label>
                                    <InputField
                                        placeholder='Input employee’s RIN'
                                        value={recordToBeReviewed?.employeeRIN || ''}
                                        disabled='disabled'
                                    />
                                </div>
                                <div className="col-7  no-p-r">
                                    <label>MDA</label>
                                    <InputField
                                        placeholder='Ministry of Housing and Urban Development'
                                        value={recordToBeReviewed?.ministry || ''}
                                        disabled='disabled'
                                    />
                                </div>
                            </div>
                            <div className="m-b-20 m-l-20">
                                <label>Staff full name</label>
                                <InputField
                                    placeholder='Staff full name'
                                    value={recordToBeReviewed?.staffFullName || ''}
                                    disabled='disabled'
                                />
                            </div>
                            <div className="m-b-20 m-l-20">
                                <label>Through</label>
                                <Select
                                    label='Through'
                                    color='rgba(120,120,120,0.8)'
                                    className='m-b-20 w-w00'
                                    onChange={(value) => {
                                        console.log(value);
                                    }}
                                    isMulti={true}
                                    value={throughName?.map(name => ({ label: name, value: name }))}
                                    isDisabled
                                />

                            </div>
                            <div className="m-b-20 m-l-20">
                                <label>To</label>
                                <Select
                                    label='To'
                                    color='rgba(120,120,120,0.8)'
                                    className='m-b-20 w-w00'
                                    onChange={(value) => {
                                        console.log(value);
                                    }}
                                    isMulti={true}
                                    value={toName?.map(name => ({ label: name, value: name }))}
                                    isDisabled
                                />

                            </div>
                        </div>
                        {/* <div className="p-l-30 flex flex-v-center employeeDetail-banner m-b-40">
                            <img src="/+ icon.png" alt="icon" />
                            <p className="m-l-10">
                                Show Employee Details
                            </p>
                            
                        </div> */}
                        <ExitEmployeeDetails
                            userId={userId}
                            type={type}
                            recordToBeReviewed={recordToBeReviewed}
                            userDependants={userDependants}
                            userEducation={userEducation}
                            userProfessional={userProfessional}
                            userNextOfKins={userNextOfKins}
                            userProfile={recordToBeDownloaded}
                            currentCadre={currentCadre}
                            firstAppCadre={firstAppCadre}

                        />
                        {/* <div className="col-8 m-b-20 m-l-20">
                            <TextAreaInputField
                                value ={recordToBeReviewed?.comment}
                                disabled = 'disabled'
                            />
                        </div> */}
                        <div className="m-l-30">
                            <label className="m-b-20">Uploaded Documents: </label>
                            {recordToBeReviewed?.exitRequestDocuments?.map((item) => {
                                // Split the file path based on the file separator ("/" or "\")
                                const pathParts = item?.filePath.split(/[\/\\_]/);

                                // Extract the last part of the path, which is the filename
                                const filename = pathParts[pathParts.length - 1];

                                return (
                                    <div className="m-t-10 flex" key={item?.id}>
                                        <a href={item?.filePath} target="_blank" className="m-r-10 uploads">
                                            {filename}
                                        </a>
                                    </div>
                                );
                            })}

                            {recordToBeReviewed?.updateRequestDocuments?.map((item) => {
                                // Split the file path based on the file separator ("/" or "\")
                                const pathParts = item?.filePath.split(/[\/\\_]/);

                                // Extract the last part of the path, which is the filename
                                const filename = pathParts[pathParts.length - 1];

                                return (
                                    <div className="m-t-10 flex" key={item?.id}>
                                        <a href={item?.filePath} target="_blank" className="m-r-10 uploads">
                                            {filename}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>

                        <div>
                            <ApproversJourney
                                recordToBeReviewed={recordToBeReviewed}
                                type={'Exit'}

                            />
                        </div>

                        {currentUser !== recordToBeReviewed?.employeeUserId && (
                            <div>
                                {/* <div>
                                    <AssignMemo
                                        assignReview={assignReview}
                                        setAssignerComment={setAssignerComment}
                                        setAssignee={setAssignee}
                                    />
                                </div> */}

                                <div className="m-b-40">
                                    <TakeAction
                                        setAction={setAction}
                                        setReviewerComment={setReviewerComment}
                                        review={review}
                                        status={recordToBeReviewed?.approvalStatus}
                                    />
                                </div>
                            </div>
                        )}



                    </div>
                </div>
            </div>
            {isDownload && (
                <DownloadExitRecord
                    userId={userId}
                    recordOnReview={recordToBeReviewed}
                    recordToBeReviewed={recordToBeDownloaded}
                    closeModal={closeDownloaModal}
                    userDependants={userDependants}
                    userEducation={userEducation}
                    userProfessional={userProfessional}
                    userNextOfKins={userNextOfKins}
                    wrongStaffId={wrongStaffId}
                    currentCadre={currentCadre}
                    firstAppCadre={firstAppCadre}

                />
            )}
        </div >
    )
}

export default ReviewModal;
