import { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get, post } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import TextAreaInputField from "../inputs/TextAreaInputField";
import Cookies from "js-cookie";
import InitiateOnboarding from "../layouts/InitiateOnboarding";

const BioEdit = (props) => {
    const userId = Cookies.get("userId");
    const [selectedDate, setSelectedDate] = useState(null);
    const [states, setStates] = useState([]);
    const [ID, setID] = useState([])
    const [toApprovers, setToApprovers] = useState([]);
    const [throughAppovers, setThroughApprovers] = useState([])
    const [rin, setRin] = useState(null);
    const [formData, setFormData] = useState();

    useEffect(() => {
        fetchStates()
        setRin(props?.rin)
        if (props?.data && !formData?.lastName) {
            setFormData({
                lastName: props?.data?.lastName || "",
                firstName: props?.data?.firstName || "",
                middleName: props?.data?.middleName || "",
                title: props?.data?.title || "",
                gender: props?.data?.gender || "",
                dateOfBirth: props?.data?.dateOfBirth || props?.data?.dob || "",
                stateId: props?.data?.state?.id || props?.data?.stateId || 0,
                placeOfBirth: props?.data?.placeOfBirth || "",
                maritalStatus: props?.data?.maritalStatus || "",
                mothersMaidenName: props?.data?.motherMaidenName || props?.data?.mothersMaidenName || "",
                employeeRIN: props?.data?.rin || props?.data?.employeeRIN || props?.rin || "",
                userId: Number(userId),
                onboardToApprovers: toApprovers,
                onboardThroughApprovers: throughAppovers,
            });
        }
        if (!formData?.lastName) {
            setFormData({
                lastName: props?.payload?.lastName || props?.data?.lastName || "",
                firstName: props?.payload?.firstName || props?.data?.firstName || "",
                middleName: props?.payload?.middleName || props?.data?.middleName || "",
                title: props?.payload?.title || props?.data?.title || "",
                gender: props?.payload?.gender || props?.data?.gender || "",
                dateOfBirth: props?.payload?.dateOfBirth || props?.data?.dateOfBirth || props?.data?.dob || "",
                stateId: props?.payload?.stateId || props?.data?.state?.id || props?.data?.stateId || 0,
                placeOfBirth: props?.payload?.placeOfBirth || props?.data?.placeOfBirth || "",
                maritalStatus: props?.payload?.maritalStatus || props?.data?.maritalStatus || "",
                mothersMaidenName: props?.payload?.mothersMaidenName || props?.data?.motherMaidenName || props?.data?.mothersMaidenName || "",
                employeeRIN: props?.payload?.employeeRIN || props?.data?.rin || props?.data?.employeeRIN || props?.rin || "",
                userId: Number(userId),
                onboardToApprovers: toApprovers,
                onboardThroughApprovers: throughAppovers,
            });
        }


        window.scrollTo(0, 0);
        document.title = 'EDSG - Test';
    }, [props?.data]);

    useEffect(() => {
        props?.handleFormData(formData);

    }, [formData])


    console.log(formData, props?.payload)

    const postData = async (data) => {

        try {
            const response = await post('bioEdit/create-employee', data);
            console.log(response)
            const id = response?.id
            props.handleFormData(data);
            if (response?.code === 1) {
                props.onIDUpdate(response?.data);
                props.handleNext();
                feedback({
                    title: "Success",
                    text: response?.message,
                    iconType: "success",
                });
            }
            console.log(id)
            console.log('response:', response)
        } catch (response) {
            feedback({
                title: "Failed",
                text: response?.message,
                iconType: "error",
            });
            console.error('failed to update perfonal info', response)
        }
    }

    const fetchStates = async () => {
        await get('state')
            .then(response => {
                setStates(response?.data);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }

    console.log('states', states)
    const handleDateChange = (date) => {
        const formattedDateWithoutTimestamp = date
            ? new Date(date).toLocaleDateString()
            : "";

        setFormData((prevData) => ({
            ...prevData,
            dateOfBirth: formattedDateWithoutTimestamp,
        }));



        setSelectedDate(date);
        console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);

    };

    const handleInputChange = (field, value) => {
        const selectedValue = value && value.target ? value.target.value : value;
        if (field === "employeeRIN") {
            // Validate if the value is a number and has a length of 11
            if (!isNaN(selectedValue) && selectedValue.length <= 11) {
                setFormData((prevData) => ({
                    ...prevData,
                    [field]: selectedValue,
                }));
                props?.handleFormData({ [field]: selectedValue });
                setRin(selectedValue);
                console.log(field, ': ', selectedValue)
            } else {
                // Display an error or handle the invalid input accordingly
                console.error("Invalid input for rin");
                feedback({
                    title: "Failed",
                    text: `Invalid input for ${field}`,
                    iconType: "error",
                });
            }
        } else {
            // For other fields, proceed without validation
            setFormData((prevData) => ({
                ...prevData,
                [field]: selectedValue === "" ? "" : selectedValue,
            }));
        }
    };

    console.log(formData)


    const isFormValid = () => {
        // Check if all required fields are filled
        const isValid = Object.entries(formData).every(([fieldName, value]) => {
            console.log(`Field: ${fieldName}, Value: ${value}, Type: ${typeof value}`);

            if (fieldName === "middleName" || fieldName === "mothersMaidenName") {
                return true;
            }

            if (typeof value === 'string') {
                // If the value is a string, check if it has at least one non-whitespace character
                return value.trim() !== '';
            } else {
                // For non-string values, consider the value valid if it exists
                return value !== undefined && value !== null;
            }
        });

        console.log("Form validity:", isValid);

        return isValid;
    };

    const titleOptions = [
        { value: "ALH", label: "ALHAJI" },
        { value: "ALJ", label: "ALHAJA" },
        { value: "ARCH", label: "ARCHITECT" },
        { value: "BARO", label: "BARONESS" },
        { value: "BARR", label: "BARRISTER" },
        { value: "CHIEF", label: "CHIEF" },
        { value: "COL", label: "COLONEL" },
        { value: "COMDR", label: "COMMANDER" },
        { value: "COMM", label: "COMMRADE" },
        { value: "CPTN", label: "CAPTAIN" },
        { value: "DEAC", label: "DEACON" },
        { value: "DR", label: "DOCTOR" },
        { value: "ELDER", label: "ELDER" },
        { value: "ENGR", label: "ENGINEER" },
        { value: "EVAN", label: "EVANGELIST" },
        { value: "EXC", label: "EXCELLENCY" },
        { value: "HIGH", label: "HIGH CHIEF" },
        { value: "HON", label: "HONOURABLE/HONBL" },
        { value: "HRH", label: "HIS ROYAL HIGHNESS" },
        { value: "JUST", label: "JUSTICE" },
        { value: "LADY", label: "LADY" },
        { value: "LCOM", label: "LT.COMMANDER" },
        { value: "LORD", label: "LORD" },
        { value: "MADAM", label: "MADAM" },
        { value: "MAJOR", label: "MAJOR" },
        { value: "MAST", label: "MASTER" },
        { value: "MAZI", label: "MAZI" },
        { value: "MES", label: "MESSERS" },
        { value: "MISS", label: "MISS" },
        { value: "MIST", label: "MISTER" },
        { value: "MR", label: "MR" },
        { value: "MRS", label: "MRS." },
        { value: "MM", label: "MR & MRS" },
        { value: "MS", label: "MS" },
        { value: "OBA", label: "OBA" },
        { value: "OTUN", label: "OTUNBA" },
        { value: "PAST", label: "PASTOR" },
        { value: "PRINCE", label: "PRINCE" },
        { value: "PROF", label: "PROFESSOR" },
        { value: "REV", label: "REVEREND" },
        { value: "SEKH", label: "SHEIKH" },
        { value: "SENT", label: "SENATOR" },
        { value: "SIR", label: "SIR" },
    ];

    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
    ];

    const maritalStatus = [
        { value: 'Married', label: 'Married' },
        { value: 'Single', label: 'Single' },
        { value: 'Divorced', label: 'Divorced' },
        { value: 'Widow', label: 'Widow' },
        { value: 'Widower', label: 'Widower' },
        { value: 'Undisclosed', label: 'Undisclosed' },
    ]

    const submitBioEdit = (e) => {
        if (props?.review) {
            props?.handleNext()
            return
        }
        const payload = {
            ...formData,
            userId: throughAppovers[0]?.approverId,
            onboardToApprovers: toApprovers,
            onboardThroughApprovers: throughAppovers,
        }
        console.log(toApprovers, throughAppovers, payload)
        e.preventDefault();
        if (isFormValid()) {
            postData(payload);
            console.log("Form submitted:", payload);
            console.log('handleFormData:',)
        } else {
            feedback({
                title: "Form not complete",
                text: 'Please fill in all required fields before submitting',
                iconType: "warning",
            });
            // props.showNotification("Please fill in all required fields correctly before submitting.");
            console.log("Please fill in all required fields before submitting.");
        }
    };

    return (

        <div className="form-step" data-step="1">
            {!props?.review &&
                <InitiateOnboarding
                    setToApprovers={setToApprovers}
                    setThroughApprovers={setThroughApprovers}
                />

            }
            <div className="m-b-20">
                <label >Last Name</label>
                <InputField
                    placeholder='Last Name'
                    required
                    name='Lastname'
                    onChange={(value) => handleInputChange("lastName", value)}
                    value={formData?.lastName}
                    disabled={props?.disabled && props?.authStatus !== 'ps-hr'}
                />
            </div>
            <div className="m-b-20">
                <label >First Name</label>
                <InputField
                    placeholder='First Name'
                    required
                    name='Firstname'
                    onChange={(value) => handleInputChange("firstName", value)}
                    value={formData?.firstName}
                    disabled={props?.authStatus !== 'ps-hr'}
                />
            </div>
            <div className="m-b-20">
                <label >Middle Name(Optional)</label>
                <InputField
                    placeholder='MiddleName'
                    name='Middle Name'
                    onChange={(value) => handleInputChange("middleName", value)}
                    value={formData?.middleName}
                    disabled={props?.authStatus !== 'ps-hr'}

                />
            </div>
            <div className="m-b-20">
                <label >Rin</label>
                <InputField
                    placeholder='Rin'
                    name='Rin'
                    required
                    onChange={(value) => handleInputChange("employeeRIN", value)}
                    value={rin || props?.data?.rin || ''}
                    disabled={props?.authStatus !== 'ps-hr'}

                />
            </div>
            <div className="m-b-20">
                <label >Title</label>
                <Dropdown
                    options={titleOptions}
                    placeholder='Select Title'
                    required
                    name='Title'
                    onChange={(value) => handleInputChange("title", value)}
                    selectedValue={formData?.title}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label >Gender</label>
                <Dropdown
                    options={genderOptions}
                    placeholder='Select Gender'
                    required
                    name='Gender'
                    onChange={(value) => handleInputChange("gender", value)}
                    selectedValue={formData?.gender}
                    disabled={props?.authStatus !== 'ps-hr'}

                />
            </div>
            <div className="m-b-20">
                <label >Date Of Birth</label>
                <DatePicker
                    className="input-field"
                    placeholderText="Date Of Birth"
                    selected={selectedDate}
                    required
                    isClearable
                    dateFormat="dd/MM/yyyy"
                    onChange={handleDateChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={200}
                    value={selectedDate || formData?.dateOfBirth}
                    disabled={props?.authStatus !== 'ps-hr'}

                />
            </div>
            <div className="m-b-20">
                <label > State Of Birth</label>
                <Dropdown
                    options={states}
                    placeholder='Select State Of Birth'
                    required
                    name='State Of Birth'
                    onChange={(value) => handleInputChange("stateId", Number(value))}
                    labelKey="name"
                    valueKey="id"
                    selectedValue={formData?.stateId}
                    disabled={props?.disabled}


                />
            </div>
            {/* <div>
                <div className="m-b-20">
                    <label >Place Of Birth</label>
                    <InputField
                        placeholder='Place Of Birth'
                        required
                        name='Place Of Birth'
                        onChange={(value) => handleInputChange("placeOfBirth", value)}
                        value={formData?.placeOfBirth}
                        disabled={props?.disabled}

                    />
                </div>
            </div> */}

            <div className="m-b-20">
                <label >Marital Status</label>
                <Dropdown
                    options={maritalStatus}
                    placeholder='Select Marital Status'
                    required
                    name='Marital Status'
                    onChange={(value) => handleInputChange("maritalStatus", value)}
                    selectedValue={formData?.maritalStatus}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label >Mother's Maiden Name(Optional)</label>
                <InputField
                    placeholder="Mother's Maiden Name"

                    name="Mother's Maiden Name"
                    onChange={(value) => handleInputChange("mothersMaidenName", value)}
                    value={formData?.mothersMaidenName}
                    disabled={props?.disabled}

                />
            </div>
            <div className="flex flex-right">
                <button className="col-5 submit-btn next-step" onClick={submitBioEdit}>Next</button>
            </div>
        </div>


    )
}

export default BioEdit;