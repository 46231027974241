const PayrollCorrection = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Promotion letter</p>
                    <p>2: Appointment letter</p>
                    <p>3: Inter-cadre letter</p>
                    </div>
                    <div className="col-7">
                    <p>4: Biometric</p>
                    <p>5: Bank statement and Salary payroll of the month of complaint</p>
                    </div>
                </div>
    )
}

export default PayrollCorrection;