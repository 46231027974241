import { useEffect, useState } from "react";
import ReviewerComment from "../modal/Reviewer'sComment";
import { feedback } from "../modal/feedback";
import { put } from "../../utility/fetch";
import Cookies from "js-cookie";

const OfficersComments = ({ recordToBeReviewed, toName, throughName, type }) => {
  // const [tableData, setTableData] = useState([
  //     { name: "Jay", createdOn:'August 23, 2020; 09.45am', approvedOn:'Awaiting', comment:'good show!'},
  //     { name: "Musa", createdOn:'August 23, 2020; 09.45am', approvedOn:'Awaiting', comment:'good show!'},
  //     { name: "Joy", createdOn:'August 23, 2020; 09.45am', approvedOn:'Awaiting', comment:''},
  //     { name: "Gustavo", createdOn:'August 23, 2020; 09.45am', approvedOn:'Awaiting', comment:''},

  // ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const [comment, setComment] = useState('');
  const [approver, setApprover] = useState()
  const [loading, setLoading] = useState(false);


  const closeModal = () => setIsModalOpen(false);

  const userId = Cookies.get('userId');
  const userName = Cookies.get('fullName');


  useEffect(() => {
    let newApprovers = []
    if (toName?.length > 0 || throughName?.length > 0) {
      newApprovers = [
        ...(throughName ?? []),
        ...(toName ?? []),
      ];
    } else {
      newApprovers = [
        ...(recordToBeReviewed?.exitRequestThroughApprovers ?? []),
        ...(recordToBeReviewed?.exitRequestToApprovers ?? [])
      ];
    }

    setApprovers(newApprovers);
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
  }, []);

  console.log(approvers, throughName, toName)



  const selectedRecord = (approver) => {
    console.log('Clicked on approver record:', approver, Number(userId));
    setApprover(approver);
    console.log('Selected approver from record:', approver);
    setIsModalOpen(true)
    // console.log('Modal State:', isModalOpen)
  }

  const respondToComent = async () => {
    if (comment === '' || comment === undefined || comment === null) {
      feedback({
        title: "No Comment",
        text: "Enter your comment",
        iconType: "warning",
      });
      return
    }
    if (comment.length < 5) {
      feedback({
        title: "comment too short",
        text: "comment should be at least 5 characters",
        iconType: "warning",
      });
      return
    }
    setLoading(true);
    const payload = {
      comment: comment,
      exitId: recordToBeReviewed?.id,
      approverRespondingName: userName,
      approverRespondingUserId: approver?.userId,
      approverRespondingMDAId: approver?.mdaId,
      approverRespondingId: Number(userId)
    }
    await put(`ExitRequest/respond-to-comment/exit/${recordToBeReviewed?.id}/approver/${approver?.userId}`, payload)
      .then(response => {
        console.log(response)
        feedback({
          title: "Success",
          text: "Comment sent successfully",
          iconType: "success",
        });
        closeModal()
        setComment('')

        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        feedback({
          title: "Failed",
          text: `Comment not sent`,
          iconType: "error",
        });
        setLoading(false)
      })
  }

  const respondToComentUpdate = async () => {
    if (comment === '' || comment === undefined || comment === null) {
      feedback({
        title: "No Comment",
        text: "Enter your comment",
        iconType: "warning",
      });
      return
    }
    if (comment.length < 5) {
      feedback({
        title: "comment too short",
        text: "comment should be at least 5 characters",
        iconType: "warning",
      });
      return
    }
    setLoading(true);
    const payload = {
      comment: comment,
      updateId: recordToBeReviewed?.id,
      approverRespondingName: userName,
      approverRespondingUserId: approver?.userId,
      approverRespondingMDAId: approver?.mdaId,
      approverRespondingId: Number(userId)
    }
    await put(`update/respond-to-comment/update/${recordToBeReviewed?.id}/approver/${approver?.userId}`, payload)
      .then(response => {
        console.log(response)
        feedback({
          title: "Success",
          text: "Comment sent successfully",
          iconType: "success",
        });
        closeModal()
        setComment('')
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        feedback({
          title: "Failed",
          text: `Comment not sent`,
          iconType: "error",
        });
        setLoading(false)
      })
  }

  const respondToComentOnboarding = async () => {
    if (comment === '' || comment === undefined || comment === null) {
      feedback({
        title: "No Comment",
        text: "Enter your comment",
        iconType: "warning",
      });
      return
    }
    if (comment.length < 5) {
      feedback({
        title: "comment too short",
        text: "comment should be at least 5 characters",
        iconType: "warning",
      });
      return
    }
    setLoading(true);
    setComment('')
    closeModal()

    const payload = {
      comment: comment,
      updateId: recordToBeReviewed?.id,
      approverRespondingName: userName,
      approverRespondingUserId: approver?.userId,
      approverRespondingMDAId: approver?.mdaId,
      approverRespondingId: Number(userId)
    }
    await put(`onboarding/respond-to-comment/onboarding/${recordToBeReviewed?.id}/approver/${approver?.userId}`, payload)
      .then(response => {
        console.log(response)
        feedback({
          title: "Success",
          text: "Comment sent successfully",
          iconType: "success",
        });
        closeModal()
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        feedback({
          title: "Failed",
          text: `Comment not sent`,
          iconType: "error",
        });
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <div className="processing">
        <p className="processing-text center-text">Processing Request...</p>
      </div>
    );
  }

  const formatDate = (timestamp) => {
    if (timestamp === null || timestamp === undefined) {
      return
    }
    const dateObject = new Date(timestamp);
    const formattedDate = dateObject.toISOString().split('T')[0];
    return formattedDate;
  };

  console.log(approvers)

  return (
    <div className="w-100">
      <table className="reviewer-table">
        <thead>
          <tr>
            <th className="center-text">Assigned Officers</th>
            <th className="center-text">In-Tray</th>
            <th className="center-text">Out-Tray</th>
            <th className="center-text">Action Taken</th>
            <th className="center-text">Comments</th>
          </tr>
        </thead>
        <tbody>
          {approvers.map((item, index) => (
            <tr key={index}>
              <td className="center-text">{item?.name || item?.approverName}</td>
              <td className="center-text">{formatDate(item?.recievedDate || item?.createdAt)}</td>
              <td className="center-text">{formatDate(item?.approvedOn || item?.modifiedAt)}</td>
              <td className="center-text">{item?.actionTaken}</td>
              <td>
                <p className="m-b-20 m-b-10 center-text"> {item?.comment}</p>
                {/* {item?.comment !== null && item?.userId === Number(userId) &&
                  <button className="comment-btn " onClick={() => selectedRecord(item)}>Respond to Comment</button>
                } */}
              </td>


            </tr>
          ))}
        </tbody>
      </table>
      <ReviewerComment
        isOpen={isModalOpen}
        respondToComent={type === "Update" ? respondToComentUpdate : type === "Exit" ? respondToComent : respondToComentOnboarding}
        setComment={setComment}
        onClose={closeModal}
      />

    </div>
  );
}

export default OfficersComments;