import Select from "react-select";
import TextAreaInputField from "../inputs/TextAreaInputField";
import ApproversJourney from "../page modifiers/ApproversJourney";
import TakeAction from "../page modifiers/TakeAction";
import InputField from "../inputs/InputField";
import React, { useState, useEffect } from "react";
import { get, post, put } from "../../utility/fetch";
import { feedback } from "./feedback";
import ExitEmployeeDetails from "../page modifiers/ExitEmployeeDetails";
import AssignMemo from "../page modifiers/AssignMemo";
import Cookies from "js-cookie";
import { PDFExport } from "@progress/kendo-react-pdf";

import DownloadExitRecord from "../ReviewRecordsDetails/DownloadExitRecord";

const OnboardingReview = ({ recordToBeReviewed, closeModal, userID, requestsOnQueue, type, disabled }) => {
    const [throughName, setThroughName] = useState([])
    const [toName, setToName] = useState([])
    const [reviewerComment, setReviewerComment] = useState('')
    const [action, setAction] = useState('')
    const [loading, setLoading] = useState(false);
    const [assignerComment, setAssignerComment] = useState('')
    const [isDownload, setIsDownload] = useState(false);
    const [staffId, setStaffId] = useState(Number)
    const [user, setUser] = useState({})
    const [userRin, setUserRin] = useState(Number)
    const [recordToBeDownloaded, setrecordToBeDownloaded] = useState({})
    const [searchValue, setSearchValue] = useState('')
    const [recipientMda, setrecipientMda] = useState([])
    const [approvers, setApprovers] = useState([])
    const [thoroughMda, setThroughMda] = useState([])
    const [through, setThrough] = useState([])
    const [mFiles, setMFiles] = useState([])
    const [comment, setComment] = useState('')
    const [documentArray, setDocumentArray] = useState([])
    const [receiveImage, setReceiveImage] = useState([])
    const [userProfile, setUserProfile] = useState({})


    const [userDependants, setUserDependants] = useState([]);
    const [userEducation, setUserEducation] = useState([]);
    const [userProfessional, setUserProfessional] = useState([]);
    const [userNextOfKins, setUserNextOfKins] = useState([]);
    const [wrongStaffId, setWrongStaffId] = useState(false);




    const [assignee, setAssignee] = useState({
        assigneeName: "",
        assigneeUserId: 0,
        assigneeMDAId: 0
    })

    const openDownloadModal = () => { setIsDownload(true) }
    const closeDownloaModal = () => {
        console.log("closeModal")
        setIsDownload(false)
        console.log("closeModal", isDownload)
    };


    const fetchProfile = async () => {
        console.log(recordToBeReviewed?.id)
        await get(`bio/${recordToBeReviewed?.id || userID}`)
            .then(response => {
                setrecordToBeDownloaded(response?.data)
                setUserProfile(response?.data)

                console.log("User recordToBeReviewed::", response);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }


    const fetchOnbaordee = async () => {

        await get(`bio/getrecord?userid=${recordToBeReviewed?.id || userID}`)
            .then(response => {
                setUser(response?.data)
                setStaffId(response?.data?.staffID)
                // setExitPages(response?.totalPages)
                console.log("User to be  onboarded::", response?.data,);
            })
            .catch(error => {

                console.error('Error fetching exits', error)
            })
    }

    const getApproverRecord = async () => {

        await get(`bio/getapproversrecord?userid=${recordToBeReviewed?.id || userID}`)
            .then(response => {
                setApprovers(response?.data)

                console.log("Approvers ON record::", response?.data,);
            })
            .catch(error => {

                console.error('Error fetching exits', error)
            })
    }

    useEffect(async () => {
        window.scrollTo(0, 0);
        document.title = "EDSG Profile";

        await fetchOnbaordee();
        await getApproverRecord()
        await fetchProfile()

        console.log(staffId)


        if (staffId || userID) {
            const dependants = await get(`dependants/${recordToBeReviewed?.id || userID}`);

            const education = await get(`education/${recordToBeReviewed?.id || userID}`);

            const professional = await get(`profcerts/${recordToBeReviewed?.id || userID}`);
            const nextOfKin = await get(`nok/${recordToBeReviewed?.id || userID}`);

            console.log(dependants, education, professional, nextOfKin)

            setUserDependants(dependants && dependants);
            setUserEducation(education && education);
            setUserProfessional(professional && professional);
            setUserNextOfKins(nextOfKin && nextOfKin);
        }
    }, [staffId, userID]);




    useEffect(() => {
        setThroughName(approvers?.filter(approver => approver.level === 'OThrough'))
        setToName(approvers?.filter(approver => approver.level === 'OTo'))
        setUserRin(recordToBeReviewed?.employeeRIN)


        window.scrollTo(0, 0);
        document.title = 'EDSG - Dashboard';
    }, [approvers]);


    const approverName = Cookies.get("fullName")
    const currentUser = Number(Cookies.get("userId"))


    // const user={name: "David Obiageli"}
    // if (!isOpen) {
    //     return null; // Don't render anything if the modal is closed.
    // }


    const review = async () => {
        let payload
        setLoading(true)

        payload = {
            userId: recordToBeReviewed?.id,
            approverComment: reviewerComment,
        }

        const response = await post(`Approval/review`, payload)
        console.log("Review::", response);
        if (response.code === 200) {
            feedback({
                title: "Success",
                text: "Successfully Reviewed",
                iconType: "success",
            });
            setLoading(false)
            requestsOnQueue()
            closeModal()

        } else {
            console.error('Error fetching states')
            feedback({
                title: "Failed",
                text: "Could Not Handle Request",
                iconType: "error",
            });
            setLoading(false)
        }

    }



    const approve = async () => {
        let payload
        setLoading(true)

        payload = {
            userId: recordToBeReviewed?.id,
            approverComment: reviewerComment,
        }

        const response = await post(`Approval/approve`, payload)
        console.log("Review::", response);
        if (response?.code === 200) {
            feedback({
                title: "Success",
                text: "Successfully Reviewed",
                iconType: "success",
            });
            setLoading(false)
            closeModal()
            requestsOnQueue()
        } else if (response?.code === 401) {
            setLoading(false)
            feedback({
                title: "Not Authorized",
                text: "not authorized to action this record",
                iconType: "error",
            });

        } else {
            feedback({
                title: "Failed",
                text: "Could Not Handle Request",
                iconType: "error",
            });
            setLoading(false)
        }



    }



    if (loading) {
        return (
            <div className="processing">
                <p className="processing-text center-text">Processing Request...</p>
            </div>
        );
    }

    console.log('Clicked on record:', approverName, assignee, assignerComment);

    recordToBeReviewed?.exitRequestDocuments?.map((item, index) => (
        console.log('Clicked on record:', item.id)

    ))


    return (
        <div className="overlay">
            <button className="svg close-btn" onClick={closeModal}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
                    </path>
                </svg>
            </button>

            <div className="modal-content">
                <div className="w-100">
                    <div className="flex flex-v-center space-between m-b-20">
                        <div className="flex flex-v-center">
                            <img src="/Vector.svg" alt="staff request" />
                            <div className="m-l-10 searchtext bold-text">Onboarding Review</div>
                        </div>
                        <div className="flex space-between ">
                            {/* <div className="m-r-10 col-5 no-p-r no-margin">
                                <Dropdown
                                placeholder={'Select MDA'}
                                />
                                </div>
                            <button className="edit-btn  m-r-10">Return to Edit Mode</button> */}
                            <button
                                onClick={openDownloadModal}
                                disabled={staffId ? false : true}
                                className="download-btn"
                            >
                                To Download Page
                            </button>
                        </div>
                    </div>

                    <div className="seperator"></div>
                    <div className="w-100 bg-white">
                        <div className="col-6 m-t-40">
                            <div className="flex row space-between m-l-20">
                                <div className="col-5 no-padding">
                                    <label> Employee’s RIN</label>
                                    <InputField
                                        placeholder='Input employee’s RIN'
                                        value={user?.rin || ''}
                                        disabled='disabled'
                                    />
                                </div>
                                <div className="col-7  no-p-r">
                                    <label>MDA</label>
                                    <InputField
                                        placeholder='MDA'
                                        value={user?.mda || ''}
                                        disabled='disabled'
                                    />
                                </div>
                            </div>
                            <div className="m-b-20 m-l-20">
                                <label>Staff full name</label>
                                <InputField
                                    placeholder='Staff full name'
                                    value={user?.fullName || ''}
                                    disabled='disabled'
                                />
                            </div>
                            <div className="m-b-20 m-l-20">
                                <label>Through</label>
                                <InputField
                                    placeholder='Through'
                                    value={throughName?.map(name => (name?.approverName))}
                                    disabled='disabled'
                                />

                            </div>
                            <div className="m-b-20 m-l-20">
                                <label>To</label>
                                <InputField
                                    placeholder='To'
                                    value={toName?.map(name => (name?.approverName))}
                                    disabled='disabled'
                                />

                            </div>
                        </div>

                        <ExitEmployeeDetails
                            type={type}
                            userId={recordToBeReviewed?.id}
                            recordToBeReviewed={recordToBeReviewed}
                            user={userProfile}
                            userDependants={userDependants}
                            userEducation={userEducation}
                            userProfessional={userProfessional}
                            userNextOfKins={userNextOfKins}
                        />
                        {/* <div className="col-8 m-b-20 m-l-20">
                            <TextAreaInputField
                                value ={recordToBeReviewed?.comment}
                                disabled = 'disabled'
                            />
                        </div> */}
                        {/* <div className="m-l-30">
                            <label className="m-b-20">Uploaded Documents: </label>
                            {recordToBeReviewed?.exitRequestDocuments?.map((item) => {
                                // Split the file path based on the file separator ("/" or "\")
                                const pathParts = item?.filePath.split(/[\/\\_]/);

                                // Extract the last part of the path, which is the filename
                                const filename = pathParts[pathParts.length - 1];

                                return (
                                    <div className="m-t-10 flex" key={item?.id}>
                                        <a href={item?.filePath} target="_blank" className="m-r-10 uploads">
                                            {filename}
                                        </a>
                                    </div>
                                );
                            })}

                            {recordToBeReviewed?.updateRequestDocuments?.map((item) => {
                                // Split the file path based on the file separator ("/" or "\")
                                const pathParts = item?.filePath.split(/[\/\\_]/);

                                // Extract the last part of the path, which is the filename
                                const filename = pathParts[pathParts.length - 1];

                                return (
                                    <div className="m-t-10 flex" key={item?.id}>
                                        <a href={item?.filePath} target="_blank" className="m-r-10 uploads">
                                            {filename}
                                        </a>
                                    </div>
                                );
                            })}
                        </div> */}

                        <div>
                            <ApproversJourney
                                throughName={throughName}
                                toName={toName}
                                recordToBeReviewed={recordToBeReviewed}

                            />
                        </div>
                        {(currentUser !== recordToBeReviewed?.id && !disabled) && (
                            <div>
                                <div className="m-b-40">
                                    <TakeAction
                                        setAction={setAction}
                                        setReviewerComment={setReviewerComment}
                                        review={review}
                                        approve={approve}
                                        status={recordToBeReviewed?.approvalStatus}
                                        type={type}
                                    />
                                </div>
                            </div>
                        )}



                    </div>
                </div>
            </div>
            {isDownload && (
                <DownloadExitRecord
                    userId={recordToBeReviewed?.id}
                    recordOnReview={recordToBeReviewed}
                    recordToBeReviewed={recordToBeDownloaded}
                    closeModal={closeDownloaModal}
                    userDependants={userDependants}
                    userEducation={userEducation}
                    userProfessional={userProfessional}
                    userNextOfKins={userNextOfKins}
                    wrongStaffId={wrongStaffId}

                />
            )}
        </div >
    )
}

export default OnboardingReview;
