import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../components/pages/Dashboard';
import InitiateRequest from '../components/pages/InitiateRequest';
import ReviewRequest from '../components/pages/ReviewRequest';
import Cookies from "js-cookie";
import OnboardingForm from '../components/forms/OnboardingForm';
import DataUpdate from '../components/layouts/DataUpdate';
import ExitRequest from '../components/layouts/Exit';
import MyRecords from '../components/pages/MyRecords';
import AuthorisedUsers from '../components/pages/AuthorisedUsers';
import Reports from '../components/pages/Reports';


export default pure(() => (
 
  <Switch>
    
    <ProtectedRoute
      path="/admin-portal/"
      exact
      component={Dashboard}
    />
    <ProtectedRoute
      path="/admin-portal/data-update"
      exact
      component={DataUpdate}
    />
    <ProtectedRoute
      path="/admin-portal/authorise-user"
      exact
      component={AuthorisedUsers}
    />
     <ProtectedRoute
      path="/admin-portal/reports"
      exact
      component={Reports}
    />
      <ProtectedRoute
      path="/admin-portal/initiate-request"
      exact
      component={InitiateRequest}
    />
     <ProtectedRoute
      path="/admin-portal/staff-exit"
      exact
      component={ExitRequest}
    />
    <ProtectedRoute
      path="/admin-portal/review-request"
      exact
      component={ReviewRequest}
    />
     <ProtectedRoute
      path="/admin-portal/my-records"
      exact
      component={MyRecords}
    />
    <ProtectedRoute
      path="/admin-portal/logout"
      exact
      component={() => {
        const home = Cookies.get("homeurl")
        console.log('home', home)
        Cookies.remove("userId");
        Cookies.remove("_Role");
        Cookies.remove("_Email");
        Cookies.remove("profile");
        Cookies.remove("_FullName");
        Cookies.remove("firstName");
        Cookies.remove("lastName");
        Cookies.remove("fullName");
        Cookies.remove("profilePicture");
        Cookies.remove("isAdmin");
        Cookies.remove("loginTime");
        // Cookies.remove("Edogov_Appraisal_AppToken");
        Cookies.remove("_APIBaseURL");
        Cookies.remove("tokenExist");
        // Cookies.remove("Edogov_Appraisal_AccessToken");
        localStorage.clear();
        window.location.assign(home);
      }}
      />
       <ProtectedRoute
      path="/admin-portal/home"
      exact
      
      component={() => {
        const home = Cookies.get("homeurl")
        console.log('home', home)
        Cookies.remove("userId");
        Cookies.remove("_Role");
        Cookies.remove("_Email");
        Cookies.remove("profile");
        Cookies.remove("_FullName");
        Cookies.remove("firstName");
        Cookies.remove("lastName");
        Cookies.remove("fullName");
        Cookies.remove("profilePicture");
        Cookies.remove("isAdmin");
        Cookies.remove("loginTime");

        Cookies.remove("_APIBaseURL");
        Cookies.remove("tokenExist");

        localStorage.clear();
        window.location.assign(home);
      }}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
