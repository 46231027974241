import { useState, useEffect, useContext } from "react";

import { del, get, post } from "../../utility/fetch";
import Cookies from "js-cookie";
import { feedback } from "../modal/feedback";
import MyContext from "../../utility/Context";
import Dropdown from "../inputs/Dropdown";
import { get as gets } from "../../utility/services";
import ApproverDropdown from "../inputs/ApproverDropdown";
import SelectInput from "../inputs/SelectInput";
import InputField from "../inputs/InputField";
import CreateAuthorisedUser from "../page modifiers/CreateAuthourisedUser";
import CreateRole from "../page modifiers/CreateRole";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";



const AuthorisedUsers = ({ history }) => {

    const { setExitToBeViewed, setUserRin, setMyRecord, setSentBack, setUpdateToBeViewed } = useContext(MyContext);
    const authStatus = Cookies.get('authStatus');

    const userId = Cookies.get('userId')
    const [loading, setLoading] = useState(false)
    const [approvers, setApprovers] = useState([])

    const [roles, setRoles] = useState([])
    const [roleList, setRoleList] = useState([])
    const [urgency, setUrgency] = useState("");
    const [mdas, setMdas] = useState([]);
    const [users, setUsers] = useState({})
    const [roleName, setRoleName] = useState('')

    const [approvalMDA, setapprovalMDA] = useState([])
    const [employeeName, setemployeeName] = useState([])



    const [thoroughMda, setThroughMda] = useState([])
    const [throughName, setThroughName] = useState([])

    useEffect(() => {
        setMyRecord(false)
        setUpdateToBeViewed({})

        window.scrollTo(0, 0);
        document.title = "EDSG Profile";
        const fetchData = async () => {
            fetchRoles()
            fetchUsers()
        };
        fetchData()
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await get(`onboardingusers`)
            if (response?.code === 1) {
                setUsers(response.data)
            }
        } catch (error) {

        }
    }

    const fetchRoles = async () => {
        try {
            const response = await get(`onboardingroles`)
            if (response?.code === 1) {
                setRoleList(response.data)
            }
        } catch (error) {

        }
    }


    const handleChange = (field, value) => {
        const selectedValue = value && value.target ? value.target.value : value;
        if (field === 'role') {
            setRoles(value);
        } else {
            setRoleName(selectedValue)
        }
    }

    const createRole = async () => {
        setLoading(true)

        await post(`onboardingroles/${roleName}`)
            .then((response) => {
                feedback({
                    title: "Success",
                    text: "Role created successfully",
                    iconType: "success",
                });
                fetchRoles()
                setRoleName(null)
                setLoading(false);
            })
            .catch((error) => {
                feedback({
                    title: "Failed",
                    text: "Failed to create role",
                    iconType: "error",
                });
                setLoading(false);
                console.error('Error fetching states', error);
            });
    }

    const authoriseUser = async () => {
        // if (users?.some((item)=> item?.role?.toLowerCase() === 'hos' || item?.role?.toLowerCase() === 'governor')) {
        //     feedback({
        //         title: "Not allowed",
        //         text: "Cannot authorize user",
        //         iconType: "error",
        //     });
        //     return
        // }
        setLoading(true)


        await post(`onboardingusers/${employeeName?.value}?roleId=${roles?.value}`)
            .then((response) => {
                setMyRecord(false)
                feedback({
                    title: "Success",
                    text: "User Authorized",
                    iconType: "success",
                });
                fetchUsers()
                setapprovalMDA([])
                setemployeeName([])

                setLoading(false);

            })
            .catch((error) => {
                setMyRecord(false)
                feedback({
                    title: "Failed",
                    text: "Failed to authorize user",
                    iconType: "error",
                });
                setLoading(false);
                console.error('Error fetching states', error);
            });
    }

    const deleteUser = async (id) => {
        setLoading(true)

        await del(`onboardingusers/${id}`)
            .then((response) => {
                setMyRecord(false)
                feedback({
                    title: "Success",
                    text: "User De-authorized",
                    iconType: "success",
                });

                fetchUsers()
                setLoading(false);
            })
            .catch((error) => {
                setMyRecord(false)
                feedback({
                    title: "Failed",
                    text: "Failed to De-authorize user",
                    iconType: "error",
                });
                setLoading(false);
                console.error('Error fetching states', error);
            });
    }

    const sortById = (array) => {
        return array.sort((a, b) => a.id - b.id);
    };








    console.log(employeeName, roles, roleList)

    if (loading) {
        return (
            <div className="processing">
                <p className="processing-text">Processing...</p>
            </div>
        );
    }


    return (
        <div className="w-100">
            <h2 className="searchtext center-text bold-text m-b-5">User Permissions/Roles</h2>
            <div className="bottom-border m-b-40" />

            <div>
                <CreateAuthorisedUser
                    approvalMDA={approvalMDA}
                    setapprovalMDA={setapprovalMDA}
                    employeeName={employeeName}
                    setemployeeName={setemployeeName}
                    roleList={sortById(roleList)}
                    handleChange={handleChange}
                    authoriseUser={authoriseUser}
                />
            </div>
            <div>
                <CreateRole
                    handleChange={handleChange}
                    createRole={createRole}
                />
            </div>

            <div>
                <div className="collapse-banner m-t-60 m-b-10 flex flex-v-center ">Authorized Users</div>
                <div className="reviewer-card">
                    <table className="reviewer-table">
                        <thead>
                            <tr>
                                <th className="center-text">Name</th>
                                <th className="center-text">Role</th>
                                <th className="center-text">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(users) && users?.map((item, index) => (
                                <tr key={index}>
                                    <td className="center-text">{item?.user || item?.approverName}</td>
                                    <td className="center-text">{item?.role}</td>
                                    <td className="center-text">
                                        <div className="flex space-around  flex-v-center">
                                            {/* <div>
                                                <RiEdit2Line className="pointer" style={{ fontSize: '20px', color: 'green' }} onClick={() => { selectedRecord(item)}} />
                                            </div> */}
                                            <div>
                                                <RiDeleteBinLine style={{ fontSize: '20px', color: 'red' }} className="pointer" onClick={() => deleteUser(item?.userId)} />
                                            </div>
                                            {/* {item?.role.toLowerCase() !== 'hos' && item?.role.toLowerCase() !== 'governor' &&
                                                <div>
                                                    <RiDeleteBinLine style={{ fontSize: '20px', color: 'red' }} className="pointer" onClick={() => deleteUser(item?.userId)} />
                                                </div>
                                            } */}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AuthorisedUsers;