import React, { useContext, useEffect, useState } from 'react';
import Card from '../layouts/Card';
import SearchInput from '../inputs/SearchInput';
import Exit from '../layouts/DataUpdate'
import PdfSave from '../layouts/PdfSave'
import Cookies from "js-cookie";
import { del, get } from '../../utility/fetch';
import OnboardingTable from '../layouts/tables/OnboardingTable';
import UpdateTable from '../layouts/tables/UpdateTable';
import ExitTable from '../layouts/tables/ExitTable';
import MyContext from '../../utility/Context';
import { feedback } from '../modal/feedback';




const Dashboard = ({ history }) => {
  const [onboardingStatusCard, setOnboardingStatusCard] = useState("Approved");
  const [updateStatusCard, setUpdateStatusCard] = useState("Approved");
  const [exitRecords, setExitRecords] = useState([]);
  const [updateRecords, setUpdateRecords] = useState([]);
  const [onboardRecords, setOnboardRecords] = useState([]);

  const [exitStatusCard, setExitStatusCard] = useState("Approved");
  const [exitApproved, setAprovedExit] = useState([]);
  const [pendingExit, setPendingExit] = useState([]);
  const [sentBackExit, setSentBackExit] = useState([]);
  const [SavedExit, setSavedExit] = useState();
  const [updateApproved, setAprovedUpdate] = useState([]);
  const [pendingUpdate, setPendingUpdate] = useState([]);
  const [sentBackUpdate, setSentBackUpdate] = useState([]);
  const [SavedUpdate, setSavedUpdate] = useState();
  const [onboardApproved, setAprovedOnboard] = useState([]);
  const [pendingOnboard, setPendingOnboard] = useState([]);
  const [sentBackOnboard, setSentBackOnboard] = useState([]);
  const [SavedOnboard, setSavedOnboard] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [searchInput, setSearchInput] = useState();
  const [title, setTitle] = useState('');
  const [role, setRole] = useState('')
  const [openTab, setOpenTab] = useState(0);
  const [exitApprovedPages, setExitApprovedPages] = useState(1)
  const [exitPendingdPages, setExitPendingPages] = useState(1)
  const [exitSentbackPages, setExitSentbackPages] = useState(1)
  const [updateApprovedPages, setUpdateApprovedPages] = useState(1)
  const [updatePendingdPages, setUpdatePendingPages] = useState(1)
  const [updateSentbackPages, setUpdateSentbackPages] = useState(1)
  const [onboardApprovedPages, setOnboardApprovedPages] = useState(1)
  const [onboardPendingdPages, setOnboardPendingPages] = useState(1)
  const [onboardSentbackPages, setOnboardSentbackPages] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pageNumberSavedExit, setpageNumberSavedExit] = useState(1)
  const [pageSizeSavedExit, setpageSizeSavedExit] = useState(10)
  const [savedExitPages, setSavedExitPages] = useState(1)
  const [savedUpdatePages, setSavedUpdatePages] = useState(1)
  const [savedOnboardPages, setSavedOnboardPages] = useState(1)


  const { setExitToBeViewed, setUserRin, setMyRecord, setUpdateToBeViewed } = useContext(MyContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
    fetch()
    setRole(Cookies.get('_Role'))
    setTitle('Dashboard');
    setMyRecord(false)
    setUpdateToBeViewed({})


  }, []);

  const user = Cookies.get('fullName')
  const userId = Cookies.get('userId')

  const fetch = async () => {
    await fetchApprovedExits(pageNumber)
    await fetchSavedExits(pageNumber)
    await fetchSentBackExits(pageNumber)
    await fetchPendingExits(pageNumber)
    await fetchApprovedUpdate(pageNumber)
    await fetchSavedUpdate(pageNumber)
    await fetchSentBackUpdate(pageNumber)
    await fetchPendingUpdate(pageNumber)

    await fetchPendingOnboarding(pageNumber)
    await fetchSentBackOnboarding(pageNumber)
    await fetchSavedOnboarding(pageNumber)
    await fetchApprovedOnboarding(pageNumber)
  }


  const fetchApprovedExits = async (pageNumber) => {
    try {
      const response = await get(`ExitRequest/list/approved-status/${userId}/${pageNumber}/${pageSize}`);
      setAprovedExit(response);
      setExitApprovedPages(response?.totalPages);
      if (exitStatusCard === 'Approved') {
        setExitRecords(response?.resultList)
      }
    } catch (error) {
      console.error('Error fetching approved exit records', error);
    }
  }


  const fetchPendingExits = async (pageNumber) => {
    try {
      const response = await get(`ExitRequest/list/pending-status/${userId}/${pageNumber}/${pageSize}`);
      setPendingExit(response)
      setExitPendingPages(response?.totalPages)
      if (exitStatusCard === 'Pending') {
        setExitRecords(response?.resultList)
      } return response;
    } catch (error) {
      console.error('Error fetching pending exit records', error);
    }
  };


  const fetchSentBackExits = async (pageNumber) => {
    try {
      const response = await get(`ExitRequest/list/back-for-review-status/${userId}/${pageNumber}/${pageSize}`);
      setSentBackExit(response);
      setExitSentbackPages(response?.totalPages);
      if (exitStatusCard === 'Review') {
        setExitRecords(response?.resultList)
      }
    } catch (error) {
      console.error('Error fetching sent back exit records', error);
    }
  }

  const deleteExit = async (recordId) => {

    await del(`ExitRequest/${recordId}`)
      .then((response) => {
        console.log(response)
        if (response?.statusCode === 500 ) {
        
          feedback({
            title: "error",
            text: "Delete failed",
            iconType: "error",
          });
        }else if (response?.statusCode === 401 ) {
        
          feedback({
            title: "error",
            text: "Not authorised",
            iconType: "error",
          });
        }else if (response?.statusCode === 400 ) {
        
          feedback({
            title: "error",
            text: "Bad request",
            iconType: "error",
          });
        }else{
          setMyRecord(false)
          fetchSavedExits(pageNumber)
          feedback({
            title: "Success",
            text: "Successfully deleted",
            iconType: "success",
          });
        }

      })
      .catch((error) => {
        setMyRecord(false)

        console.error('Error fetching states', error);
      });
  }

  const fetchSavedExits = async (pageNumber) => {
    try {
      const response = await get(`ExitRequest/list/saved/${userId}/${pageNumber}/${pageSize}`);
      setSavedExit(response);
      setSavedExitPages(response?.totalPages);
      if (exitStatusCard === 'Saved') {
        setExitRecords(response?.resultList)
      }
      console.log(SavedExit);
    } catch (error) {
      console.error('Error fetching saved exit records', error);
    }
  }

  const deleteUpdate = async (recordId) => {

    await del(`update/${recordId}`)
      .then((response) => {
        setMyRecord(false)
        fetchSavedUpdate(pageNumber)
        feedback({
          title: "Success",
          text: "Successfully deleted",
          iconType: "success",
        });

      })
      .catch((error) => {
        setMyRecord(false)

        console.error('Error fetching states', error);
      });
  }


  const fetchApprovedUpdate = async (pageNumber) => {
    try {
      const response = await get(`update/list/${userId}/approved-status?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      setAprovedUpdate(response);
      setUpdateApprovedPages(response?.totalPages);
      if (updateStatusCard === 'Approved') {
        setUpdateRecords(response?.resultList);
      }
    } catch (error) {
      console.error('Error fetching approved exit records', error);
    }
  }


  const fetchPendingUpdate = async (pageNumber) => {
    try {
      const response = await get(`update/list/${userId}/pending-status?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      setPendingUpdate(response)
      setUpdatePendingPages(response?.totalPages)
      if (updateStatusCard === 'Pending') {
        setUpdateRecords(response?.resultList);
      }
      return response;
    } catch (error) {
      console.error('Error fetching pending exit records', error);
    }
  };


  const fetchSentBackUpdate = async (pageNumber) => {
    try {
      const response = await get(`update/list/${userId}/back-for-review-status?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      setSentBackUpdate(response);
      setUpdateSentbackPages(response?.totalPages);
      if (updateStatusCard === 'Review') {
        setUpdateRecords(response?.resultList);
      }
    } catch (error) {
      console.error('Error fetching sent back exit records', error);
    }
  }

  const fetchSavedUpdate = async (pageNumber) => {
    try {
      const response = await get(`update/list/saved/${userId}/${pageNumber}/${pageSize}`);
      setSavedUpdate(response);

      setSavedUpdatePages(response?.totalPages);
      if (updateStatusCard === 'Saved') {
        setUpdateRecords(response?.resultList);
      }
      console.log(SavedExit);
    } catch (error) {
      console.error('Error fetching saved exit records', error);
    }
  }

  const fetchApprovedOnboarding = async (pageNumber) => {
    try {
      const response = await get(`bio/approved/${userId}`);
      setAprovedOnboard(response?.data);
      setOnboardRecords(response?.data)
      setOnboardApprovedPages(response?.totalPages);

    } catch (error) {
      console.error('Error fetching approved exit records', error);
    }
  }


  const fetchPendingOnboarding = async (pageNumber) => {
    try {
      const response = await get(`bio/pending/${userId}`);
      setPendingOnboard(response?.data)
      setOnboardPendingPages(response?.totalPages)
      return response;
    } catch (error) {
      console.error('Error fetching pending exit records', error);
    }
  };


  const fetchSentBackOnboarding = async (pageNumber) => {
    try {
      const response = await get(`bio/reviewed/${userId}`);
      setSentBackOnboard(response?.data);
      setOnboardSentbackPages(response?.totalPages);
    } catch (error) {
      console.error('Error fetching sent back exit records', error);
    }
  }

  const fetchSavedOnboarding = async (pageNumber) => {
    try {
      const response = await get(`bio/saved/${userId}`);
      setSavedOnboard(response);
      setSavedOnboardPages(response?.totalPages);
      console.log(SavedOnboard);
    } catch (error) {
      console.error('Error fetching saved exit records', error);
    }
  }

  const deleteOnboarding = async (recordId) => {

    await del(`bio/Delete/${recordId}`)
      .then((response) => {
        setMyRecord(false)
        fetchSavedOnboarding(pageNumber)
        feedback({
          title: "Success",
          text: "Successfully deleted",
          iconType: "success",
        });

      })
      .catch((error) => {
        setMyRecord(false)

        console.error('Error fetching states', error);
      });
  }


  const handlePageNumberClick = (pageNumber) => {
    setPageNumber(pageNumber)
    console.log("openTab:", openTab);
    console.log("onboardingStatusCard:", onboardingStatusCard);
    console.log("updateStatusCard:", updateStatusCard);
    console.log("exitStatusCard:", exitStatusCard);

    if (openTab === 0 && onboardingStatusCard === 'Approved') {
      console.log("Fetching approved onboarding...");
      fetchApprovedOnboarding(pageNumber);
    } else if (openTab === 0 && onboardingStatusCard === 'Pending') {
      console.log("Fetching pending onboarding...");
      fetchPendingOnboarding(pageNumber);
    } else if (openTab === 0 && onboardingStatusCard === 'Review') {
      console.log("Fetching onboarding sent back for review...");
      fetchSentBackOnboarding(pageNumber);
    } else if (openTab === 0 && onboardingStatusCard === 'Saved') {
      console.log("Fetching saved onboarding...");
      fetchSavedOnboarding(pageNumber);
    } else if (openTab === 1 && updateStatusCard === 'Approved') {
      console.log("Fetching approved update...");
      fetchApprovedUpdate(pageNumber);
    } else if (openTab === 1 && updateStatusCard === 'Pending') {
      console.log("Fetching pending update...");
      fetchPendingUpdate(pageNumber);
    } else if (openTab === 1 && updateStatusCard === 'Review') {
      console.log("Fetching update sent back for review...");
      fetchSentBackUpdate(pageNumber);
    } else if (openTab === 1 && updateStatusCard === 'Saved') {
      console.log("Fetching saved update...");
      fetchSavedUpdate(pageNumber);
    } else if (openTab === 2 && exitStatusCard === 'Approved') {
      console.log("Fetching approved exits...");
      fetchApprovedExits(pageNumber);
    } else if (openTab === 2 && exitStatusCard === 'Pending') {
      console.log("Fetching pending exits...");
      fetchPendingExits(pageNumber);
    } else if (openTab === 2 && exitStatusCard === 'Review') {
      console.log("Fetching exits sent back for review...");
      fetchSentBackExits(pageNumber);
    } else if (openTab === 2 && exitStatusCard === 'Saved') {
      console.log("Fetching saved exits...");
      fetchSavedUpdate(pageNumber);
    }
  }

  const styles = {
    pagination: {
      display: 'flex',
      justifyContent: 'center',
    },
    pageNumber: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      padding: '10px',
      margin: '5px',
      cursor: 'pointer',
    },
    ellipses: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      padding: '10px',
      margin: '5px',
    },
    active: {
      backgroundColor: '#3C7E2D',
      color: '#fff',
    },
  };

  const maxDisplayedPages = 10; // Maximum number of page numbers to display
  const totalPageCount = totalPages; // Total number of pages

  let pageNumbers = [];


  if (totalPageCount <= maxDisplayedPages) {
    // If total page count is less than or equal to the maximum displayed pages,
    // display all page numbers from 1 to totalPageCount
    pageNumbers = Array.from({ length: totalPageCount }, (_, index) => index + 1);
  } else {
    // If total page count is greater than the maximum displayed pages,
    // display a limited set of page numbers with ellipsis
    const currentPageIndex = pageNumber - 1; // Index of current page number (0-based)

    const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);
    let startPageIndex = Math.max(0, currentPageIndex - halfDisplayedPages);
    let endPageIndex = Math.min(totalPageCount - 1, startPageIndex + maxDisplayedPages - 1);

    if (endPageIndex - startPageIndex < maxDisplayedPages - 1) {
      // Adjust startPageIndex if endPageIndex is at the end
      startPageIndex = Math.max(0, endPageIndex - maxDisplayedPages + 1);
    } else if (endPageIndex === totalPageCount - 1) {
      // Adjust endPageIndex if startPageIndex is at the start
      endPageIndex = Math.min(totalPageCount - 1, startPageIndex + maxDisplayedPages - 1);
    }

    // Generate page numbers with ellipsis
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      pageNumbers.push(i + 1);
    }
    if (startPageIndex > 0) {
      // Insert ellipsis at the beginning
      pageNumbers.unshift('...');
    }
    if (endPageIndex < totalPageCount - 1) {
      // Insert ellipsis at the end
      pageNumbers.push('...');
    }
  }



  console.log(exitStatusCard)

  return (
    <div className='w-100'>
      <div className='searchtext'>
        <p>Good Day</p>
        <div className='bold-text m-t-5 m-b-5'>  {user || "Osagie Osaigbovo"} </div>


        <div>{role || 'officer 1'}</div>
      </div>
      <br />
      {openTab === 0 && (
        <div className='col-12 flex space-between'>
          <Card
            status={"Approved"}
            onClick={() => { setOnboardingStatusCard("Approved"); setOnboardRecords(onboardApproved); setTotalPages(onboardApprovedPages); setPageNumber(1) }}
            active={onboardingStatusCard === "Approved"}
            count={onboardApproved?.length || 0}
          />
          <Card
            status={'Pending '}
            onClick={() => { setOnboardingStatusCard('Pending'); setOnboardRecords(pendingOnboard); setTotalPages(onboardPendingdPages); setPageNumber(1) }}
            active={onboardingStatusCard === "Pending"}
            count={pendingOnboard?.length || 0}
          />
          <Card
            status={"Review"}
            onClick={() => { setOnboardingStatusCard("Review"); setOnboardRecords(sentBackOnboard); setTotalPages(onboardSentbackPages); setPageNumber(1) }}
            active={onboardingStatusCard === "Review"}
            count={sentBackOnboard?.length || 0}
          />

          <Card
            status={"Saved"}
            onClick={() => { setOnboardingStatusCard("Saved"); setOnboardRecords(SavedOnboard?.data); setTotalPages(savedOnboardPages); setPageNumber(1) }}
            active={onboardingStatusCard === "Saved"}
            count={SavedOnboard?.data?.length || 0}
          />
        </div>
      )}

      {openTab === 1 && (
        <div className='col-12 flex space-between'>
          <Card
            status={"Approved"}
            onClick={() => { setUpdateStatusCard("Approved"); setUpdateRecords(updateApproved?.resultList); setTotalPages(updateApprovedPages); setPageNumber(1) }}
            active={updateStatusCard === "Approved"}
            count={updateApproved?.totalCount || 0}
          />
          <Card
            status={'Pending'}
            onClick={() => { setUpdateStatusCard('Pending'); setUpdateRecords(pendingUpdate?.resultList); setTotalPages(updatePendingdPages); setPageNumber(1) }}
            active={updateStatusCard === "Pending"}
            count={pendingUpdate?.totalCount || 0}
          />
          <Card
            status={"Review"}
            onClick={() => { setUpdateStatusCard("Review"); setUpdateRecords(sentBackUpdate?.resultList); setTotalPages(updateSentbackPages); setPageNumber(1) }}
            active={updateStatusCard === "Review"}
            count={sentBackUpdate?.totalCount || 0}
          />

          <Card
            status={"Saved"}
            onClick={() => { setUpdateStatusCard("Saved"); setUpdateRecords(SavedUpdate?.resultList); setTotalPages(savedUpdatePages); setPageNumber(1) }}
            active={updateStatusCard === "Saved"}
            count={SavedUpdate?.totalcount || 0}
          />
        </div>
      )}

      {openTab === 2 && (
        <div className='col-12 flex space-between'>

          <Card
            status={"Approved"}
            onClick={() => { setExitStatusCard("Approved"); setExitRecords(exitApproved?.resultList); setTotalPages(exitApprovedPages); setPageNumber(1) }}
            active={exitStatusCard === "Approved"}
            count={exitApproved?.totalCount || 0}
          />


          <Card
            status={'Pending'}
            onClick={() => { setExitStatusCard('Pending'); setExitRecords(pendingExit?.resultList); setTotalPages(exitPendingdPages); setPageNumber(1) }}
            active={exitStatusCard === "Pending"}
            count={pendingExit?.totalCount || 0}
          />


          <Card
            status={"Review"}
            onClick={() => { setExitStatusCard("Review"); setExitRecords(sentBackExit?.resultList); setTotalPages(exitSentbackPages); setPageNumber(1) }}
            active={exitStatusCard === "Review"}
            count={sentBackExit?.totalCount || 0}
          />

          <Card
            status={"Saved"}
            onClick={() => { setExitStatusCard("Saved"); setExitRecords(SavedExit?.resultList); setTotalPages(savedExitPages); setPageNumber(1) }}
            active={exitStatusCard === "Saved"}
            count={SavedExit?.totalCount || 0}
          />

        </div>
      )}

      <br />
      <br />
      <div className='flex flex-v-center searchtext'>
        <img src="/Vector.png" alt="staff request" />
        <span className='m-l-10 bold-text'>Staff Management Request</span>
      </div>
      <br />
      <br />
      <div className="flex m-b-20">
        <div
          onClick={() => { setOpenTab(0); setPageNumber(1); setTotalPages(1) }}
          className={
            openTab === 0
              ? "flex annual_button m-r-10 "
              : "flex annual_button1 m-r-10 "
          }
        >
          Onboarding
        </div>
        <div
          onClick={() => { setOpenTab(1); setPageNumber(1); setTotalPages(1) }}
          className={
            openTab === 1
              ? "flex annual_button m-r-10 "
              : "flex annual_button1 m-r-10 "
          }
        >
          Staff Update
        </div>
        <div
          onClick={() => { setOpenTab(2); setPageNumber(1); setTotalPages(1) }}
          className={
            openTab === 2
              ? "flex annual_button m-r-10 "
              : "flex annual_button1 m-r-10 "
          }
        >
          Staff Exit
        </div>

      </div>
      {/* <SearchInput
        placeholder='search'
        startDate={startDate}
        endDate={endDate}
        searchInput={searchInput}
      /> */}
      <br />
      {openTab === 0 && (
        <div>
          <div className='Tablecard'>
            <OnboardingTable
              records={onboardRecords}
              history={history}
              cardStatus={onboardingStatusCard}
              deleteOnboarding={deleteOnboarding}
            />
          </div>
          <div className='flex m-t-10 flex-v-center space-between'>
            <button className="btn" onClick={() => { handlePageNumberClick(pageNumber - 1) }} disabled={pageNumber === 1}>
              Previous Page
            </button>
            <div style={styles.pagination}>
              {pageNumbers.map((pageNumb, index) => (
                <div key={index}>
                  {pageNumb === '...' ? (
                    <div
                      style={{
                        ...styles.ellipses,
                        ...(pageNumb === (pageNumber) ? styles.active : {}),
                      }}
                      disabled
                    >
                      {pageNumb}
                    </div>
                  ) : (
                    <div
                      style={{
                        ...styles.pageNumber,
                        ...(pageNumb === pageNumber ? styles.active : {}),
                      }}
                      onClick={() => { handlePageNumberClick(pageNumb) }}
                    >
                      {pageNumb}
                    </div>
                  )}
                </div>
              ))}

            </div>
            <button className="btn" onClick={() => { handlePageNumberClick(pageNumber + 1) }} disabled={pageNumber === totalPages || !totalPages}>
              Next Page
            </button>
          </div>

        </div>
      )}
      {openTab === 1 && (
        <div>
          <div className='Tablecard'>
            <UpdateTable
              records={updateRecords}
              history={history}
              cardStatus={updateStatusCard}
              deleteUpdate={deleteUpdate}
              pageNumber={pageNumber}
              pageSize={pageSize}
              savedUpdatePages={savedUpdatePages}
              userId={userId}
            />
          </div>
          <div className='flex m-t-10 flex-v-center space-between'>
            <button className="btn" onClick={() => { handlePageNumberClick(pageNumber - 1) }} disabled={pageNumber === 1}>
              Previous Page
            </button>
            <div style={styles.pagination}>
              {pageNumbers.map((pageNumb, index) => (
                <div key={index}>
                  {pageNumb === '...' ? (
                    <div
                      style={{
                        ...styles.ellipses,
                        ...(pageNumb === (pageNumber) ? styles.active : {}),
                      }}
                      disabled
                    >
                      {pageNumb}
                    </div>
                  ) : (
                    <div
                      style={{
                        ...styles.pageNumber,
                        ...(pageNumb === pageNumber ? styles.active : {}),
                      }}
                      onClick={() => { handlePageNumberClick(pageNumb) }}
                    >
                      {pageNumb}
                    </div>
                  )}
                </div>
              ))}

            </div>
            <button className="btn" onClick={() => { handlePageNumberClick(pageNumber + 1) }} disabled={pageNumber === totalPages || !totalPages}>
              Next Page
            </button>
          </div>

        </div>
      )}
      {openTab === 2 && (
        <div>
          <div className='Tablecard'>
            <ExitTable
              history={history}
              records={exitRecords}
              cardStatus={exitStatusCard}
              deleteExit={deleteExit}
              pageNumber={pageNumber}
              pageSize={pageSize}
              savedExitPages={savedExitPages}
              userId={userId}
            />
          </div>

          <div className='flex m-t-10 flex-v-center space-between'>
            <button className="btn" onClick={() => { handlePageNumberClick(pageNumber - 1) }} disabled={pageNumber === 1}>
              Previous Page
            </button>
            <div style={styles.pagination}>
              {pageNumbers.map((pageNumb, index) => (
                <div key={index}>
                  {pageNumb === '...' ? (
                    <div
                      style={{
                        ...styles.ellipses,
                        ...(pageNumb === (pageNumber) ? styles.active : {}),
                      }}
                      disabled
                    >
                      {pageNumb}
                    </div>
                  ) : (
                    <div
                      style={{
                        ...styles.pageNumber,
                        ...(pageNumb === pageNumber ? styles.active : {}),
                      }}
                      onClick={() => { handlePageNumberClick(pageNumb) }}
                    >
                      {pageNumb}
                    </div>
                  )}
                </div>
              ))}

            </div>
            <button className="btn" onClick={() => { handlePageNumberClick(pageNumber + 1) }} disabled={pageNumber === totalPages || !totalPages}>
              Next Page
            </button>
          </div>

        </div>

      )
      }

    </div >
  );
};

export default Dashboard;
