
import React, { useEffect, useState, useContext } from "react";


import ProfilePix from "../../assets/images/profile-pix.jpg";
import { del, get, post, put } from "../../utility/fetch";
import { getMfile } from "../../utility/globalfetch"

import OfficialDetails from "./profileDetails/OfficialDetails";
import PersonalDetails from "./profileDetails/PersonalDetails";
import DependantDetails from "./profileDetails/DependantDetails";
import EducationDetails from "./profileDetails/EducationDetails";
import ProfessionalDetails from "./profileDetails/ProfessionalDetails";
import NextOfKinsDetails from "./profileDetails/NextOfKinsDetails";
import DatePicker from "react-datepicker";
import InitiateExit from "./InitiateExit";
import Dropdown from "../inputs/Dropdown";
import TextAreaInputField from "../inputs/TextAreaInputField";
import UploadButton from "../inputs/UploadButton";
import ApproverDropdown from "../inputs/ApproverDropdown";

import Select from "react-select";
import axios from "axios";
import Cookies from "js-cookie";
import InputField from "../inputs/InputField";
import { feedback } from "../modal/feedback";
import { RiDeleteBinLine } from "react-icons/ri";
import MyContext from "../../utility/Context";
import moment from "moment";
import SelectInput from "../inputs/SelectInput";


const ExitRequest = ({ history, }) => {
  const [currentForm, setCurrentForm] = useState("official-details");
  const [isUpdating, setIsUpdating] = useState(false);
  const [userProfile, setUserProfile] = useState({})
  const [userId, setUserId] = useState(Number)
  const [staffId, setStaffId] = useState(Number)
  const [searchValue, setSearchValue] = useState('')
  const [recipientMda, setrecipientMda] = useState([])
  const [toName, setToName] = useState([])
  const [approvers, setApprovers] = useState([])
  const [thoroughMda, setThroughMda] = useState([])
  const [throughName, setThroughName] = useState([])
  const [through, setThrough] = useState([])
  const [mFiles, setMFiles] = useState([])
  const [user, setUser] = useState({})
  const [comment, setComment] = useState('')
  const [exitReason, setExitReason] = useState('')
  const [documentArray, setDocumentArray] = useState([])
  const [receiveImage, setReceiveImage] = useState([])
  const [recordId, setRecordId] = useState(0)
  const [loading, setLoading] = useState(false);
  const [docNames, setDocNames] = useState([])
  const [m_File, setM_File] = useState([])
  const [currentCadre, setCurrentCadre] = useState('')
  const [firstAppCadre, setFirstAppCadre] = useState('')





  const [userDependants, setUserDependants] = useState({});
  const [userEducation, setUserEducation] = useState({});
  const [userProfessional, setUserProfessional] = useState({});
  const [userNextOfKins, setUserNextOfKins] = useState({});
  const [wrongStaffId, setWrongStaffId] = useState(false);

  const [approverlist, setApproverList] = useState([]);
  const [mDocs, setMDocs] = useState([])

  const authStatus = Cookies.get('authStatus');


  const deleteDoc = (doc) => {
    let newRegularArr = documentArray.filter((id) => id.name !== doc);
    let newMdocsArr = mDocs.filter((mdoc) => mdoc.label !== doc);
    setDocumentArray(newRegularArr);
    setMDocs(newMdocsArr);
  }

  const handleSelectInputChange = async (value) => {
    console.log(value);

    await FetchMFiles(value);
  };

  const handleSelectInput = (value) => {
    console.log(value);
    setMDocs(prevMDocs => [...prevMDocs, value]);
  };

  const userID = Cookies.get("userId");
  const StaffName = Cookies.get("fullName");
  const { exitToBeViewed, setExitToBeViewed, setUserRin, userRin, myRecord, setMyRecord, sentBack, setUpdateToBeViewed } = useContext(MyContext);

  useEffect(() => {
    setUserRin(null)
    setUpdateToBeViewed({})
    let newApprovers = []

    newApprovers = [
      ...(exitToBeViewed?.exitRequestThroughApprovers ?? []),
      ...(exitToBeViewed?.exitRequestToApprovers ?? [])

    ]

    setApproverList(newApprovers);
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
  }, []);

  console.log('holla', documentArray, exitToBeViewed, myRecord)
  useEffect(() => {
    setApprovers(
      toName?.map((item) => ({
        userId: Number(item?.value),
        mdaId: recipientMda?.value || 2,
        name: item?.label
      }))
    );

  }, [toName]);

  useEffect(() => {
    setThrough(
      throughName?.map((item) => ({
        userId: Number(item?.value),
        mdaId: thoroughMda?.value || 2,
        name: item?.label
      }))
    );

  }, [throughName]);



  const fetchDatas = async (currentCadre, firstAppCadre) => {
    const currentCadreData = await roleById(currentCadre);
    const firstAppCadreData = await roleById(firstAppCadre);

    setCurrentCadre(currentCadreData);
    setFirstAppCadre(firstAppCadreData);
  };

  const roleById = async (id) => {
    try {
      const response = await get(`role/${id}`)
      console.log('response', response);

      return { value: response?.data?.id, label: response?.data?.name }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await get(`fetchuser?staffid=${userRin}`);
      setUserId(response?.id);
      setStaffId(response?.staffID);
      console.log(staffId);
      setUser(response);
      setUserRin(null)
      feedback({
        title: "Success",
        text: "Successfully fetched user records",
        iconType: "success",
      });
      console.log("User DATA::", response);
    } catch (error) {
      feedback({
        title: "Failed",
        text: "Failed to fetch records",
        iconType: "error",
      });
      console.error('Error fetching states', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProfile = async () => {
    await get(`bio/${userId}`)
      .then(response => {
        setUserProfile(response?.data)

        fetchDatas(response?.data?.currentCadre, response?.data?.firstAppCadre)

        console.log("User DATA::", response);
      })
      .catch(error => {
        console.error('Error fetching states', error)
      })
  }


  const FetchMFiles = async (searchValue) => {
    console.log('Fetch', searchValue)
    await axios.get(`https://edogoverp.com/services/api/dashboard/searchdocuments?user_id=${parseInt(
      userID
    )}&DocTitle=${searchValue}`)
      .then(response => {
        const mFileList = response?.data.map((item, index) => ({
          value: index,
          label: item?.title,
          fileurl: item?.fileurl,
        }));
        setMFiles(mFileList)
        console.log("User DATA::", response, mFileList);
      })
      .catch(error => {
        console.error('Error fetching states', error)
      })
  }

  // Check for empty fields in the payload, including nested objects
  const emptyFields = [];

  const checkEmptyFields = (obj, path = '') => {
    for (const key in obj) {
      if (key !== 'comment' && key !== 'reason') {
        continue;
      }

      const value = obj[key];
      const fullPath = path ? `${path}.${key}` : key;

      if (value === undefined || value === null || value === '') {
        emptyFields.push(fullPath);
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        // Recursively check nested objects
        checkEmptyFields(value, fullPath);
      }
    }
  };


  const submitExit = async () => {

    if (approvers.length <= 0) {
      feedback({
        title: "No Approvers Selected",
        text: `Please select an approver`,
        iconType: "error",
      });
      return;
    }

    const documents = [...documentArray, ...mDocs]

    setLoading(true)
    const document = documents.map((doc) => ({
      filePath: doc.fileurl || doc.path,
    }));

    console.log(approvers, through, document)

    setLoading(true)
    const payload = {
      employeeEmail: user?.email,
      exitId: recordId,
      employeeRIN: Number(user?.rin),
      ministry: user?.name,
      staffFullName: StaffName,
      reason: exitReason,
      exitRequestToApprovers: approvers,
      exitRequestThroughApprovers: through,
      exitRequestDocuments: document,
      exitRequestMFiles: document,
      employeeUserId: Number(user?.id),
      comment: comment,
      nextApproverId: through[0]?.userId || approvers[0]?.userId,
      actionTaken: 'Submit'
    };




    checkEmptyFields(payload);

    if (emptyFields.length > 0) {
      // Display feedback for empty fields
      feedback({
        title: "Failed",
        text: `Please fill in the following fields: ${emptyFields.join(", ")}`,
        iconType: "error",
      });
      setLoading(false)
      console.log('docs', documentArray, payload)
      return;
    }

    console.log('docs', documentArray, payload)
    await post(`ExitRequest/submit-exist`, payload)
      .then((response) => {
        if (typeof response === "number") {
          // If response is an integer, show success message
          setRecordId(response);
          feedback({
            title: "Success",
            text: "Record submitted successfully",
            iconType: "success",

          });
          setLoading(false);
          console.log("User DATA before set::", user, response);
          setUser({});
          console.log(user);
          setToName([]);
          setThroughName([]);
          setDocumentArray([]);
          setThroughMda([]);
          setrecipientMda([]);
          setUserDependants([]);
          setUserProfessional([]);
          setUserEducation([]);
          setUserNextOfKins([]);
          setApprovers([]);
          setUserProfile([]);
          setComment('');
          setExitReason('');
          setMyRecord(false);
          setRecordId(0);

        } else if (typeof response === "object") {
          const { message } = response;
          if (response?.statusCode === 500 && response) {
            feedback({
              title: "Error",
              text: message || "Record Already Exists",
              iconType: "error",
            });
            setLoading(false);


          }else{
            feedback({
              title: "Error",
              text: message || "An error occurred while submitting the record",
              iconType: "error",
            });
            setLoading(false);

          }
        } else {
          // If response is neither integer nor object, show generic error message
          feedback({
            title: "Error",
            text: "An unknown error occurred while submitting the record",
            iconType: "error",
          });
          setLoading(false);

        }

      })
      .catch((error) => {
        feedback({
          title: "Failed",
          text: "Failed to submit records",
          iconType: "error",
        });
        setLoading(false);
        console.error('Error fetching states', error);
      });
  }

  console.log(approvers, through)

  const SaveExit = async () => {
    const documents = [...documentArray, ...mDocs]
    setLoading(true)
    const document = documents.map((doc) => ({
      filePath: doc.fileurl || doc.path,
    }));

    console.log(approvers, through, document)
    const payload = {
      employeeEmail: user?.email,
      employeeRIN: Number(user?.rin),
      ministry: user?.name,
      staffFullName: StaffName,
      reason: exitReason,
      exitRequestToApprovers: approvers,
      exitRequestThroughApprovers: through,
      exitRequestDocuments: document,
      exitRequestMFiles: document,
      employeeUserId: Number(user?.id),
      comment: comment,
      nextApproverId: through[0]?.userId || approvers[0]?.userId,
      actionTaken: 'Save'
    };

    checkEmptyFields(payload);

    if (emptyFields.length > 0) {
      // Display feedback for empty fields
      feedback({
        title: "Failed",
        text: `Please fill in the following fields: ${emptyFields.join(", ")}`,
        iconType: "error",
      });
      setLoading(false)
      console.log('docs', documentArray, payload)
      return;
    }

    if (recordId) {
      await put(`ExitRequest/update-exist/${recordId}`, payload)
        .then((response) => {
          if (typeof response === "number") {
            // If response is an integer, show success message
            setRecordId(response);
            feedback({
              title: "Success",
              text: "Record saved successfully",
              iconType: "success",

            });
            setLoading(false);
            console.log("User DATA before set::", user, response);
            setUser({});
            console.log(user);
            setToName([]);
            setThroughName([]);
            setDocumentArray([]);
            setThroughMda([]);
            setrecipientMda([]);
            setUserDependants([]);
            setUserProfessional([]);
            setUserEducation([]);
            setUserNextOfKins([]);
            setApprovers([]);
            setUserProfile([]);
            setComment('');
            setExitReason('');
            setMyRecord(false);
            setRecordId(0);

          } else if (typeof response === "object") {
            // If response is an object, extract message and show feedback
            const { message } = response;
            if (response?.statusCode === 500 && response) {
              feedback({
                title: "Error",
                text: message || "Record Already Exists",
                iconType: "error",
              });
              setLoading(false);

  
            }else{
              feedback({
                title: "Error",
                text: message || "An error occurred while saving the record",
                iconType: "error",
              });
              setLoading(false);

            }

          } else {
            // If response is neither integer nor object, show generic error message
            feedback({
              title: "Error",
              text: "An unknown error occurred while saving the record",
              iconType: "error",
            });
            setLoading(false);

          }


        }).catch((error) => {
          setMyRecord(false)
          feedback({
            title: "Failed",
            text: "Failed to update records",
            iconType: "error",
          });
          setLoading(false);
          console.error('Error fetching states', error);
        });

      return
    } else {
      console.log('docs', documentArray, payload)
      await post(`ExitRequest/save-exit`, payload)
        .then((response) => {
          if (typeof response === "number") {
            // If response is an integer, show success message
            setRecordId(response);
            feedback({
              title: "Success",
              text: "Record saved successfully",
              iconType: "success",

            });
            setLoading(false);
            console.log("User DATA before set::", user, response);
            setUser({});
            console.log(user);
            setToName([]);
            setThroughName([]);
            setDocumentArray([]);
            setThroughMda([]);
            setrecipientMda([]);
            setUserDependants([]);
            setUserProfessional([]);
            setUserEducation([]);
            setUserNextOfKins([]);
            setApprovers([]);
            setUserProfile([]);
            setComment('');
            setExitReason('');
            setMyRecord(false);
            setRecordId(0);

          } else if (typeof response === "object") {
            // If response is an object, extract message and show feedback
            const { message } = response;
            
            if (response?.statusCode === 500 && response) {
              feedback({
                title: "Error",
                text: message || "Record Already Exists",
                iconType: "error",
              });
              setLoading(false);

  
            }else{
              feedback({
                title: "Error",
                text: message || "An error occurred while saving the record",
                iconType: "error",
              });
              setLoading(false);

            }


          } else {
            // If response is neither integer nor object, show generic error message
            feedback({
              title: "Error",
              text: "An unknown error occurred while saving the record",
              iconType: "error",
            });
            setLoading(false);

          }


        }).catch((error) => {
          feedback({
            title: "Failed",
            text: "Failed to save records",
            iconType: "error",
          });
          setLoading(false)
          console.error('Error fetching states', error)
        })
    }
  }





  // ttps://edogoverp.com/global/api/employee/profile/67

  useEffect(async () => {
    setIsUpdating(true);
    window.scrollTo(0, 0);
    document.title = "EDSG Profile";
  
    if (exitToBeViewed && myRecord === true && !staffId && !userId) {
      setExitReason(exitToBeViewed?.reason);
  
      setDocumentArray(exitToBeViewed?.exitRequestDocuments.map((doc) => ({
        name: doc.filePath?.split('_').pop(),
        path: doc.filePath,
      })));
      setComment(exitToBeViewed?.comment);
      setRecordId(exitToBeViewed?.id);
      await fetchUserData();
    }
  
    const fetchData = async (id) => {
      const dependants = await get(`dependants/${id}`);
      const education = await get(`education/${id}`);
      const professional = await get(`profcerts/${id}`);
      const nextOfKin = await get(`nok/${id}`);
  
      const result = {};
  
      if (dependants.length > 0) result.dependants = dependants;
      if (education.length > 0) result.education = education;
      if (professional.length > 0) result.professional = professional;
      if (nextOfKin.length > 0) result.nextOfKin = nextOfKin;
  
      return result;
  };
  
  
    if (staffId || userId) {
      await fetchProfile();
  
      const responses = await Promise.all([
        staffId ? fetchData(staffId) : Promise.resolve(null),
        userId ? fetchData(userId) : Promise.resolve(null)
      ]);
  
      const [staffResponse, userResponse] = responses;
  
      if (staffResponse) {
        if (staffResponse.dependants) setUserDependants(staffResponse.dependants);
        if (staffResponse.education) setUserEducation(staffResponse.education);
        if (staffResponse.professional) setUserProfessional(staffResponse.professional);
        if (staffResponse.nextOfKin) setUserNextOfKins(staffResponse.nextOfKin);
      }
  
      if (userResponse) {
        if (userResponse.dependants) setUserDependants(userResponse.dependants);
        if (userResponse.education) setUserEducation(userResponse.education);
        if (userResponse.professional) setUserProfessional(userResponse.professional);
        if (userResponse.nextOfKin) setUserNextOfKins(userResponse.nextOfKin);
      }
  
      setIsUpdating(false);
      setWrongStaffId(false);
    }
  
  }, [staffId, userId, exitToBeViewed]);
  

  const addDefaultSrc = (ev) => {
    ev.target.src = ProfilePix;
  };

  const handleInputChange = (value) => {
    const selectedValue = value && value?.target ? value?.target?.value : value;
    if (!isNaN(selectedValue) && selectedValue.length <= 11) {
      // Validate if the value is a number and has a length of 11
      setUserRin(selectedValue)
    } else {
      setUserRin(selectedValue)
    }
    console.log('Rin', value, userRin)
  };


  if (loading) {
    return (
      <div className="processing">
        <p className="processing-text center-text">Processing Request...</p>
      </div>
    );
  }

  console.log('comment:', comment, toName, recordId, throughName, through, approvers)


  console.log('PROFILE', userProfile, userDependants[0], userEducation[0], userNextOfKins, userProfessional)

  function calculateDuration(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    const years = end.diff(start, 'years');
    start.add(years, 'years');
    const months = end.diff(start, 'months');
    start.add(months, 'months');
    const days = end.diff(start, 'days');

    return {
      years,
      months,
      days
    };
  }

  const currentDate = moment().format('YYYY-MM-DD')
  const duration = calculateDuration(userProfile?.dateOfFirstApp, currentDate);

  console.log('hey', duration, currentDate)

  const formatDate = (timestamp) => {
    if (timestamp === null || timestamp === undefined) {
      return
    }
    const dateObject = new Date(timestamp);
    const formattedDate = dateObject.toISOString().split('T')[0];
    return formattedDate;
  };

  return (
    <div className="w-100 p-r">
      <div className="col-5">
        <InitiateExit
          fetchUserData={fetchUserData}
          userRin={userRin}
          setUserRin={handleInputChange}
          setComment={setComment}
          setExitReason={setExitReason}
          setMyRecord={setMyRecord}
          setDocumentArray={setDocumentArray}
        />
      </div>
      <div className="w-100 flex space-between flex-v-center">
        <h2 className="search-text">Staff Exit</h2>
      </div>
      {loading ? (
        <div>
          {/* <Loader type="ThreeDots" color="green" height={100} width={100} /> */}
        </div>
      ) : (
        <>

          <div className="w-100 flex space-between">
            <div className="col-6 m-t-40">
              {/* {recordId &&
                <button className="btn-del m-b-20 " onClick={deleteExit}>Delete Record</button>
              } */}
              <div className="flex row space-between ">
                <div className="col-5 no-padding">
                  <label>Employe's Rin</label>
                  <InputField
                    placeholder='Input employee’s RIN'
                    value={user?.rin || ''}
                    disabled='disabled'
                  />
                </div>
                <div className="col-7  no-p-r">
                  <label>Mda</label>
                  <InputField
                    placeholder='Ministry of Housing and Urban Development'
                    value={user?.name || ''}
                    disabled='disabled'
                  />
                </div>
              </div>
              <div className="m-b-20 ">
                <label>Full Name</label>
                <InputField
                  placeholder='Staff full name'
                  value={user?.fullName || ''}
                  disabled='disabled'
                />
              </div>
              <div className="m-b-20 ">
                <label>Exit Reason</label>
                <InputField
                  onChange={(e) => { setExitReason(e?.target?.value) }}
                  placeholder='Exit Reason'
                  value={exitReason}

                />
              </div>
              <ApproverDropdown
                approverType="Through"
                setApproverName={setThroughName}
                setApproverMda={setThroughMda}
                approverMda={thoroughMda}
                approverName={throughName}
                isMulti={true}
              />

              <ApproverDropdown
                approverType="To"
                setApproverName={setToName}
                setApproverMda={setrecipientMda}
                approverMda={recipientMda}
                approverName={toName}
                isMulti={true}
                setTo={setToName}
                to={toName}
                type={'Exit'}
                isPSHR={authStatus?.toLowerCase()}
              />
            </div>
            <div className="col-6 m-t-40 m-l-20 ">
              {exitToBeViewed && myRecord === true && sentBack === true &&
                <div>
                  <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center ">Approver Journey</div>
                  <div className="reviewer-card">
                    <table className="reviewer-table">
                      <thead>
                        <tr>
                          <th className="center-text">Assigned Officers</th>
                          <th className="center-text">In-Tray</th>
                          <th className="center-text">Out-Tray</th>
                          <th className="center-text">Action Taken</th>
                          <th className="center-text">Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approverlist.map((item, index) => (
                          <tr key={index}>
                            <td className="center-text">{item?.name || item?.approverName}</td>
                            <td className="center-text">{formatDate(item?.recievedDate || item?.createdAt)}</td>
                            <td className="center-text">{formatDate(item?.approvedOn || item?.modifiedAt)}</td>
                            <td className="center-text">{item?.actionTaken}</td>
                            <td className="center-text">{item?.comment}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="w-100 m-t-20 user-full-details">
            <div className="tab-btns w-100">
              <button
                type="button"
                onClick={() => setCurrentForm("official-details")}
                className={currentForm === "official-details" ? "active" : ""}
              >
                Official Details
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("personal")}
                className={currentForm === "personal" ? "active" : ""}
              >
                Personal
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("professional")}
                className={currentForm === "professional" ? "active" : ""}
              >
                Professional
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("education")}
                className={currentForm === "education" ? "active" : ""}
              >
                Education
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("dependant")}
                className={currentForm === "dependant" ? "active" : ""}
              >
                Dependants
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("nextofkin")}
                className={currentForm === "nextofkin" ? "active" : ""}
              >
                Next Of Kin
              </button>
            </div>
            {wrongStaffId === true ? (
              <>
                <br />
                <p>
                  <i>
                    Update your staffId with your valid National Identity Number
                    to view your profile
                  </i>
                </p>
              </>
            ) : (
              <div className="w-100 flex">
                <div className="left">
                  <div className="content w-100">
                    {currentForm === "official-details" && (
                      <OfficialDetails data={userProfile} currentCadre={currentCadre || userProfile?.currentCadre} firstAppCadre={firstAppCadre || userProfile?.firstAppCadre} />
                    )}
                    {currentForm === "personal" && (
                      <PersonalDetails data={userProfile} />
                    )}
                    {currentForm === "education" && (
                      <EducationDetails data={userEducation} />
                    )}
                    {currentForm === "professional" && (
                      <ProfessionalDetails data={userProfessional} />
                    )}
                    {currentForm === "dependant" && (
                      <DependantDetails data={userDependants} />
                    )}
                    {currentForm === "nextofkin" && (
                      <NextOfKinsDetails data={userNextOfKins} />
                    )}
                  </div>
                  <div className="col-8">
                    <div className="m-t-40 m-l-10">Attach Documents</div>
                    <br />
                    <div className="col-8">
                      <div>
                        <UploadButton
                          setdocumentArray={setDocumentArray}
                          setDocNames={setDocNames}
                          sendImagg={receiveImage}
                        />

                        {documentArray?.map((item, index) => (
                          <div key={index} className="m-t-20 flex" >
                            <a href={item.path} target="_blank" className="m-r-10 m-b-10">
                              {item?.name}
                            </a>
                            <RiDeleteBinLine color="red" className="pointer" onClick={() => deleteDoc(item?.name)} />
                          </div>
                        ))}
                      </div>

                      <div className="m-b-20 m-t-20">
                        <label >M-Files</label>
                        <SelectInput
                          kenprop={"Choose M-file"}
                          options={mFiles}
                          onChange={(value) => { handleSelectInput(value) }}
                          onInputChange={(value) => { handleSelectInputChange(value) }}

                        />
                      </div>

                      {mDocs?.map((item, index) => (
                        <div key={index} className="m-t-10 flex" >
                          <a href={item.fileurl} target="_blank" className="m-r-10">
                            {item.label}
                          </a>
                          <RiDeleteBinLine color="red" className="pointer" onClick={() => deleteDoc(item.label)} />
                        </div>
                      ))}



                    </div>
                    <div className="m-l-10">
                      <label>Comment</label>
                      <TextAreaInputField
                        onChange={(e) => { setComment(e.target?.value) }}
                        placeholder='Comment:'
                        value={comment}
                      />
                    </div>
                    <div className=" m-l-10 m-t-20">
                      <button className="save-drafts m-b-10" onClick={SaveExit}>Save Draft</button>

                      <button className="submit-btn " onClick={submitExit} >Submit</button>

                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="w-100 m-t-40 center-text profile-brief">
                    <img
                      onError={addDefaultSrc}
                      src={
                        (userProfile && userProfile?.profilePicture) ||
                        ProfilePix
                      }
                      alt={userProfile && userProfile?.firstName}
                    />
                    <div className=" brief m-t-20">
                      <h4>
                        {`${(userProfile && userProfile?.firstName) || ""} ${(userProfile && userProfile?.lastName) || ""
                          }`}
                      </h4>
                      <hr />
                      <p className="  m-b-10">
                        <b className=" m-b-10">Engagement Date: </b>
                        <div className=" m-t-10">
                          {userProfile?.dateOfFirstApp}

                        </div>
                      </p>
                      <p className="  m-b-10">
                        <b>Duration on Grade: </b>
                        <div className=" m-t-10">
                          {duration?.years} years, {duration?.months} months, {duration?.days} day(s)

                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {/* <div className="flex space-between">
        <button className="save-drafts m-r-20">Updatet</button>
                  
        <button className="submit-btn ">Submit</button>

      </div> */}


    </div>
  );
};

export default ExitRequest;
