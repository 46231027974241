import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Select from "react-select";
import axios from "axios";
import { get } from "../../utility/fetch";

const ApproverDropdown = ({
  setApprovers,
  approverType,
  setApproverName,
  setApproverMda,
  approverMda,
  approverName,
  isMulti,
  notAuthorized,
  setTo,
  to,
  type,
  isPSHR,

}) => {
  const [mdaList, setMdaList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);
  const [defaultApproverList, setDefaultApproverlist] = useState([]);
  

  const user = Cookies.get("fullName");
  const userId = Cookies.get("userId");
  console.log(user)

  useEffect(() => {
    getApproversMDAs();
    fetchUsers()
  }, []);

  useEffect(() => {
    if (approverType === "To" && type === "Onboarding") {
      setTo(defaultApproverList)
    }

    
  }, [defaultApproverList]);

  const getEmployeesFromMda = async (id = 0) => {
    await axios
      .get(`https://edogoverp.com/services/api/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {
        const roleList = response?.data?.map((item) => ({
          name: `${item?.name}`, // Keep the name as is
          value: item?.id,
        }));
        console.log("User:", user.toLowerCase().trim());
        console.log("Role List:", roleList);
        const filteredEmployees = roleList.filter((item) => {
          // Remove everything after the first occurrence of a name enclosed in parentheses
          const roleName = item?.name?.split("(")[0].trim().toLowerCase();
          return roleName !== user.toLowerCase().trim();
        });
        console.log("Filtered Employees:", filteredEmployees);
        setEmployees(filteredEmployees);
      })
      .catch((error) => {
        console.error('Error fetching employees by MDA:', error);
      });
  };


  const fetchUsers = async (name) => {
    try {
      let endpoint = 'onboardingusers';
      if (name) {
        endpoint += `?query=${name}`;
      }
      const response = await get(endpoint);
      if (response?.code === 1) {
        const filteredEmployees = response?.data?.filter((item) => {
          // Remove everything after the first occurrence of a name enclosed in parentheses
          const roleName = item?.userId;
          console.log(roleName);
          if (type === 'Onboarding') {
            return (roleName !== Number(userId)) && (item?.role?.toLowerCase() !== 'governor' && item?.role?.toLowerCase() !== 'hos');
          } else {
            return (roleName !== Number(userId)) && (item?.role?.toLowerCase() !== 'governor');
          }
        });
        setUsers(filteredEmployees);
      }
      

      setDefaultApproverlist(response?.data?.filter((item) => {
        const role = item?.role?.toLowerCase();
        if (type === 'Onboarding') {
          return role === 'hos' || role === 'governor';
        }else{
          return role === 'ps-hr' || role === 'hos'
         }
      }).sort((a, b) => {
        // Sort by role, with "HOS" or "Governor" coming first if it's for onboarding, otherwise coming last
        if (type === 'Onboarding') {
          if (a.role.toLowerCase() === 'hos' && b.role.toLowerCase() !== 'hos') {
            return -1;
          } else if (a.role.toLowerCase() !== 'hos' && b.role.toLowerCase() === 'hos') {
            return 1;
          } else if (a.role.toLowerCase() === 'governor' && b.role.toLowerCase() !== 'governor') {
            return -1;
          } else if (a.role.toLowerCase() !== 'governor' && b.role.toLowerCase() === 'governor') {
            return 1;
          }
          return 0;
        } else {
          if (a.role.toLowerCase() === 'hos' && b.role.toLowerCase() !== 'hos') {
            return 1;
          } else if (a.role.toLowerCase() !== 'hos' && b.role.toLowerCase() === 'hos') {
            return -1;
          } else if (a.role.toLowerCase() === 'ps-hr' && b.role.toLowerCase() !== 'ps-hr') {
            return -1;
          } else if (a.role.toLowerCase() !== 'ps-hr' && b.role.toLowerCase() === 'ps-hr') {
            return 1;
          }
          return 0;
        }
      }));
  
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };





  const getApproversMDAs = async () => {
    await axios.get(`https://edogoverp.com/services/api/Employees/mdas`).then((response) => {
      const roleList = response?.data.map((item) => ({
        name: `${item?.name}`,
        value: item?.id,
      }));
      setMdaList(roleList);
    });
  };

  console.log(employees,  defaultApproverList)
  console.log(approverName, to )

  const handleMdaChange = (value) => {
    setApproverMda(value);
    getEmployeesFromMda(value?.value);
  };

  const handleApproverChange = (value) => {
    console.log(value)

    setApproverName(value);

  };


  return (
    <div>
      {notAuthorized || approverType == 'Through' ?
        <div>
          <div className="m-b-10 w-100 form-group ">
            <label>{`${approverType} MDA`}</label>
            <Select
              label={`Select ${approverType} MDA`}
              color="rgba(120,120,120,0.8)"
              className="m-b-10 w-w00"
              onChange={handleMdaChange}
              value={approverMda}
              options={mdaList?.map((m) => ({
                label: m?.name,
                value: m?.value,
              }))}
            />
          </div>
          <div className="m-b-10 w-100 form-group ">
            <label>{approverType || "Approver"} </label>
            <Select
              placeholder={`Select ${approverType}`}
              color="rgba(120,120,120,0.8)"
              className="m-b-10 w-w00"
              onChange={handleApproverChange}
              isMulti={isMulti}
              value={approverName}
              options={employees?.map((m) => ({
                label: m?.name,
                value: m?.value,
              }))}
            />
          </div>
        </div>
        :
        ( approverType == 'Through ' ?
            <div>
              <div className="m-b-10 w-100 form-group ">
                <label>{approverType || "Approver"} </label>
                <Select
                  placeholder={`Select ${approverType}`}
                  color="rgba(120,120,120,0.8)"
                  className="m-b-10 w-w00"
                  onChange={handleApproverChange}
                  isMulti={isMulti}
                  value={approverName}
                  options={defaultApproverList?.map((m) => ({
                    label: `${m?.user} (${m?.role})`,
                    value: m?.userId,
                    isDisabled: m?.role?.toLowerCase() === 'hos' ? true : m?.role?.toLowerCase() === isPSHR ? true : m?.role?.toLowerCase() === 'governor' ? true : false
                  }))}

                />

              </div>
            </div>
            :
            ( type === 'Onboarding' ?
              <div>
                <div className="m-b-10 w-100 form-group ">
                  <label>{approverType || "Approver"} </label>
                  <Select
                    placeholder={`Select ${approverType}`}
                    color="rgba(120,120,120,0.8)"
                    className="m-b-10 w-w00"
                    // onChange={handleApproverChange}
                    isMulti={isMulti}
                   
                    options={defaultApproverList?.map((m) => ({
                      label: `${m?.user} (${m?.role})`,
                      value: m?.userId,
                    }))}
                  />

                </div>
              </div>
              :
              (
                <div>
                <div className="m-b-10 w-100 form-group ">
                  <label>{approverType || "Approver"} </label>
                  <Select
                    placeholder={`Select ${approverType}`}
                    color="rgba(120,120,120,0.8)"
                    className="m-b-10 w-w00"
                    onChange={handleApproverChange}
                    isMulti={isMulti}
                    value={approverName}
                    options={defaultApproverList?.map((m) => ({
                      label: `${m?.user} (${m?.role})`,
                      value: m?.userId,
                      isDisabled: m?.role?.toLowerCase() === isPSHR ? true : false}))}
                    
                  />

                </div>
              </div>
              )
            )

          
      )
      }
    </div>
  );
};

export default ApproverDropdown;
