import { useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { feedback } from "../modal/feedback";




const DependantForm = ({ index, handleInputChangeDependant, handleDateChangeDependant, selectedDateDependant, handleRemoveQualificationDependant }) => {
    const relationship = [
        { value: "Brother", label: "Brother" },
        { value: "Cousin", label: "Cousin" },
        { value: "Daughter", label: "Daughter" },
        { value: "Father", label: "Father" },
        { value: "Father in law", label: "Father in law" },
        { value: "Mother", label: "Mother" },
        { value: "Mother in law", label: "Mother in law" },
        { value: "Nephew", label: "Nephew" },
        { value: "Niece", label: "Niece" },
        { value: "Sister", label: "Sister" },
        { value: "Son", label: "Son" },
        { value: "Spouse", label: "Spouse" },
        { value: "Uncle", label: "Uncle" },
        { value: "Granddaughter", label: "Granddaughter" },
        { value: "Grandson", label: "Grandson" },
        { value: "Grandfather", label: "Grandfather" },
        { value: "Grandmother", label: "Grandmother" }
    ];
    const Reset = () => {
        document.querySelector('form[name="deps"]').reset()
        handleDateChangeDependant(null);
        console.log('reset')
    }

    const [mobile, setMobile] = useState('')

    const handleMobileChange = (field, value) => {
        const selectedValue = value.target.value;

        if (field === "depMobile") {
            // Validate if the value is a number and has a length of 11
            if (!isNaN(selectedValue) && selectedValue.length <= 11) {
                handleInputChangeDependant("depMobile", selectedValue);
                console.log('mobile', selectedValue)
                setMobile(selectedValue)
            } else {
                // Display an error or handle the invalid input accordingly
                console.error("Invalid input for nokMobile");
                feedback({
                    title: "Failed",
                    text: "Invalid input for Mobile Number",
                    iconType: "error",
                });
            }
        }
    };


    return (
        <form name="deps">
            <div>
                <button className="btn" onClick={Reset}>clear inputs</button>
            </div>
            <div className="m-b-20 m-t-30">
                <label >Name</label>
                <InputField
                    placeholder='Name'
                    required
                    onChange={(value) => handleInputChangeDependant("depName", value)}
                />
            </div>

            <div className="m-b-20">
                <label >Address</label>
                <InputField
                    placeholder='Address'
                    required
                    onChange={(value) => handleInputChangeDependant("depAddress", value)}
                />
            </div>

            <div className="m-b-20">
                <div className="col-7 no-padding no-margin">
                    <label >Relationship</label>
                    <Dropdown
                        options={relationship}
                        placeholder='Select Relationship'
                        required
                        onChange={(value) => handleInputChangeDependant("depRelation", value)}

                    />
                </div>
                <div className="col-5 no-p-r no-margin">
                    <label >Date Of Birth</label>
                    <DatePicker className="input-field p-r-5 p-l-5"
                        placeholderText="Date Of Birth"
                        selected={selectedDateDependant}
                        required
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        onChange={handleDateChangeDependant}
                        showMonthDropdown
                        showYearDropdown={true}
                        scrollableYearDropdown
                        yearDropdownItemNumber={200}
                    />
                </div>
            </div>

            <div className="m-b-20">
                <label >Phone</label>
                <InputField
                    placeholder='Phone Number'
                    required
                    onChange={(value) => handleMobileChange("depMobile", value)}
                    value={mobile}
                />
            </div>

            {/* <button className="edit-btn m-b-20" onClick={() => handleRemoveQualificationDependant(index)}>Remove Qualification</button> */}

        </form>
    )
}

export default DependantForm;