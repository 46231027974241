import React from 'react';

import InputField from '../../inputs/InputField';

import { formatDateforPicker } from '../../../utility/dateTime';

const PersonalDetails = ({
  data
}) => (
  <div className="w-100 official-details">
    {!data || data === null ? (
      <p>No data to be displayed </p>
    ) : (
      <>
        <div className="row">
          <div className="col-4 no-margin m-b-20">
          <label>Rin:</label>
            <InputField
             
              label="Rin"
              value={data?.rin}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Title:</label>
            <InputField
              label="Title"
              value={data?.title || ''}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin m-b-20">
          <label>Surname:</label>
            <InputField
              label="Surname"
              value={data?.lastName || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>First Name:</label>
            <InputField
             
              label="First Name"
              value={data?.firstName || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Middle Name:</label>
            <InputField
             
              label="Middle Name"
              value={data?.middleName || ''}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin m-b-20">
          <label>Date of Birth:</label>
            <InputField
              // type={data?.dob !== null ? 'date' : null}
             
              label="Date of Birth"
              maxDate={formatDateforPicker(new Date())}
              value={data?.dob || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Gender:</label>
            <InputField
             
             
              value={data?.gender || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Marital Status:</label>
            <InputField
             
              label="Marital Status"
              value={data?.maritalStatus || ''}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin m-b-20">
          <label>Place of Birth:</label>
            <InputField
             
              label="Place of Birth"
              value={data?.placeOfBirth || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Nationality:</label>
            <InputField
              
              value={data?.nationality?.name || ''}
              disabled
            />
          </div>
          
        </div>
        <div className="row">
          <div className="col-4 no-margin m-b-20">
          <label>Religion:</label>
            <InputField
             
              label="Religion"
              value={data?.religion?.name || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Mother's Maiden Name:</label>
            <InputField
             
              label="Mother's Maiden Name"
              value={data?.motherMaidenName || ''}
              disabled
            />
          </div>
          <div className="col-4 no-margin m-b-20">
          <label>Wedding Anniversary:</label>
            <InputField
              
             
              maxDate={formatDateforPicker(new Date())}
              value={data?.weddingAnniversary || ''}
              disabled
            />
          </div>
        </div>
      </>
    )}
  </div>
);
export default PersonalDetails;
