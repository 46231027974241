import React, { useState } from 'react';
import SelectInput from '../inputs/SelectInput';
import ApproverDropdown from '../inputs/ApproverDropdown';
import InputField from '../inputs/InputField';

function CreateRole({ setemployeeName, setapprovalMDA, employeeName, approvalMDA, roleList, handleChange, createRole }) {
  const [isContentVisible, setContentVisible] = useState(false);



  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  return (
    <div >
      <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center space-between">
        <div className="bold-text">Create Role</div>
        <div>
          <button className="collapse-btn" onClick={toggleContent}>
            {isContentVisible ? 'Collapse' : 'Expand'}
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div className='m-b-20 col-6'>
          <label >Role Name</label>
          <InputField
            placeholder={'Role name'}
            onChange={(value) => handleChange('roleName', value)}

          />
        <button onClick={createRole} className='submit-btn m-t-20'>Create</button>
        </div>
      )}
    </div>
  );
}

export default CreateRole;
