import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadPic from "../inputs/UploadPic";
import UploadSignature from "../inputs/UploadSignature";
import ProfilePix from "../../assets/images/profile-pix.jpg";

import { FileUpload } from "../../utility/fetches";
import UploadButton from "../inputs/UploadButton";
import { feedback } from "../modal/feedback";
import { put } from "../../utility/fetch";

const Declaration = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
  }, []);


  
  const [pic, setPic] = useState({
    profilePicture: props?.data?.profilePicture || props?.payload?.profilePicture || '',
  })

  const [sig, setSig] = useState({
    signature: props?.data?.signature || props?.payload?.signature || '',
  })
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [sigName, setSigName] = useState('')
  const [picName, setPicName] = useState('')

  
  const [formData, setFormData] = useState({
    profilePicture:  '',
    signature: '',
  });
  
  useEffect(() => {
    props.handleFormData(formData);
  }, [formData]);
  
  const [selectedDate, setSelectedDate] = useState(null);
  const id = props.ID


  const postDeclaration = async () => {
    const payload = {
      profilePicture: pic,
      signature: sig,
      date: selectedDate
    }
    try {
      const response = await put(`bio/declarations/${id}`, payload)
      if (response?.code === 1) {
        props.handleFormData(payload);
        console.log('declaration', payload);
        console.log('response', response?.data);
        feedback({
          title: "Success",
          text: "Updated Successfully",
          iconType: "success",
        });
        submit();
        props?.clear()
        props?.setActiveStep(0)
      } else {
        feedback({
          title: "Failed",
          text: response?.message,
          iconType: "error",
        });
      }
    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const submit = async () => {

    try {
      const response = await put(`bio/submit/${id}`)
      console.log(response)
      if (response?.code === 1) {

        feedback({
          title: "Success",
          text: "Submitted Successfully",
          iconType: "success",
        });

      } else {
        feedback({
          title: "Failed",
          text: response?.message,
          iconType: "error",
        });
      }
    } catch (response) {
      feedback({
        title: "Oops!",
        text: "Something went wrong",
        iconType: "error",
      });

      console.error('failed to update perfonal info', response)
    }
  }

  const handleCheckBoxChange = () => {
    setCheckBoxValue(!checkBoxValue); // Toggle the checkbox value
  };


  const handleInputChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "phoneNumber") {
      // Validate if the value is a number and has a length of 11
      if (/^\d{11}$/.test(selectedValue)) {
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
      } else {
        // Display an error or handle the invalid input accordingly
        console.error("Invalid input for phone number");
      }
    } else {
      // For other fields, proceed without validation
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));
    }
  };

  const handlePicChange = (pics) => {
    console.log('Pic:', pics);
    setPic(pics)
    setFormData((prevData) => ({
      ...prevData,
      profilePicture: `${pics}`,
    }));

  }

  const handleSigChange = (sigs) => {
    setSig(sigs)
    setFormData((prevData) => ({
      ...prevData,
      signature: `${sigs}`,
    }));

  }

  const isFormValid = () => {
    // Check if all required fields are filled
    const isValid = Object.entries(formData).every(([fieldName, value]) => {
      console.log(`Field: ${fieldName}, Value: ${value}, Type: ${typeof value}`);
      if (fieldName === "date") {
        return true
      }
      if (typeof value === 'string') {
        // If the value is a string, check if it has at least one non-whitespace character
        return value.trim() !== '';
      } else {
        // For non-string values, consider the value valid if it exists
        return value !== undefined && value !== null;
      }
    });

    console.log("Form validity:", isValid);

    return isValid;
  };


  const handleDateChange = (date) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setFormData((prevData) => ({
      ...prevData,
      date: formattedDateWithoutTimestamp,
    }));

    setSelectedDate(date);
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };

  const handleSubmit = (e) => {
    postDeclaration()
    props.handleFormData(formData);
    console.log("Form submitted:", formData);
    console.log(props.handleFormData(formData))
    // Implement your logic to handle the form submission

  };

  console.log('pic', pic, 'sig', sig?.signature, props?.payload)
  const handleSigName = (newName) => {
    setSigName(newName)
    console.log('New name:', newName);
  };

  const handlePicName = (newName) => {
    setPicName(newName)
    console.log('New name:', newName);
  };

  console.log('sig name', sigName, pic)
  console.log('pic name', picName)

  const addDefaultSrc = (ev) => {
    ev.target.src = ProfilePix;
  };


  console.log(props?.data)
  return (

    <div className="form-step" data-step="7">
      <div >
        {props?.review === true ?
          <div className="flex flex-v-center input-field m-b-10">
            <input className="m-l-10 check-box" type="checkbox" id="checkbox" name="option1" checked={true} value="value" />
            <p className="searchtext m-l-20">I hereby affirm that the above particulars are true and correct.</p>
          </div>
          :
          (
            <div className="flex flex-v-center input-field m-b-10">
              <input className="m-l-10 check-box" type="checkbox" id="checkbox" name="option1" onChange={handleCheckBoxChange} value="value" />
              <p className="searchtext m-l-20">I hereby affirm that the above particulars are true and correct.</p>
            </div>
          )
        }
      </div>
      {props?.review !== true ?
        <div>
          <div className="flex space-between m-b-10">
            <div className="flex-col no-margin">
              <div><UploadPic
                handlePicChange={handlePicChange}
                name={handlePicName}
              />
              </div>
              <p className="m-t-10 center-text">{picName}</p>
            </div>
            <div className="flex-col no-margin">
              <div><UploadSignature
                handleSigChange={handleSigChange}
                name={handleSigName}
              />
              </div>
              <p className="m-t-10 center-text"> {sigName}</p>
            </div>
          </div>
        </div>
        :
        (
          <div className=" flex space-between w-100 m-t-40 m-b-40 center-text profile-brief">
            <div>
              <label>Profile Picture</label>
              <img
                onError={addDefaultSrc}
                src={
                  (props?.data && props?.data?.profilePicture) ||
                  ProfilePix
                }
                alt={props?.data && props?.data?.firstName}
              />

              {  props?.disabled !== true &&  props?.authStatus === 'ps-hr' &&
                <div>
                  <div className="flex space-between m-t-20 m-b-10">
                    <div className="flex-col no-margin">
                      <div><UploadPic
                        handlePicChange={handlePicChange}
                        name={handlePicName}
                      />
                      </div>
                      <p className="m-t-10 center-text">{picName}</p>
                    </div>
                  </div>
                </div>
              }

            </div>


            <div>
              <label>Signature</label>
              <img
                onError={addDefaultSrc}
                src={
                  (props?.data && props?.data?.signature) ||
                  ProfilePix
                }
                alt={props?.data && props?.data?.firstName}
              />

              { props?.disabled !== true &&  props?.authStatus === 'ps-hr' && 
                <div>
                  <div className="flex space-between m-t-20 m-b-10">
                    <div className="flex-col no-margin">
                      <div><UploadSignature
                        handleSigChange={handleSigChange}
                        name={handleSigName}
                      />
                      </div>
                      <p className="m-t-10 center-text"> {sigName}</p>
                    </div>
                  </div>
                </div>
              }

            </div>


          </div>




        )
      }

      <div className="flex space-between">
        <button className="col-5 submit-btn prev-step" onClick={props.handleBack}>Previous</button>
        {props?.review !== true &&
          <button type="Submit" onClick={handleSubmit} disabled={!checkBoxValue} className="col-5  submit-btn next-step">Submit</button>
        }
      </div>
    </div>


  )
}

export default Declaration;