import React, { useState } from 'react';
import OfficersComments from '../layouts/ApprovingOfficersComments';

function ApproversJourney({ recordToBeReviewed, toName, throughName, type }) {
  const [isContentVisible, setContentVisible] = useState(false);



  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  return (
    <div>
      <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center space-between">
        <div className="bold-text">Approval Journey</div>
        <div>
          <button className="collapse-btn" onClick={toggleContent}>
            {isContentVisible ? 'Collapse' : 'Expand'}
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div className="reviewer-card m-b-20 ">
          <div>
            <OfficersComments
              recordToBeReviewed={recordToBeReviewed}
              toName = {toName}
              throughName = {throughName}
              type = {type}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ApproversJourney;
