import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Container, Step, StepLabel, Stepper } from "@mui/material";

import './assets/css/pages-icons.css';
import './assets/css/pages-sidebar.css';
import './assets/css/index.css';
import './assets/css/general.css';
import { MyContextProvider } from './utility/Context';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <MyContextProvider>
      <App />
    </MyContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
