import React, { useState, useEffect, useContext } from "react";
import Bio from "./Bio";
import { Button, Container, Step, StepLabel, Stepper } from "@mui/material";

import PersonalInfo from "./PersonalInfo";
import EmploymentRecords from "./EmploymentRecords";
import EducationalQualifications from "./EducationalQualifications";
import ProfessionalQualification from "./ProfessionalQualificaton";
import Relatives from "./Relatives";
import Declaration from "./Declaration";
import MyContext from "../../utility/Context";

const steps = ["Bio", "Personal Information", "Employment Records", "Educational Qualifications", "Professional Qualifications", "Relatives", "Declarations"];

const OnboardingForm = ({ getID, history }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState(null);
  const [ID, setID] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [rinData, setRinData] = useState({})
  const [userDependants, setUserDependants] = useState({});
  const [userEducation, setUserEducation] = useState({});
  const [userProfessional, setUserProfessional] = useState({});
  const [userNextOfKins, setUserNextOfKins] = useState({});
  const [wrongStaffId, setWrongStaffId] = useState(false);
  const [payload, setPayload] = useState({});
  const [deps, setDeps] = useState([]);
  const [kins, setKins] = useState([]);


  const { setOnboardToBeViewed, onboardToBeViewed, setUserRin, setMyRecord, sentBack, setSentBack } = useContext(MyContext);



  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
  }, []);
  console.log('ID from post', ID)
  
  const handleFormData = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };
  
  console.log('forrmData', formData)
  const handleFormDataChangeDeps = (data) => {
    setDeps((prevData) => ({ ...prevData, ...data }));
    console.log(deps)
  };

  const handleFormDataChangeNok = (data) => {
    setKins((prevData) => ({ ...prevData, ...data }));
    console.log(kins)
  };


  const clearFormData = () => {
    setFormData({});
  }


  getID(ID)

  const handleIDUpdate = (id) => {
    setID(id);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null);
    }, 3000); // Auto-dismiss after 3 seconds
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Bio handleNext={handleNext} handleFormData={handleFormData} setRinData={setRinData} data = {onboardToBeViewed} onIDUpdate={handleIDUpdate} showNotification={showNotification} review={false} disabled={false} />;
      case 1:
        return <PersonalInfo handleBack={handleBack} handleNext={handleNext} payload= {formData} ID={ID} rinData={rinData} data = {onboardToBeViewed} handleFormData={handleFormData} showNotification={showNotification} review={false} disabled={false} />;
      case 2:
        return <EmploymentRecords handleBack={handleBack} handleNext={handleNext} payload= {formData} rinData={rinData} data = {onboardToBeViewed} ID={ID} handleFormData={handleFormData} showNotification={showNotification} review={false} disabled={false} />;
      case 3:
        return <EducationalQualifications handleBack={handleBack} canEdit={true} ID={ID} handleNext={handleNext} handleFormData={handleFormData} showNotification={showNotification} review={false} disabled={false} />;
      case 4:
        return <ProfessionalQualification handleBack={handleBack} canEdit={true} ID={ID} handleNext={handleNext} handleFormData={handleFormData} showNotification={showNotification} review={false} disabled={false} />;
      case 5:
        return <Relatives handleBack={handleBack} canEdit={true} handleNext={handleNext} handleFormDataDeps={handleFormDataChangeDeps} handleFormData={handleFormDataChangeNok} ID={ID}  showNotification={showNotification} review={false} dataNOK={userNextOfKins} dataDEP={userDependants} disabled={false} />;
      case 6:
        return <Declaration handleBack={handleBack} handleFormData={handleFormData} clear = {clearFormData} ID={ID} showNotification={showNotification} setActiveStep={setActiveStep} />;
      default:
        return "Unknown step";
    }
  };

  const handleStepClick = (step) => {
    setActiveStep(step);
  };
  
  return (
    <div>
      <div className="col-12 flex space-between m-t-30">
        <div>
          <h2 className="searchtext center-text bold-text m-b-40">Employee Details</h2>
          <Container>
            <div>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick = {()=>handleStepClick(index)}  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <div>
                  <p>All steps completed</p>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default OnboardingForm;
