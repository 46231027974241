import { useState, useEffect, useContext } from "react";

import { get } from "../../utility/fetch";
import Cookies from "js-cookie";
import ReviewModal from "../modal/ReviewModal";
import { feedback } from "../modal/feedback";
import UpdateReviewModal from "../modal/UpdateReviewModal";
import MyContext from "../../utility/Context";
import OnboardingReview from "../modal/OnboardingReview";

const ReviewRequest = () => {
  const [exitRecord, setExitRecord] = useState([]);
  const [updateRecords, setUpdateRecords] = useState([]);
  const [exitRecordToBeReviewed, setExitRecordToBeReviewed] =
    useState(undefined);
  const [updateRecordToBeReviewed, setUpdateRecordToBeReviewed] =
    useState(undefined);
  const [onboardingRecordToBeReviewed, setOnboardingRecordToBeReviewed] =
    useState(undefined);
  const [onboardingRecord, setOnboardingRecord] = useState([]);
  const [assignedMemoExit, setAssignedMemoExit] = useState();
  const [assignedMemoUpdate, setAssignedMemoUpdate] = useState();
  const [assignedExitPages, setAssignedExitPages] = useState(1);
  const [pageNumberAssignedExit, setpageNumberAssignedExit] = useState(1);
  const [pageSizeAssignedExit, setpageSizeAssignedExit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumberUpdate, setPageNumberUpdate] = useState(1);
  const [pageSizeUpdate, setPageSizeUpdate] = useState(10);
  const [openTab, setOpenTab] = useState(0);
  const [exitPages, setExitPages] = useState(1);
  const [updatePages, setUpdatePages] = useState(1);
  const [userID, setUserID] = useState(Cookies.get("userId"))
  // const userID = Cookies.get("userId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [innerTab, setInnerTab] = useState(0);
  const [type, setType] = useState("");

  const authStatus = Cookies.get("authStatus");

  const closeModal = () => {
    console.log("closeModal");
    setIsModalOpen(false);
    Cookies.set("fromdashboard", null);
    console.log("closeModal", isModalOpen);
  };

  const { setMyRecord, setUpdateToBeViewed } = useContext(MyContext);

  useEffect(() => {
    setUpdateToBeViewed({});
    fetch();

    window.scrollTo(0, 0);
    document.title = "EDSG - Dashboard";
  }, [pageNumber]);

  const ExitrequestsOnQueue = async () => {
    await get(`ExitRequest/list/approver/${userID}/${pageNumber}/${pageSize}`)
      .then((response) => {
        setExitRecord(response?.resultList);
        setExitPages(response?.totalPages);
        console.log("Review ON Queue::", response, exitRecord, exitPages);
      })
      .catch((error) => {
        console.error("Error fetching exits", error);
      });
    setMyRecord(false);
  };

  const fetchAssignedExits = async () => {
    await get(
      `ExitRequest/assign-memo/list/approver-assignee/${userID}/${pageNumberAssignedExit}/${pageSizeAssignedExit}`
    )
      .then((response) => {
        setAssignedMemoExit(response?.resultList);
        setAssignedExitPages(response?.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching approved exit records", error);
      });
  };

  // const fetchAssignedUpdate = async () => {
  //     await get(`update/update-assign-memo/approver-assignee/${userID}`)
  //         .then(response => {
  //             setAssignedMemoUpdate(response)

  //         })
  //         .catch(error => {
  //             console.error('Error fetching approved exit records', error)
  //         })
  // }

  const updateRequestsOnQueue = async () => {
    await get(
      `update/list/approver/${userID}/${pageNumberUpdate}/${pageSizeUpdate}`
    )
      .then((response) => {
        setUpdateRecords(response?.resultList);
        setUpdatePages(response?.totalPages);
        console.log("Review ON Queue::", response);
      })
      .catch((error) => {
        console.error("Error fetching update", error);
      });
  };

  const OnboardingRequestsOnQueue = async () => {
    await get(`bio/user-dashboard?userid=${userID}`)
      .then((response) => {
        if (Cookies.get("fromdashboard") == "true") {
          setIsModalOpen(true);
          setOnboardingRecord(response?.data);

          setOnboardingRecordToBeReviewed(
            response?.data.find((e) => e.id == Cookies.get("requestid"))
          );
        } else setOnboardingRecord(response?.data);

        // setExitPages(response?.totalPages)
        console.log("Review ON Queue::", response?.data);
      })
      .catch((error) => {
        console.error("Error fetching exits", error);
      });
  };

  console.log(updatePages);

  const fetch = async () => {
    setLoading(true);
    await ExitrequestsOnQueue();
    await fetchAssignedExits();
    await updateRequestsOnQueue();
    await OnboardingRequestsOnQueue();
    // await fetchAssignedUpdate()
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="processing">
        <p className="processing-text">Fetching Records...</p>
      </div>
    );
  }

  const selectedRecord = (record) => {
    console.log(record);
    if (openTab === 0) {
      console.log("Clicked on onboarding record:", record);
      setOnboardingRecordToBeReviewed(record);
      console.log(
        "Clicked on onboarding record:",
        onboardingRecordToBeReviewed
      );
      setIsModalOpen(true);
      console.log("Clicked on onboarding record:", isModalOpen);
    } else if (openTab === 1) {
      console.log("Clicked on exit record:", record);
      setUpdateRecordToBeReviewed(record);
      console.log("Clicked on  exit record:", updateRecordToBeReviewed);
      setIsModalOpen(true);
      console.log("Clicked on exit record:", isModalOpen);
    } else {
      console.log("Clicked on update record:", record);
      setExitRecordToBeReviewed(record);
      console.log("Clicked on update record:", exitRecordToBeReviewed);
      setIsModalOpen(true);
      console.log("Clicked on update record:", isModalOpen);
    }
  };

  const formatDate = (timestamp) => {
    const dateObject = new Date(timestamp);
    const formattedDate = dateObject.toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <div className="w-100">
      <div className="flex m-b-20 bottom-border">
        {(authStatus.toLocaleLowerCase() === "ps-hr" ||
          authStatus.toLocaleLowerCase() === "governor" ||
          authStatus.toLocaleLowerCase() === "hos") && (
            <div
              onClick={() => setOpenTab(0)}
              className={
                openTab === 0
                  ? "flex annual_button m-r-10 "
                  : "flex annual_button1 m-r-10 "
              }
            >
              Onboarding Review
            </div>
          )}
        {authStatus.toLocaleLowerCase() !== "governor" && (
          <div
            onClick={() => setOpenTab(1)}
            className={
              openTab === 1
                ? "flex annual_button m-r-10 "
                : "flex annual_button1 m-r-10 "
            }
          >
            Staff Update Review
          </div>
        )}
        {(authStatus.toLocaleLowerCase() === "ps-hr" ||
          authStatus.toLocaleLowerCase() === "ps" ||
          authStatus.toLocaleLowerCase() === "dss" ||
          authStatus.toLocaleLowerCase() === "hos" ||
          authStatus.toLocaleLowerCase() === "hoa") && (
            <div
              onClick={() => setOpenTab(2)}
              className={
                openTab === 2
                  ? "flex annual_button m-r-10 "
                  : "flex annual_button1 m-r-10 "
              }
            >
              Staff Exit Review
            </div>
          )}
      </div>
      {openTab === 0 &&
        (authStatus.toLocaleLowerCase() === "ps-hr" ||
          authStatus.toLocaleLowerCase() === "governor" ||
          authStatus.toLocaleLowerCase() === "hos") && (
          <div>
            <div>
              <div className="reviewer-card m-b-20 m-t-20">
                <table className="reviewer-table">
                  <thead>
                    <tr>
                      <th className="center-text">Staff Name</th>
                      <th className="center-text">In-Tray</th>
                      <th className="center-text">Status</th>
                      <th className="center-text">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(onboardingRecord) &&
                      onboardingRecord?.map(
                        (record) =>

                          <tr
                            key={record?.id}
                            onClick={() => selectedRecord(record)}
                          >
                            <td className="center-text">
                              {record?.initiator}
                            </td>
                            <td className="center-text">
                              {formatDate(record?.created)}
                            </td>
                            <td className="center-text">{record?.status}</td>
                            <td className="center-text">
                              <button
                                className="btn"
                                onClick={() => selectedRecord(record)}
                              >
                                View Record
                              </button>
                            </td>
                          </tr>
  
                      )}
                  </tbody>
                </table>
              </div>

              {/* <div className="flex space-between">
                                <button className="btn" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1} >
                                    Previous Page
                                </button>
                                <button className="btn" onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === exitPages} >
                                    Next Page
                                </button>
                            </div> */}
            </div>
          </div>
        )}
      {openTab === 1 && authStatus.toLocaleLowerCase() !== "governor" && (
        <div>
          <div>
            <div className="reviewer-card m-b-20 m-t-20">
              <table className="reviewer-table">
                <thead>
                  <tr>
                    <th className="center-text">Staff Name</th>
                    <th className="center-text">In-Tray</th>
                    <th className="center-text">Status</th>
                    <th className="center-text">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(updateRecords) &&
                    updateRecords?.map((record) => (
                      // record?.approvalStatus === 'Pending' &&
                      <tr
                        key={record?.id}
                        onClick={() => selectedRecord(record)}
                      >
                        <td className="center-text">{record?.from}</td>
                        <td className="center-text">
                          {formatDate(record?.createdAt)}
                        </td>
                        <td className="center-text">
                          {record?.approvalStatus}
                        </td>
                        <td className="center-text">
                          <button
                            className="btn"
                            onClick={() => selectedRecord(record)}
                          >
                            View Record
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="flex space-between">
              <button
                className="btn"
                onClick={() => setPageNumberUpdate(pageNumberUpdate - 1)}
                disabled={pageNumberUpdate === 1}
              >
                Previous Page
              </button>
              <button
                className="btn"
                onClick={() => setPageNumberUpdate(pageNumberUpdate + 1)}
                disabled={
                  pageNumberUpdate === updatePages ||
                  updatePages === undefined ||
                  updatePages === null
                }
              >
                Next Page
              </button>
            </div>
          </div>
        </div>
      )}

      {openTab === 2 &&
        (authStatus.toLocaleLowerCase() === "ps-hr" ||
          authStatus.toLocaleLowerCase() === "ps" ||
          authStatus.toLocaleLowerCase() === "dss" ||
          authStatus.toLocaleLowerCase() === "hos" ||
          authStatus.toLocaleLowerCase() === "hoa") && (
          <div>
            <div>
              <div className="reviewer-card m-b-20 m-t-20">
                <table className="reviewer-table">
                  <thead>
                    <tr>
                      <th className="center-text">Staff Name</th>
                      <th className="center-text">In-Tray</th>
                      <th className="center-text">Status</th>
                      <th className="center-text">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(exitRecord) &&
                      exitRecord?.map((record) => (
                        // record?.approvalStatus === 'Pending' &&
                        <tr
                          key={record?.id}
                          onClick={() => selectedRecord(record)}
                        >
                          <td className="center-text">
                            {record?.staffFullName}
                          </td>
                          <td className="center-text">
                            {formatDate(record?.createdAt)}
                          </td>
                          <td className="center-text">
                            {record?.approvalStatus}
                          </td>
                          <td className="center-text">
                            <button
                              className="btn"
                              onClick={() => selectedRecord(record)}
                            >
                              View Record
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="flex space-between">
                <button
                  className="btn"
                  onClick={() => setPageNumber(pageNumber - 1)}
                  disabled={pageNumber === 1}
                >
                  Previous Page
                </button>
                <button
                  className="btn"
                  onClick={() => setPageNumber(pageNumber + 1)}
                  disabled={
                    pageNumber === exitPages ||
                    exitPages === undefined ||
                    exitPages === null
                  }
                >
                  Next Page
                </button>
              </div>
            </div>
          </div>
        )}

      {/* {
                openTab === 2 && (
                    <div>

                        <div className="flex">
                            <div
                                onClick={() => setInnerTab(0)}
                                className={
                                    innerTab === 0
                                        ? "flex annual_button m-r-10 "
                                        : "flex annual_button1 m-r-10 "
                                }
                            >
                                Pending Review
                            </div>
                            <div
                                onClick={() => setInnerTab(1)}
                                className={
                                    innerTab === 1
                                        ? "flex annual_button m-r-10 "
                                        : "flex annual_button1 m-r-10 "
                                }
                            >
                                Assigned
                            </div>


                        </div>
                        {innerTab === 0 && (
                            <div>
                                <div className="reviewer-card m-b-20 m-t-20">
                                    <table className="reviewer-table">
                                        <thead>
                                            <tr >
                                                <th className="center-text">Staff Name</th>
                                                <th className="center-text">In-Tray</th>
                                                <th className="center-text">Status</th>
                                                <th className="center-text">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(exitRecord) && exitRecord?.map((record) => (
                                                record?.actionTaken !== 'Save' && (
                                                    <tr key={record?.id} onClick={() => selectedRecord(record)}>
                                                        <td className="center-text">{record?.staffFullName}</td>
                                                        <td className="center-text">{formatDate(record?.createdAt)}</td>
                                                        <td className="center-text">{record?.approvalStatus}</td>
                                                        <td className="center-text">
                                                            <button className="btn" onClick={() => selectedRecord(record)}>
                                                                View Record
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>


                                </div>

                                <div className="flex space-between">
                                    <button className="btn" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1} >
                                        Previous Page
                                    </button>
                                    <button className="btn" onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === exitPages} >
                                        Next Page
                                    </button>
                                </div>
                            </div>
                        )}

                        {innerTab === 1 && (
                            <div>
                                <div className="reviewer-card m-b-20 m-t-20">
                                    <table className="reviewer-table">
                                        <thead>
                                            <tr >
                                                <th className="center-text">Staff Name</th>
                                                <th className="center-text">In-Tray</th>
                                                <th className="center-text">Status</th>
                                                <th className="center-text">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(assignedMemoExit) && assignedMemoExit?.map((record) => (
                                                record?.actionTaken !== 'Save' && (
                                                    <tr key={record?.id} onClick={() => selectedRecord(record)}>
                                                        <td className="center-text">{record?.staffFullName}</td>
                                                        <td className="center-text">{formatDate(record?.createdAt)}</td>
                                                        <td className="center-text">{record?.approvalStatus}</td>
                                                        <td className="center-text">
                                                            <button className="btn" onClick={() => selectedRecord(record)}>
                                                                View Record
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>


                                </div>

                                <div className="flex space-between">
                                    <button className="btn" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1} >
                                        Previous Page
                                    </button>
                                    <button className="btn" onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === assignedExitPages} >
                                        Next Page
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )
            } */}
      {isModalOpen && openTab === 0 && (
        <OnboardingReview
          type={"Onboarding"}
          closeModal={closeModal}
          recordToBeReviewed={onboardingRecordToBeReviewed}
          userID={userID}
          requestsOnQueue={OnboardingRequestsOnQueue}
        />
      )}
      {isModalOpen && openTab === 1 && (
        <UpdateReviewModal
          type={"Update"}
          closeModal={closeModal}
          recordToBeReviewed={updateRecordToBeReviewed}
          initiatorId={
            updateRecordToBeReviewed?.updateRequestEmployeeRecord?.userId
          }
          staffId={
            updateRecordToBeReviewed?.updateRequestEmployeeRecord?.staffID
          }
          fetchUpdate={updateRequestsOnQueue}
          userRecord={updateRecordToBeReviewed?.updateRequestEmployeeRecord}
        />
      )}
      {isModalOpen && openTab === 2 && (
        <ReviewModal
          type={"Exit"}
          closeModal={closeModal}
          recordToBeReviewed={exitRecordToBeReviewed}
          userID={userID}
          requestsOnQueue={ExitrequestsOnQueue}
          fetchAssignedExits={fetchAssignedExits}
        />
      )}
    </div>
  );
};

export default ReviewRequest;
