import { useState } from "react";


const ActionDropdown =({setAction, status}) =>{
    return(
        <div>
            <select
                className="action-dropdown " 
                placeholder='Select Action'
                required
                onChange={(e)=>{setAction(e?.target?.value)}}
            
            >
                <option>Select Action</option>
                <option value="Approved">Approve</option>
                <option value="Back For Review">Send back for review</option>
            </select>
            
        </div>
    );
}

export default ActionDropdown;
