import React from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { useState } from "react";

const UpdateFormProf = ({
  isOpen,
  onClose,
  index,
  certificates,
  degrees,
  handleUpdateChange,
  handleDateChangeUpdate,
  updateRecord,
  id,
}) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  if (!isOpen) {
    return null;
  }

  const resetForm = () => {
    setSelectedDate(null)
    setSelectedYear(null)
  }

  const handleDate = (field, date, recordIndex) => {
    if (field === 'certExpirationDate') {
      setSelectedDate(date)
    } else {
      setSelectedYear(date)
    }

    handleDateChangeUpdate(field, date, recordIndex)
  };
  return (
    <div className="overlay">
      <button className="svg close-btn" onClick={() => { onClose(); resetForm(); }}>
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="close-btn pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z">
          </path>
        </svg>
      </button>
      <form className="col-4 modal-container " key={index} name={`update-prof-form-${index}`}>
        <div key={index} className="qualification-form m-t-30">
          <div className="m-b-20">
          <label >Certification Type</label>
            <InputField
              placeholder='Certification Type'
              required
              onChange={(value) => handleUpdateChange("certType", value, index)}
            />
          </div>

          <div className="m-b-20">
          <label >Certificate Obtained</label>
            <InputField
              placeholder='Certificate Obtained'
              required
              onChange={(value) => handleUpdateChange("certObtained", value, index)}

            />
          </div>

          <div className="m-b-20">
            <div className="col-7 no-padding no-margin">
            <label >Certificate/License Number</label>
              <InputField
                placeholder='Certificate/License Number'
                required
                onChange={(value) => handleUpdateChange("certNumber", value, index)}
              />
            </div>
            <div className="col-5 no-p-r no-margin">
            <label >Expiration Date</label>
              <DatePicker className="input-field p-r-5 p-l-5"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => handleDate("certExpirationDate", date, index)}
                placeholderText="Expiration Date"
                selected={selectedDate}
                showMonthDropdown
                showYearDropdown={true}
                scrollableYearDropdown
                yearDropdownItemNumber={200}
                required />
            </div>
          </div>

          <div className="m-b-20">
            <label >Year of attaining certification</label>
              <DatePicker className="input-field p-r-5 p-l-5"
                dateFormat="yyyy"
                selected={selectedYear}
                onChange={(date) => handleDate("certYear", date, index)}
                placeholderText="Year of attaining certification"
                showYearPicker

                required />
          </div>

          <div className="m-b-20">
          <label >Remarks</label>
            <TextAreaInputField
              placeholder='Remarks'
              required
              onChange={(value) => handleUpdateChange("remarks", value, index)}
            />
          </div>
          <div className="flex space-between">
            <button
              className="btn m-t-20 m-b-20"
              onClick={(e) => updateRecord(e, id, index)}
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateFormProf;
