import React, { useEffect, useState } from 'react';

const Footer = () => {
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
      setCurrentYear(new Date().getFullYear());
    
  }, []);
  
  return (
    <footer>
      <div className="content space-between flex-v-center">
        <div className="footer-inner-left">
          <p>© {currentYear} Edo State Government Digital Office</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
