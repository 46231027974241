import { useEffect, useState } from "react";
import ApproverDropdown from "../inputs/ApproverDropdown"
import Cookies from "js-cookie";


const InitiateOnboarding = ({ setToApprovers, setThroughApprovers }) => {

  const [approvers, setApprovers] = useState([])
  const [throughMda, setThroughMda] = useState([])
  const [toMda, setToMda] = useState([])
  const [toName, setToName] = useState([])
  const [throughName, setThroughName] = useState([])

  const authStatus = Cookies.get('authStatus')


  useEffect(() => {
    setToApprovers(
      toName?.map((item) => ({
        approverId: Number(item?.userId),
        approverName: item?.user
      }))
    );
  }, [toName]);

  useEffect(() => {
    setThroughApprovers(
      throughName?.map((item) => ({
        approverId: Number(item?.value),
        approverName: item?.label
      }))
    );
  }, [throughName]);


  console.log(throughName , toName);

  return (
    <div className="flex-col ">
      <ApproverDropdown
        approverType="Through "
        setApproverName={setThroughName}
        setApproverMda={setThroughMda}
        approverMda={throughMda}
        approverName={throughName}
        isMulti = {true}
        isPSHR={authStatus?.toLowerCase()}

      />

      <ApproverDropdown
        approverType="To"
        approverMda={toMda}
        approverName={toName}
        isMulti = {true}
        setTo={setToName}
        to={toName}
        type={'Onboarding'}

      />

    </div>
  )
}

export default InitiateOnboarding