const UnratifiedNameChange = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Promotion letter</p>
                    <p>2: Appointment letter</p>
                    <p>3: Biometric with a new name</p>
                    </div>
                    <div className="col-7">
                    <p>4: Previous name and new name</p>
                    </div>
                </div>
    )
}

export default UnratifiedNameChange;