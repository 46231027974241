import React, { useState } from 'react';
import OfficersComments from '../layouts/ApprovingOfficersComments';
import ExitRequestDisplay from '../ReviewRecordsDetails/DisplayExitDetails';
import DisplayUpdateDetails from '../ReviewRecordsDetails/DisplayUpdate';
import OnboardingDetails from '../page modifiers/OnboardingDetails';

function ExitEmployeeDetails({ userId, staffId, type, recordToBeReviewed, userEducation, userDependants, user, userNextOfKins, userProfessional, userProfile, currentCadre, firstAppCadre }) {
  const [isContentVisible, setContentVisible] = useState(false);
  const [payload, setPayload] = useState({})

  console.log('ShowEmployeeDetails', recordToBeReviewed, type)



  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  return (
    <div>
      <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center space-between">
        <div className="bold-text">Show Employee Details</div>
        <div>
          <button className="collapse-btn" onClick={toggleContent}>
            {isContentVisible ? 'Collapse' : 'Expand'}
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div className="reviewer-card m-b-20 ">
          {recordToBeReviewed !== undefined && type === 'Exit' && (
            <ExitRequestDisplay
              userId={userId}
              recordToBeReviewed={recordToBeReviewed}
              userDependants={userDependants}
              userEducation={userEducation}
              userProfessional={userProfessional}
              userNextOfKins={userNextOfKins}
              userProfile={userProfile}
              currentCadre={currentCadre}
              firstAppCadre={firstAppCadre}
            />
          )}

          {recordToBeReviewed !== undefined && type === 'Onboarding' &&
            <OnboardingDetails
              userId={userId}
              staffId={staffId}
              recordToBeReviewed={recordToBeReviewed}
              userProfile={user}
              userDependants={userDependants}
              userEducation={userEducation}
              userProfessional={userProfessional}
              userNextOfKins={userNextOfKins}
              review={true}
              disabled={true}
              setPayload={setPayload}
              type={type}
              payload={payload}
            />

          }
        </div>
      )}
    </div>
  );
}

export default ExitEmployeeDetails;
