import { useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { get, put, del } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import UpdateDependant from "./UpdateDependant";


const DisplayDependant = ({ userId, setDepRecord, depRecord, index, disabled, review, handleFormData, canEdit }) => {
  const [updateForm, setUpdateForm] = useState((Array.isArray(depRecord) && depRecord[index]) || {});
  const [selectedDate, setSelectedDate] = useState(null)
  const [formStates, setFormStates] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [mobile, setMobile] = useState('')

  const [isModalOpenArray, setIsModalOpenArray] = useState(Array(Array.isArray(depRecord) && depRecord?.length).fill(false));



  useEffect(() => {
    setFormStates(
      Array.isArray(depRecord) && depRecord?.map((record) => ({
        updateForm: { ...record },
        selectedDate: record?.depDoB ? new Date(record?.depDoB) : null,
        isEditing: true,

      }))
    );
    console.log(selectedRecordId)
  }, [depRecord, selectedRecordId]);

  const toggleEdit = (e, recordIndex) => {
    e.preventDefault();

    setUpdateForm(depRecord[recordIndex]); // Set updateForm to the selected record


    setFormStates((prevFormStates) => {
      const updatedFormStates = [...prevFormStates];
      updatedFormStates[recordIndex].isEditing = !updatedFormStates[recordIndex].isEditing;
      return updatedFormStates;
    });


  };

  const openModal = (recordIndex, id) => {
    setSelectedRecordId(id);

    const updatedArray = [...isModalOpenArray];
    updatedArray[recordIndex] = true;
    setIsModalOpenArray(updatedArray);
  };

  const closeModal = (recordIndex) => {
    const updatedArray = [...isModalOpenArray];
    updatedArray[recordIndex] = false;
    setIsModalOpenArray(updatedArray);
  };

  const isFormValid = () => {
    // Check if all required fields are filled
    const isValid = Object.entries(updateForm).every(([fieldName, value]) => {
      console.log(`Field: ${fieldName}, Value: ${value}, Type: ${typeof value}`);

      if (fieldName === "depMobile") {
        // Validate nin and mobile fields with exactly 11 digits
        if (/^\d{11}$/.test(value)) {
          return true
        } else {
          feedback({
            title: "Failed",
            text: "Please enter valid phone number",
            iconType: "error",
          });
          return false
        };

      }
      return true
    });

    console.log("Form validity:", isValid);

    return isValid;
  };

  const updateRecord = async (e, id, recordIndex) => {
    e.preventDefault()
    console.log('update id', id)


    try {
      const response = await put(`dependants/${id}`, formStates[recordIndex].updateForm)
      const updatedRecord = response?.data
      setDepRecord((prevRecords) => {
        const updatedRecords = prevRecords.map((record, index) =>
          index === recordIndex ? updatedRecord : record
        );
        return updatedRecords;
      });
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      })
      closeModal(recordIndex)

    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
    }
  }

  const deleteRecord = async (e, id) => {
    e.preventDefault()
    console.log('del id', id)
    try {

      const response = await del(`dependants/${id}`)
      setDepRecord((prevRecords) =>
        prevRecords.filter((record) => record?.id !== id)
      );
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });

    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
    }
  }

  console.log("dep record  ", depRecord)

  const handleDateChangeUpdate = (field, date, recordIndex) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setUpdateForm((prevData) => ({
      ...prevData,
      depDoB: formattedDateWithoutTimestamp,
    }));

    setFormStates((prevFormStates) => {
      const updatedFormStates = [...prevFormStates];
      updatedFormStates[recordIndex].selectedDate = date;
      updatedFormStates[recordIndex].updateForm = {
        ...updatedFormStates[recordIndex].updateForm,
        [field]: formattedDateWithoutTimestamp,
      };
      return updatedFormStates;
    });

    handleFormData(formStates?.updateForm)
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };

  console.log("selectedDate:", selectedDate);

  console.log("updateForm:", updateForm);


  const handleUpdateChange = (field, value, recordIndex) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === 'depNumber') {


      if (!isNaN(selectedValue) && selectedValue.length <= 11) {
        console.log('mobile', selectedValue)
        setUpdateForm((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
        setMobile(selectedValue)
      } else {
        console.error("Invalid input for depMobile");
        feedback({
          title: "Failed",
          text: "Invalid input for Mobile Number",
          iconType: "error",
        });
      }
    } else {
      setUpdateForm((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));
    }

    setFormStates((prevFormStates) => {
      const updatedFormStates = [...prevFormStates];
      updatedFormStates[recordIndex].updateForm = {
        ...updatedFormStates[recordIndex].updateForm,
        [field]: selectedValue,
      };
      return updatedFormStates;
    });

    handleFormData(formStates?.updateForm)
  };
  const professionalRecordsArray = Array.isArray(depRecord) ? depRecord : [depRecord];

  const relationship = [
    { value: "Brother", label: "Brother" },
    { value: "Cousin", label: "Cousin" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Father in law", label: "Father in law" },
    { value: "Mother", label: "Mother" },
    { value: "Mother in law", label: "Mother in law" },
    { value: "Nephew", label: "Nephew" },
    { value: "Niece", label: "Niece" },
    { value: "Sister", label: "Sister" },
    { value: "Son", label: "Son" },
    { value: "Spouse", label: "Spouse" },
    { value: "Uncle", label: "Uncle" },
    { value: "Granddaughter", label: "Granddaughter" },
    { value: "Grandson", label: "Grandson" },
    { value: "Grandfather", label: "Grandfather" },
    { value: "Grandmother", label: "Grandmother" }
  ];

  if (!Array.isArray(depRecord)) {
    depRecord = [depRecord]; // Convert to array if it's not already
  }


  if (Object.values(depRecord)?.some(value => value !== null && value !== undefined && value !== '')) {
    return (
      <div>
        {Array.isArray(depRecord) && depRecord?.length > 0 ? depRecord?.map((record, index) => (
          <div key={index} className="qualification-form m-t-30">
            <div className="m-b-20 m-t-30">
              <label >Name</label>
              <InputField
                placeholder='Name'
                required
                value={record?.depName}
                onChange={(value) => handleUpdateChange("depName", value, index)}

                disabled={disabled}
              />
            </div>

            <div className="m-b-20">
              <label >Address</label>
              <InputField
                placeholder='Address'
                onChange={(value) => handleUpdateChange("depAddress", value, index)}

                required
                value={record?.depAddress}
                disabled={disabled}
              />
            </div>

            <div className="m-b-20">
              <div className="col-7 no-padding no-margin">
                <label >Relationship</label>
                <InputField
                  placeholder='Select Relationship'
                  onChange={(value) => handleUpdateChange("depRelation", value, index)}

                  required
                  value={record?.depRelation}
                  disabled={disabled}

                />
              </div>
              <div className="col-5 no-p-r no-margin">
                <label >Date Of Birth</label>
                <DatePicker className="input-field p-r-5 p-l-5"
                  placeholderText="Date Of Birth"
                  selected={record?.depDoB ? new Date(record?.depDoB) : null}
                  required
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown={true}
                  onChange={(date) => handleDateChangeUpdate("depDoB", date, index)}
                  scrollableYearDropdown
                  yearDropdownItemNumber={200}
                  value={record?.depDoB}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="m-b-20">
              <label >Phone Number</label>
              <InputField
                placeholder='Phone Number'
                required
                value={record?.depMobile}
                disabled={disabled}
                onChange={(value) => handleUpdateChange("depMobile", value, index)}


              />
            </div>

            {canEdit === true &&
              <div>
                <div className="flex space-between">
                  <button
                    className="btn m-t-20 m-b-20"
                    onClick={(e) => openModal(index, record?.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="edit-btn m-t-20 m-b-20"
                    onClick={(e) => deleteRecord(e, record?.id)}
                  >
                    Remove Dependant
                  </button>
                </div>
              </div>
            }
            <div>
              <UpdateDependant
                isOpen={isModalOpenArray[index]}
                onClose={() => closeModal(index)}
                formState={formStates}
                relationship={relationship}
                isFormValid={isFormValid}
                index={index}
                handleUpdateChange={handleUpdateChange}
                handleDateChangeUpdate={handleDateChangeUpdate}
                updateRecord={updateRecord}
                id={selectedRecordId}
              />
            </div>
          </div>
        ))
          :
          (
            <div key={index} className="qualification-form m-t-30">
              <div className="m-b-20 m-t-30">
                <label >Name</label>
                <InputField
                  placeholder='Name'
                  required
                  value={depRecord?.depName}
                  onChange={(value) => handleUpdateChange("depName", value, index)}

                  disabled={disabled}
                />
              </div>

              <div className="m-b-20">
                <label >Address</label>
                <InputField
                  placeholder='Address'
                  onChange={(value) => handleUpdateChange("depAddress", value, index)}

                  required
                  value={depRecord?.depAddress}
                  disabled={disabled}
                />
              </div>

              <div className="m-b-20">
                <div className="col-7 no-padding no-margin">
                  <label >Relationship</label>
                  <InputField
                    placeholder='Select Relationship'
                    onChange={(value) => handleUpdateChange("depRelation", value, index)}

                    required
                    value={depRecord?.depRelation}
                    disabled={disabled}

                  />
                </div>
                <div className="col-5 no-p-r no-margin">
                  <label >Date Of Birth</label>
                  <DatePicker className="input-field p-r-5 p-l-5"
                    placeholderText="Date Of Birth"
                    selected={depRecord?.depDoB ? new Date(depRecord?.depDoB) : null}
                    required
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown={true}
                    onChange={(date) => handleDateChangeUpdate("depDoB", date, index)}
                    scrollableYearDropdown
                    yearDropdownItemNumber={200}
                    value={depRecord?.depDoB}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="m-b-20">
                <label >Phone Number</label>
                <InputField
                  placeholder='Phone Number'
                  required
                  value={depRecord?.depMobile}
                  disabled={disabled}
                  onChange={(value) => handleUpdateChange("depMobile", value, index)}


                />
              </div>


              <div>
                <UpdateDependant
                  isOpen={isModalOpenArray[index]}
                  onClose={() => closeModal(index)}
                  formState={formStates}
                  relationship={relationship}
                  isFormValid={isFormValid}
                  index={index}
                  handleUpdateChange={handleUpdateChange}
                  handleDateChangeUpdate={handleDateChangeUpdate}
                  updateRecord={updateRecord}
                  id={selectedRecordId}
                />
              </div>
            </div>
          )
        }



      </div>
    )
  } else {
    return null; // Return null if nokRecord doesn't have any non-empty values
  }

};

export default DisplayDependant;
