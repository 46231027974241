import { RiBarChartFill, BiFile, HiOutlineUserAdd, BiEnvelope, BiLogOutCircle, AiOutlineHome, AiFillLock, BiReceipt } from 'react-icons/all';
import Cookies from 'js-cookie';
import { get } from '../utility/fetch';

const SidebarMenu = async () => {
  try {

    const authStatus = Cookies.get('authStatus');

    console.log(authStatus)

    if (authStatus.toLocaleLowerCase() === 'ps-hr') {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'User Permissions', href: '/admin-portal/authorise-user', icon: <AiFillLock className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
            { title: 'Onboarding', href: '/admin-portal/initiate-request', icon: <HiOutlineUserAdd className='icon' />, isVisible: authStatus === 'PS-HR', },
            { title: 'Staff Exit', href: '/admin-portal/staff-exit', isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' }
          ]
        },
        { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    } else if (authStatus.toLocaleLowerCase() === 'hos') {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
            { title: 'Staff Exit', href: '/admin-portal/staff-exit', isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' }
          ]
        },
        { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    } else if (authStatus.toLocaleLowerCase() === 'hoa') {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
            { title: 'Staff Exit', href: '/admin-portal/staff-exit', isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' }
          ]
        },
        { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    } else if (authStatus.toLocaleLowerCase() === `dss`) {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
            { title: 'Staff Exit', href: '/admin-portal/staff-exit', isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' }
          ]
        },
        { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' || authStatus === 'Governor' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    }else if (authStatus.toLocaleLowerCase() === `ps`) {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
            { title: 'Staff Exit', href: '/admin-portal/staff-exit', isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' }
          ]
        },
        { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' || authStatus === 'Governor' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    } else if (authStatus.toLocaleLowerCase() === 'governor') {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
          ]
        },
        { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' || authStatus === 'Governor' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    } else if (authStatus.toLocaleLowerCase() === 'onboarding initiator') {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
            { title: 'Onboarding', href: '/admin-portal/initiate-request', icon: <HiOutlineUserAdd className='icon' />, isVisible: authStatus === 'PS-HR', },
            { title: 'Staff Exit', href: '/admin-portal/staff-exit', isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' }
          ]
        },
        // { title: 'Approve Requests', href: '/admin-portal/review-request', icon: <BiEnvelope className='icon' />, isVisible: authStatus === 'PS-HR' || authStatus === 'DSS' || authStatus === 'HOS' || authStatus === 'Governor' },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    } else {
      return [
        { title: "Home", href: "/admin-portal/home", icon: <AiOutlineHome className='icon' /> },
        { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className='icon' /> },
        { title: 'My Record', href: '/admin-portal/my-records', icon: <BiFile className='icon' /> },
        // { title: 'Reports', href: '/admin-portal/reports', icon: <BiReceipt className='icon' /> },
        {
          title: 'Initiate Request',
          href: '',
          icon: <HiOutlineUserAdd className='icon' />,
          children: [
            { title: 'Data Update', href: '/admin-portal/data-update' },
          ]
        },
        { title: "Logout", href: "/admin-portal/logout", icon: <BiLogOutCircle className='icon' /> }
      ];
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default SidebarMenu;
