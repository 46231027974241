/* eslint-disable */
import React, { useEffect, useState } from "react";

// import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { store } from "react-notifications-component";
import queryString, { parse } from "query-string";
import Cookies from "js-cookie";
import axios from "axios";
// import { location } from '../../utility/Location';
// import moment from "moment";
// import { ModuleName } from "../../utils/globalVariables";
// import { fetchDashboardDetails } from "../../redux/actions/actions";

// import LoadingSpinner from '../../resources/loadingSpinner';

const Connect = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  // const dispatch = useDispatch();

  const checkurl = () => {
    const parsed = queryString.parse(location.search);

    //this change with respect to each module
    //Edogov_(enter module name);

    //check if there is a jwt token saved before
    // const AccessToken = Cookies.get(ModuleName + "_AccessToken");

    //check if there is an app token saved before
    // const AppToken = Cookies.get(ModuleName + "_AppToken");

    //get the base url from query string(edogoverp.com)
    const baseurl = parsed.base;
    const fromdashboard = parsed.fromdashboard;
    const requestid = parsed.requestid;

    Cookies.set("baseUrl", baseurl);

    // get the help Link
    Cookies.set("helpLink", parsed.help + "#page=63");
    console.log("parsed: ", parsed);

    if (parsed.emp !== "") {
      setLoading(true);

      const authurl = "/onboardingapi/api/authorization"; //change this to the module authenthication endpoint

      axios
        .post(`${baseurl}${authurl}?urltoken=${parsed.emp}`, {
          headers: {
            "Access-Control-Allow-Credentials": true,
            crossorigin: true,
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Origin": "*",
          },
        })

        .then((user_auth) => {
          console.log("user_auth: ", user_auth);
          // Cookies.set(ModuleName + "_UserId", user_auth.data.userID);
          Cookies.set("userId", user_auth.data.id);
          Cookies.set("authStatus", user_auth?.data?.moduleRole);
          localStorage.setItem("userId", user_auth.data.userID);
          Cookies.set("isSupervisor", user_auth.data.isSupervisor);
          Cookies.set("_Role", user_auth.data.role);
          Cookies.set("_Email", user_auth.data.email);
          Cookies.set("profile", user_auth.data.profile);
          Cookies.set("fromdashboard", fromdashboard);
          Cookies.set("requestid", requestid);
          
          //Cookies.set(ModuleName + "_FirstName", user_auth.data.firstName);
          Cookies.set("_FullName", user_auth.data.name);
          Cookies.set("firstName", user_auth.data.firstName);
          Cookies.set("lastName", user_auth.data.lastName);
          Cookies.set(
            "fullName",
            user_auth.data.firstName + " " + user_auth.data.lastName
          );
          Cookies.set("profilePicture", user_auth.data.profilePicture);
          Cookies.set("mdaMandate", user_auth.data.mdA_Mandate);
          Cookies.set("signature", user_auth.data.signature);
          Cookies.set("isAdmin", user_auth.data.isAdmin);
          // Cookies.set("loginTime", moment().format("HH:mm a"));
          // Cookies.set(ModuleName + "_LastName", user_auth.data.lastName);
          // Cookies.set(ModuleName + "_AppToken", user_auth.data.token, {
          //   expires: 0.0416665,
          // });
          Cookies.set("token", user_auth?.data?.token);
          Cookies.set("homeurl", user_auth?.data?.homeurl); //for home redirection
          // Cookies.set(ModuleName + "_AccessToken", parsed.emp);
          Cookies.set("_APIBaseURL", parsed.base);
          Cookies.set("tokenExist", true, { expires: 0.0416665 });

          if (user_auth?.data?.token) {
            setLoading(true);
            console.log(fromdashboard);

            if (fromdashboard === "true") {
              history.push("/admin-portal/review-request");
            }

            //redirect to dashboard
            else history.push("/admin-portal/");
          }
          // } else {
          //   store.addNotification({
          //     title: "Error!",
          //     message: user_auth.message,
          //     type: "danger",
          //     insert: "bottom",
          //     container: "bottom-left",
          //     animationIn: ["animate__animated", "animate__fadeIn"],
          //     animationOut: ["animate__animated", "animate__fadeOut"],
          //     dismiss: {
          //       duration: 5000,
          //       onScreen: true,
          //     },
          //   });
          // }

          // dispatch(fetchDashboardDetails());
        })

        .catch((error) => {
          console.log(error);
          Cookies.set("_AppToken", null);
          Cookies.set("tokenExist", false);
          Cookies.set("_FullName", null);
          Cookies.set("_Role", null);
          Cookies.set("_Email", null);

          // store.addNotification({
          //   title: "Error!",
          //   message: "You Do Not Have Access To this Application!",
          //   type: "danger",
          //   insert: "bottom",
          //   container: "bottom-left",
          //   animationIn: ["animate__animated", "animate__fadeIn"],
          //   animationOut: ["animate__animated", "animate__fadeOut"],
          //   dismiss: {
          //     duration: 5000,
          //     onScreen: true,
          //   },
          // });
        });
    }
  };

  useEffect(() => {
    checkurl();
  }, []);

  return (
    <div
      className="w-100 flex flex-v-center flex-h-center"
      style={{ minHeight: "105vh" }}
    >
      {loading ? "Loading..." : ""}
    </div>
  );
};

export default Connect;
