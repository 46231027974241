import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ selectedDate, onChange, ...rest }) => {
  const [isValid, setIsValid] = useState(true);

  const handleBlur = () => {
    const isInputValid = selectedDate !== null;
    setIsValid(isInputValid);
  };

  return (
    <div className={`react-datepicker-wrapper ${!isValid ? 'invalid' : ''}`}>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        onBlur={handleBlur}
        {...rest}
      />
    </div>
  );
};

export default CustomDatePicker;
