const NameDeletion = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Reason for deletion (Death, Retirement, New job)</p>
                    <p>2: Oracle No</p>
                    <p>3: Names to be deleted</p>
                    </div>
                    
                </div>
    )
}

export default NameDeletion;