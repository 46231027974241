import { createContext, useState } from "react";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [exitToBeViewed, setExitToBeViewed] = useState({});
  const [updateToBeViewed, setUpdateToBeViewed] = useState({});
  const [onboardToBeViewed, setOnboardToBeViewed] = useState({});
  const [userRin, setUserRin] = useState(Number)

  const [myRecord, setMyRecord] = useState(false)

  const  [sentBack, setSentBack] = useState(false)



  return (
    <MyContext.Provider value={{ exitToBeViewed, setExitToBeViewed, userRin, setUserRin ,myRecord, setMyRecord, sentBack, setSentBack, updateToBeViewed, setUpdateToBeViewed, onboardToBeViewed, setOnboardToBeViewed}}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
