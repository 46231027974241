/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Select from 'react-select';

const SelectInput = (props) => {
  // Map options to format required by react-select
  const options = props.options && props.options.map((option, index) => {
    if (typeof option === 'object') {
      return {
        label: option.name || option.label || option.structureType || `${option.level} - ${option.step} (${option.type})`,
        value:  option.id ? option.id : index + 1,
        ...(option.fileurl && { fileurl: option.fileurl }),
      };
    } else {
      return {
        label: option,
        value: option,
      };
    }
  });

  console.log(props.options, props.value)
  return (
    <div className= "dropdown-input">
      <label htmlFor={props.name}>
        {props.label}
      </label>
      <Select
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        isSearchable={true}
        onBlur={props.onBlur}
        onInputChange={props?.onInputChange}
        disabled={props.disabled} 
        options={options}
      />
    </div>
  );
};

export default SelectInput;
