import React, { useState } from 'react';
import DisplayUpdateDetails from '../ReviewRecordsDetails/DisplayUpdate';

function UpdateRequestDetails({userId, recordToBeReviewed,staffId,userDependants,userEducation,userNextOfKins,userProfessional,userProfile, userRecord, employeeRIN, updateType }) {
  const [isContentVisible, setContentVisible] = useState(false);

  console.log('ShowEmployeeDetails', recordToBeReviewed)
  

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  return (
    <div>
      <div className="collapse-banner m-t-20 m-b-10 flex flex-v-center space-between">
        <div className="bold-text">Show Employee Details</div>
        <div>
          <button className="collapse-btn" onClick={toggleContent}>
            {isContentVisible ? 'Collapse' : 'Expand'}
          </button>
        </div>
      </div>
        {isContentVisible && (
          <div className="reviewer-card p-20 m-b-20 ">
            {recordToBeReviewed !== undefined && (
              <DisplayUpdateDetails
              staffId={staffId}
              userProfile={userProfile}
              userDependants={userDependants}
              userEducation={userEducation}
              userProfessional={userProfessional}
              userNextOfKins={userNextOfKins}
              userId={userId}
              recordToBeReviewed={recordToBeReviewed}
              userRecord ={userRecord}
              employeeRIN = {employeeRIN}
              updateType = {updateType}

          />
            )}
          </div>
        )}
    </div>
  );
}

export default UpdateRequestDetails;
