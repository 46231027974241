import React, { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import TextAreaInputField from "../inputs/TextAreaInputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../utility/fetch";
import ProfForm from "./ProfForm";
import { feedback } from "../modal/feedback";
import ProfRecordDisplay from "./ProfRecordDisplay";


const ProfessionalQualification = (props) => {
  const id = props?.ID?.toString()
  const initialFormData = {
    certType: "",
    certObtained: "",
    certNumber: "",
    certExpirationDate: "",
    certYear: "",
    remarks: "Nil",
  }
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [year, setYear] = useState(null)
  const [professionalRecord, setProfessionalRecord] = useState([])
  const [reset, setReset] = useState()


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
    if (props?.review === false) {
      fetchProfRecord(id)

    } else {
      fetchProfRecord(props?.staffId)
    }
  }, []);

  const postQualifications = async (data) => {

    let payload
    if (props?.review === true) {
      payload = {
        ...data,
        staffID: props?.staffId,
      }
    } else {
      payload = data
    }

    try {
      const response = await post('profcerts', payload);
      console.log('Next of kin created successfully:', response);
      const id = response?.data?.staffID ? response.data.staffID : response?.data?.id
      console.log('staff', id)

      if (response?.code === 1 && props?.review === false) {
        fetchProfRecord(id)
          .then(setProfessionalRecord(response))
      } else {
        fetchProfRecord(props?.staffId)
          .then(setProfessionalRecord(response))
      }

      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
      setFormData(initialFormData)
    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('failed to update perfonal info', response)
    }
  }

  const fetchProfRecord = async (id) => {
    try {
      if (props?.review === true && props?.data && (Array.isArray(props.data) && props.data.length > 0) || (typeof props.data === 'object' && Object.keys(props.data).length > 0)) {
        setProfessionalRecord(props?.data)
        return
      }
      // Make an API call to fetch educational records based on the userId
      const response = await get(`profcerts/${id}`);
      const Record = response;
      console.log('data', Record)


      if (Record) {
        setProfessionalRecord(Record);
      }
    } catch (response) {

      console.error('Error fetching educational records:', response);
      // Handle the error as needed
    }
  };


  const handleInputChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "phoneNumber") {
      // Validate if the value is a number and has a length of 11
      if (/^\d{11}$/.test(selectedValue)) {
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
      } else {
        // Display an error or handle the invalid input accordingly
        console.error("Invalid input for phone number");
      }
    } else {
      // For other fields, proceed without validation
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue === "" ? "" : selectedValue,
        staffID: id
      }));
    }
  };




  const isFormValid = () => {
    const missingFields = [];

    // Check if all required fields are filled
    Object.entries(formData).forEach(([fieldName, value]) => {
      // Exclude middleName and mothersMaidenName fields
      if (fieldName === "remarks" || fieldName === "certExpirationDate") {
        return; // Skip to the next iteration
      }

      if (value === "" || value === null || value === undefined) {
        missingFields.push(fieldName);
      } else if (fieldName === "nin" || fieldName === "mobile") {
        // Validate nin and mobile fields with exactly 11 digits
        if (!/^\d{11}$/.test(value)) {
          missingFields.push(fieldName);
        }
      }
    });

    console.log("Missing fields:", missingFields);

    return { isValid: missingFields.length === 0, missingFields };
  }

  const handleAddQualification = () => {
    setFormData([...formData, { certType: "", certObtained: "", certNumber: "", certExpirationDate: "", certYear: "", remarks: "" }]);
  };

  const handleRemoveQualification = (index) => {
    const updatedQualifications = [...formData];
    updatedQualifications.splice(index, 1);
    setFormData(updatedQualifications);
  };


  const handleDateChange = (date) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setFormData((prevData) => ({
      ...prevData,
      certExpirationDate: formattedDateWithoutTimestamp,
    }));

    setSelectedDate(date);

    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };

  const handleDateYearChange = (date) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).getFullYear().toString()
      : "";

    setFormData((prevData) => ({
      ...prevData,
      certYear: formattedDateWithoutTimestamp,
    }));

    setYear(date)
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    const { isValid, missingFields } = isFormValid();
    if (isValid) {

      props?.handleFormData(formData);
      postQualifications(formData);
      console.log("Form submitted:", formData);
      setFormData(initialFormData);
    } else {
      const missingFieldsMessage = `Please fill in the following fields: ${missingFields.join(", ")}`;
      feedback({
        title: "Form submission failed",
        text: missingFieldsMessage,
        iconType: "warning",
      });
      console.log("Please fill in all required fields before submitting.");
    }
  };




  return (

    <div className="form-step" data-step="5">


      {props?.review !== true && props?.review !== undefined &&
        <div>
          <ProfForm
            // key={index}
            // index={index}
            handleDateChange={handleDateChange}
            handleDateYearChange={handleDateYearChange}
            selectedDate={selectedDate}
            year={year}
            handleInputChange={handleInputChange}
            handleRemoveQualification={handleRemoveQualification}
          // qualification={qualification}
          />
          {/* ))} */}
          <p className="m-t-20 search-text">Please clear input after adding Professional Qualification and enter new values to add another qualification</p>
          <div>
            <button onClick={handleSubmit} className="submit-btn m-b-20 m-t-20">Add Professional Qualification</button>
          </div>

          <div className="m-t-30">

            <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Professional Certifications</h3>
            <ProfRecordDisplay
              professionalRecord={professionalRecord}
              setProfessionalRecord={setProfessionalRecord}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}
            />
          </div>
        </div>
      }

      {props?.review === true && props?.canEdit === true &&
        <div>
          <div className="m-t-30 m-b-60">

            <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Professional Certifications</h3>
            <ProfRecordDisplay
              professionalRecord={professionalRecord}
              setProfessionalRecord={setProfessionalRecord}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}
              disabled={true}
            />
          </div>

          <ProfForm
            // key={index}
            setReset={setReset}
            handleDateChange={handleDateChange}
            handleDateYearChange={handleDateYearChange}
            selectedDate={selectedDate}
            year={year}
            handleInputChange={handleInputChange}
            handleRemoveQualification={handleRemoveQualification}
          // qualification={qualification}
          />
          {/* ))} */}
          <p className="m-t-20 search-text">Please clear input after adding Professional Qualification and enter new values to add another qualification</p>
          <div>
            <button onClick={handleSubmit} className="submit-btn m-b-20 m-t-20">Add Professional Qualification</button>
          </div>


        </div>
      }

      {props?.review === true && props?.canEdit !== true &&
        <div>
          <div className="m-t-30">

            <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Professional Certifications</h3>
            <ProfRecordDisplay
              professionalRecord={professionalRecord}
              setProfessionalRecord={setProfessionalRecord}
              review={props?.review}
              disabled={props?.disabled}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}

            />
          </div>
        </div>
      }

      {props?.download !== true &&
        <div className="flex space-between">
          <button className="col-5 submit-btn prev-step" onClick={props?.handleBack}>Previous</button>
          <button className="col-5  submit-btn next-step" onClick={props?.handleNext}>Next</button>
        </div>

      }
    </div>
  )
}

export default ProfessionalQualification;