/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";

// import Loader from "react-loader-spinner";
// import Moment from "react-moment";
import moment from "moment";
import ProfilePix from "../../assets/images/profile-pix.jpg";
import { get, post } from "../../utility/fetch";
import { getMfile } from "../../utility/globalfetch"

import DatePicker from "react-datepicker";
import TextAreaInputField from "../inputs/TextAreaInputField";

import axios from "axios";
import Cookies from "js-cookie";
import { feedback } from "../modal/feedback";
import OfficialDetails from "../layouts/profileDetails/OfficialDetails";
import PersonalDetails from "../layouts/profileDetails/PersonalDetails";
import EducationDetails from "../layouts/profileDetails/EducationDetails";
import ProfessionalDetails from "../layouts/profileDetails/ProfessionalDetails";
import DependantDetails from "../layouts/profileDetails/DependantDetails";
import NextOfKinsDetails from "../layouts/profileDetails/NextOfKinsDetails";
// import { formatFileUrl } from "../../utility/general";

// import { decodeToken } from "../../utility/auth";

// import notification from "../../utility/notification";
// import IsLoading from '../common/IsLoading';

// import LocationDetails from '../layouts/LocationDetails';

const ExitRequestDisplay = ({ userId, type, recordToBeReviewed, userEducation, userDependants, user, userNextOfKins, userProfessional,userProfile, currentCadre, firstAppCadre }) => {
  const [currentForm, setCurrentForm] = useState("official-details");
  
  const [recipientMda, setrecipientMda] = useState([])
  const [toName, setToName] = useState([])
  const [approvers, setApprovers] = useState([])
  const [thoroughMda, setThroughMda] = useState([])
  const [throughName, setThroughName] = useState([])
  const [through, setThrough] = useState([])
  const [comment, setComment] = useState('')
  
 
  const userID = Cookies.get("userId");

  useEffect(() => {
    setApprovers(
      toName?.map((item) => ({
        userId: Number(item?.value),
        mdaId: recipientMda?.value,
        name: item?.label
      }))
    );
  }, [toName]);

  useEffect(() => {
    setThrough(
      throughName?.map((item) => ({
        userId: Number(item?.value),
        mdaId: thoroughMda?.value,
        name: item?.label
      }))
    );
  }, [throughName]);


  const addDefaultSrc = (ev) => {
    ev.target.src = ProfilePix;
  };

  console.log(throughName)
  console.log('comment:', comment, toName)

  console.log(userId)

  console.log('PROFILE', userProfile?.currentCadre, currentCadre, firstAppCadre, userDependants[0], userEducation[0], userNextOfKins, userProfessional)

  function calculateDuration(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    const years = end.diff(start, 'years');
    start.add(years, 'years');
    const months = end.diff(start, 'months');
    start.add(months, 'months');
    const days = end.diff(start, 'days');

    return {
      years,
      months,
      days
    };
  }

  const currentDate = moment().format('YYYY-MM-DD')
  const duration = calculateDuration(userProfile?.dateOfFirstApp, currentDate);

  console.log('hey', duration, currentDate)

  return (
    <div className="w-100 p-r m-t-20 m-l-20">



      
          <div className="w-100 flex space-between flex-v-center">
            <h2 className="search-text">Staff Exit</h2>
          </div>

          <div className="col-4 m-l-10 m-t-20 ">
            <label>Exit Reason</label>
            <TextAreaInputField
              value={recordToBeReviewed?.reason}
              placeholder='Exit Reason'
              disabled

            />
          </div>
          <div className="w-100 m-t-20 user-full-details">
            <div className="tab-btns w-100">
              <button
                type="button"
                onClick={() => setCurrentForm("official-details")}
                className={currentForm === "official-details" ? "active" : ""}
              >
                Official Details
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("personal")}
                className={currentForm === "personal" ? "active" : ""}
              >
                Personal
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("professional")}
                className={currentForm === "professional" ? "active" : ""}
              >
                Professional
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("education")}
                className={currentForm === "education" ? "active" : ""}
              >
                Education
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("dependant")}
                className={currentForm === "dependant" ? "active" : ""}
              >
                Dependants
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("nextofkin")}
                className={currentForm === "nextofkin" ? "active" : ""}
              >
                Next Of Kin
              </button>
            </div>
            
              <div className="w-100 flex">
                <div className="left">
                  <div className="content w-100">
                    {currentForm === "official-details" && (
                      <OfficialDetails data={userProfile} currentCadre = {currentCadre || userProfile?.currentCadre} firstAppCadre = {firstAppCadre || userProfile?.currentCadre} />
                    )}
                    {currentForm === "personal" && (
                      <PersonalDetails data={userProfile} />
                    )}
                    {currentForm === "education" && (
                      <EducationDetails data={userEducation} />
                    )}
                    {currentForm === "professional" && (
                      <ProfessionalDetails data={userProfessional} />
                    )}
                    {currentForm === "dependant" && (
                      <DependantDetails data={userDependants} />
                    )}
                    {currentForm === "nextofkin" && (
                      <NextOfKinsDetails data={userNextOfKins} />
                    )}
                  </div>
                  <div className="col-8">


                    <div className="m-l-10 m-t-20">
                      <label>Comment</label>
                      <TextAreaInputField
                        placeholder='Details:'
                        value={recordToBeReviewed?.comment}
                        disabled
                      />
                    </div>



                  </div>
                </div>
                <div className="right">
                  <div className="w-100 m-t-40 center-text profile-brief">
                    <img
                      onError={addDefaultSrc}
                      src={
                        (userProfile && userProfile?.profilePicture) ||
                        ProfilePix
                      }
                      alt={userProfile && userProfile?.firstName}
                    />
                    <div className="brief m-t-20">
                      <h4>
                        {`${(userProfile && userProfile?.firstName) || ""} ${(userProfile && userProfile?.lastName) || ""
                          }`}
                      </h4>
                      <hr />
                      <p className="  m-b-10">
                        <b className=" m-b-10">Engagement Date: </b>
                        <div className=" m-t-10">
                          {userProfile?.dateOfFirstApp}

                        </div>
                      </p>
                      <p className="  m-b-10">
                        <b>Duration on Grade: </b>
                        <div className=" m-t-10">
                          {duration?.years} years, {duration?.months} months, {duration?.days} day(s)

                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
    </div>
  );
};

export default ExitRequestDisplay;
