import { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { post, get } from "../../utility/fetch";
import EducationForm from "./EducationForm";
import { feedback } from "../modal/feedback";
import EducationalRecordDisplay from "./EducationalRecordDisplay";

const EducationalQualifications = (props) => {
  const id = props?.ID?.toString()

  const initialFormData = {
    schoolName: "",
    course: "",
    certificate: "",
    graduateYear: "",
    classOfDegree: "",
    remarks: "Nil",
    staffID: id
  }
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [clearForm, setClearForm] = useState(false);
  const [recordId, setRecordId] = useState('')
  const [FetchEducationalRecords, setFetchEducationalRecords] = useState([])
  const [educationalRecord, setEducationalRecord] = useState([]);
  const [formDataEdu, setFormDataEdu] = useState([])



  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
    setClearForm(true);
    if (props?.review === false) {
      fetchEducationalRecord(id)

    } else {
      fetchEducationalRecord(props?.staffId)
    }

  }, [id,props?.staffId ]);



  const handleAddQualification = () => {
    setFormData([...formData, { schoolName: "", course: "", certificate: "", graduateYear: "", classOfDegree: "", remarks: "" }]);
  };

  const handleRemoveQualification = (index) => {
    const updatedQualifications = [...formData];
    updatedQualifications.splice(index, 1);
    setFormData(updatedQualifications);
  };


  const postEducation = async (data) => {
    let payload
    if (props?.review === true) {
      payload = {
        ...data,
        staffID: props?.staffId,
      }
    } else {
      payload = data
    }
    try {
      const response = await post('education', payload);
      console.log('edu created successfully:', response?.data?.data);
      const id = response?.data?.staffID
      console.log('staff', id)
      if (response?.code === 1 && props?.review === false) {
        await fetchEducationalRecord(id)
      } else {
        fetchEducationalRecord(props?.staffId)
         
      }


      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('Error creating edu:', response);
    }
  }


  console.log(id, props?.staffId, props?.review, props?.data)
  const handleInputChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "phoneNumber") {

      // Validate if the value is a number and has a length of 11
      if (/^\d{11}$/.test(selectedValue)) {
        setFormData((prevData) => ({
          ...prevData,
          [field]: selectedValue,
          staffID: id,
        }));
      } else {
        // Display an error or handle the invalid input accordingly
        console.error("Invalid input for phone number");
      }
    } else {
      // For other fields, proceed without validation
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue === "" ? "" : selectedValue,
        staffID: id,
      }));
    }
  };



  const fetchEducationalRecord = async (id) => {
    if (props?.review === true && props?.data && (Array.isArray(props.data) && props.data.length > 0) || (typeof props.data === 'object' && Object.keys(props.data).length > 0)) {
      setEducationalRecord(props?.data);
      return;
  }
  
    try {
      // Make an API call to fetch educational records based on the userId
      const response = await get(`education/${id}`);
      const educationRecord = response;
      console.log('data', educationRecord)


      if (educationRecord && props?.review === false) {
        setEducationalRecord(educationRecord);
      }else{
        setEducationalRecord(response)
      }
    } catch (response) {

      console.error('Error fetching educational records:', response);
      // Handle the error as needed
    }
  };



  const isFormValid = () => {
    const missingFields = [];

    // Check if all required fields are filled
    Object.entries(formData).forEach(([fieldName, value]) => {
      // Exclude middleName and mothersMaidenName fields
      if (fieldName === 'classOfDegree' || fieldName === 'remarks') {
        return; // Skip to the next iteration
      }

      if (value === "" || value === null || value === undefined) {
        missingFields.push(fieldName);
      } else if (fieldName === "nin" || fieldName === "mobile") {
        // Validate nin and mobile fields with exactly 11 digits
        if (!/^\d{11}$/.test(value)) {
          missingFields.push(fieldName);
        }
      }
    });

    console.log("Missing fields:", missingFields);

    return { isValid: missingFields.length === 0, missingFields };
  }



  const handleSubmit = (e) => {
    e.preventDefault();

    const { isValid, missingFields } = isFormValid();

    if (isValid) {

      props.handleFormData(formData);
      postEducation(formData);
      console.log("Form submitted:", formData);
      console.log(props.handleFormData(formData));
      setFormData(initialFormData);
      setClearForm(false);
    } else {
      const missingFieldsMessage = `Please fill in the following fields: ${missingFields.join(", ")}`;
      feedback({
        title: "Form submission failed",
        text: missingFieldsMessage,
        iconType: "warning",
      });
      setClearForm(true);
      console.log("Please fill in all required fields before submitting.");
    }
  };


  const Reset = (form) => {
    document.querySelector(`${form}`).reset()
    console.log('reset')
  }


  const handleDateChange = (date) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).getFullYear().toString()
      : "";

    setFormData((prevData) => ({
      ...prevData,
      graduateYear: formattedDateWithoutTimestamp,
    }));

    setSelectedDate(date);
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };


  return (
    <div className="form-step" data-step="4">
      {props?.review !== true && props?.review !== undefined &&
        <div>
         <div>
           <EducationForm
            // key={index}
            // index={index}
            Reset={Reset}
            clearForm={clearForm}
            handleDateChange={handleDateChange}
            selectedDate={selectedDate}
            handleInputChange={handleInputChange}
            handleRemoveQualification={handleRemoveQualification}
            initialFormData={initialFormData}
          // qualification={qualification}
          />

          <p className="m-t-20 search-text">Please clear input after adding Educational Qualification and enter new values to add another qualification</p>
          <div>
            <button onClick={handleSubmit} className="submit-btn m-b-20 m-t-20">Add Educational Qualification</button>
          </div>
         </div>

          <div className="m-t-30">
          <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Educational Certifications</h3>
            <EducationalRecordDisplay
              educationRecord={educationalRecord}
              setEducationalRecord={setEducationalRecord}
              disabled={props?.disabled}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}

            />
          </div>
        </div>
      }

      {props?.review === true && props?.canEdit === true &&
        < div >
          <div className="m-t-30 m-b-60">
          <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Educational Certifications</h3>
              <EducationalRecordDisplay
                educationRecord={educationalRecord}
                setEducationalRecord={setEducationalRecord}
                disabled={true}
                canEdit={props?.canEdit}
                handleFormData={props?.handleFormData}

              />
            </div>
            
            <EducationForm
              // key={index}
              // index={index}
              Reset={Reset}
              clearForm={clearForm}
              handleDateChange={handleDateChange}
              selectedDate={selectedDate}
              handleInputChange={handleInputChange}
              handleRemoveQualification={handleRemoveQualification}
              initialFormData={initialFormData}
            // qualification={qualification}
            />
            <p className="m-t-20 search-text">Please clear input after adding Educational Qualification and enter new values to add another qualification</p>
            <div>
              <button onClick={handleSubmit} className="submit-btn m-b-20 m-t-20">Add Educational Qualification</button>
            </div>

            <div className="m-t-30">

            
          </div>

        </div>
      }


      {
        props?.review === true && props?.canEdit !== true &&
        <div>
          <div className="m-t-30">

          <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Educational Certifications</h3>
            <EducationalRecordDisplay
              educationRecord={educationalRecord}
              setEducationalRecord={setEducationalRecord}
              review={props?.review}
              disabled={props?.disabled}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}

            />
          </div>
        </div>
      }
      
      {props?.download !== true &&
        <div className="flex space-between">
          <button className="col-5 submit-btn prev-step" onClick={props.handleBack}>Previous</button>
          <button className="col-5  submit-btn next-step" onClick={props.handleNext}>Next</button>
        </div>

      }


    </div >
  )
}

export default EducationalQualifications;