const UnratifiedPromotionalChange = ()=>{
    return (
        <div className="flex space-between">
                    <div className="col-5">
                    <p>1: Promotion letter</p>
                    <p>2: Appointment letter</p>
                    <p>3: Change of name and Certificate</p>
                    </div>
                    <div className="col-7">
                    <p>4: Biometric</p>
                    <p>5: Intercadre</p>
                    </div>
                </div>
    )
}

export default UnratifiedPromotionalChange;