import React, { useContext, useEffect, useState } from "react";
import DataUpdate from "../layouts/DataUpdate";
import OnboardingForm from "../forms/OnboardingForm";

import { get } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import Dropdown from "../inputs/Dropdown";
import InputField from "../inputs/InputField";
import InitiateOnboarding from "../layouts/InitiateOnboarding";
import InitiateExit from "../layouts/InitiateExit";
import MyContext from "../../utility/Context";

const InitiateRequest = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [userID, setUserID] = useState('')
  const [user, setUser] = useState({})
  const [requestType, setRequestType] = useState('')

  const RequestType = [
    { value: "Onboarding", label: "Onboarding" },
    { value: "Exit", label: "Exit Request" },
    { value: "Data Update", label: "Data Update Request" },
  ]

  const { setMyRecord, setUpdateToBeViewed } = useContext(MyContext);
  const toggleAnimation = () => {
    setIsAnimating(!isAnimating);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
    // fetchUserData();
    setMyRecord(false)
    setUpdateToBeViewed({})
    
  }, [userID]);


  const getID = (id) => {
    setUserID(id)
  }

  console.log('userID in initate', userID,)

  const fetchUserData = async () => {
    try {
      const response = await get(`bio/${userID}`)
      setUser(response?.data || {})
    } catch (error) {

    }
  }

  const handleRequestChange = (field, value) => {
    const selectedValue = value && value?.target ? value?.target?.value : value;
    if (field === "dropDown") {
      setRequestType(selectedValue)
      console.log('state of DropDown', selectedValue)
    }

  }

  const date = user && user?.createdAt
  const parsedDate = new Date(date)
  const formattedDateWithoutTimestamp = parsedDate.toLocaleDateString();

  console.log('user in initate', user)

  return (
    <div className="w-100 flex space-around">
      <div className="col-7">
        <OnboardingForm
          getID={getID}
        />

        <br />
      </div>
    </div>
  );
};

export default InitiateRequest;
