import { useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { get, put, del } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import EducationRecordForm from "./EducationRecordForm";


const EducationalRecordDisplay = ({ userId, setEducationalRecord, educationRecord, index, disabled, review, handleFormData, canEdit }) => {
  const [updateForm, setUpdateForm] = useState((Array.isArray(educationRecord) && educationRecord[index]) || {});
  const [selectedDate, setSelectedDate] = useState(null)
  const [formStates, setFormStates] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const [isModalOpenArray, setIsModalOpenArray] = useState(Array(Array.isArray(educationRecord) && educationRecord?.length).fill(false));



  const certificates = [
    { value: "FSLC", label: "FSLC" },
    { value: "WAEC", label: "WAEC" },
    { value: "NECO", label: "NECO" },
    { value: "GCE", label: "GCE" },
    { value: "NABTEB", label: "NABTEB" },
    { value: "OND", label: "OND" },
    { value: "HND", label: "HND" },
    { value: "BSC", label: "BSC" },
    { value: "BENG", label: "BENG" },
    { value: "BA", label: "BA" },
    { value: "BL", label: "BL" },
    { value: "MBA", label: "MBA" },
    { value: "MEND", label: "MEND" },
    { value: "MED", label: "MED" },
    { value: "TCII", label: "TCII" },
    { value: "LLB", label: "LLB" },
    { value: "LLM", label: "LLM" },
    { value: "MSC", label: "MSC" },
    { value: "PGD", label: "PGD" },
    { value: "PHD", label: "PHD" },
    { value: "NCE", label: "NCE" },
    { value: "B.ED", label: "B.ED" },
    { value: "DIPLOMA", label: "DIPLOMA" },
    { value: "DBA", label: "DBA" },
    { value: "DVM", label: "DVM" },
    { value: "MBBS", label: "MBBS" },
    { value: "OD", label: "OD" },
    { value: "BDS", label: "BDS" }
  ];

  const fieldConfigurations = [
    { type: "input", placeholder: "Name Of School", required: true, key: "schoolName" },
    { type: "input", placeholder: "Course Of Study", required: true, key: "course" },
    { type: "dropdown", placeholder: "Select Certificate Obtained", required: true, key: "certificate", options: certificates },
    // Add more field configurations as needed
  ];

  const degrees = [
    { value: "First Class", label: "First Class Honours" },
    { value: "Second Class", label: "Second Class Honours" },
    { value: "Third Class", label: "Third Class Honours" },
    { value: "Pass ", label: "Pass" },
  ]

  // Fetch educational records based on userId

  useEffect(() => {
    setFormStates(
      Array.isArray(educationRecord) && educationRecord?.map((record) => ({
        updateForm: { ...record },
        selectedDate: record.graduateYear ? new Date(record.graduateYear) : null,
        isEditing: true,

      }))
    );
    console.log(selectedRecordId)
  }, [educationRecord, selectedRecordId]);

  const toggleEdit = (e, recordIndex) => {
    e.preventDefault();

    setUpdateForm(educationRecord[recordIndex]); // Set updateForm to the selected record


    setFormStates((prevFormStates) => {
      const updatedFormStates = [...prevFormStates];
      updatedFormStates[recordIndex].isEditing = !updatedFormStates[recordIndex].isEditing;
      return updatedFormStates;
    });


  };

  const openModal = (recordIndex, id) => {
    setSelectedRecordId(id);

    const updatedArray = [...isModalOpenArray];
    updatedArray[recordIndex] = true;
    setIsModalOpenArray(updatedArray);
  };

  const closeModal = (recordIndex) => {
    const updatedArray = [...isModalOpenArray];
    updatedArray[recordIndex] = false;
    setIsModalOpenArray(updatedArray);
  };



  const updateEduRecord = async (e, id, recordIndex) => {
    e.preventDefault()
    console.log('update id', id)


    try {
      const response = await put(`education/${id}`, formStates[recordIndex].updateForm)
      const updatedRecord = response?.data
      setEducationalRecord((prevRecords) => {
        const updatedRecords = prevRecords.map((record, index) =>
          index === recordIndex ? updatedRecord : record
        );
        return updatedRecords;
      });
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      })
      closeModal(recordIndex)

    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
    }
  }

  const deleteEduRecord = async (e, id) => {
    e.preventDefault()
    console.log('del id', id)
    try {

      const response = await del(`education/${id}`)
      setEducationalRecord((prevRecords) =>
        prevRecords.filter((record) => record.id !== id)
      );
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });

    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
    }
  }

  

  console.log("educationalRecord ", educationRecord)

  
  console.log("selectedDate:", selectedDate);

  console.log("updateForm:", updateForm);


  const handleUpdateChange = (field, value, recordIndex) => {
    const selectedValue = value && value.target ? value.target.value : value;
  
    const updatedForm = {
      ...formStates[recordIndex].updateForm,
      [field]: selectedValue,
    };
  
    setUpdateForm(updatedForm);
  
    setFormStates((prevFormStates) => {
      const updatedFormStates = [...prevFormStates];
      updatedFormStates[recordIndex].updateForm = updatedForm;
      return updatedFormStates;
    });
  
    handleFormData(updatedForm);
  };
  
  const handleDateChangeUpdate = (field, date, recordIndex) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).getFullYear().toString()
      : "";
  
    const updatedForm = {
      ...formStates[recordIndex].updateForm,
      [field]: formattedDateWithoutTimestamp,
    };
  
    setUpdateForm((prevData) => ({
      ...prevData,
      [field]: formattedDateWithoutTimestamp,
    }));
  
    setFormStates((prevFormStates) => {
      const updatedFormStates = [...prevFormStates];
      updatedFormStates[recordIndex].selectedDate = date;
      updatedFormStates[recordIndex].updateForm = updatedForm;
      return updatedFormStates;
    });
  
    handleFormData(updatedForm);
  };
  
  const educationRecordsArray = Array.isArray(educationRecord) ? educationRecord : [educationRecord];



  return (
    <div>
      {Array.isArray(educationRecord) && educationRecord?.map((record, index) => (
        <div key={index} className="qualification-form m-t-30">
          <div className="m-b-10">
            <label >Name Of School</label>
            <InputField
              placeholder="Name Of School"
              onChange={(value) => handleUpdateChange("schoolName", value, index)}
              value={record.schoolName}
              disabled = {disabled}

            />
          </div>

          <div className="m-b-10">
            <label >Course Of Study</label>
            <InputField
              placeholder="Course Of Study"
              onChange={(value) => handleUpdateChange("course", value, index)}
              value={record.course}
              disabled = {disabled}

            />
          </div>

          <div className="m-b-10">
            <div className="col-7 no-padding no-margin">
              <label >Certificate Obtained</label>
              <InputField
                placeholder="Select Certificate Obtained"
                onChange={(value) => handleUpdateChange("certificate", value, index)}
                value={record.certificate}
                disabled = {disabled}

              />
            </div>
            <div className="col-5 no-p-r no-margin">
              <label >Year of Graduation</label>
              <DatePicker
                className="input-field p-r-5 p-l-5"
                placeholderText="Year of graduation"
                selected={record.graduateYear ? new Date(record.graduateYear) : null}
                onChange={(date) => handleDateChangeUpdate("graduateYear", date, index)}
                dateFormat="yyyy"
                showYearDropdown={true}
                showYearPicker
                scrollableYearDropdown
                yearDropdownItemNumber={200}
                disabled = {disabled}

              />
            </div>
          </div>

          <div className="m-b-10">
            <label >Class of Degree</label>
            <InputField
              placeholder="Select Class of Degree (Where Applicable)"
              onChange={(value) => handleUpdateChange("classOfDegree", value, index)}
              value={record.classOfDegree}
              disabled = {disabled}

            />
          </div>

          <div className="m-b-10">
            <label >Remarks</label>
            <TextAreaInputField
              placeholder="Remarks"
              value={record.remarks}
              onChange={(value) => handleUpdateChange("remarks", value, index)}
              disabled = {disabled}
            />
          </div>

          { canEdit  === true &&
            <div>
              <div className="flex space-between">
                <button
                  className="btn m-t-20 m-b-20"
                  onClick={(e) => openModal(index, record.id)}
                >
                  Edit
                </button>
                <button
                  className="edit-btn m-t-20 m-b-20"
                  onClick={(e) => deleteEduRecord(e, record.id)}
                >
                  Remove Qualification
                </button>
              </div>
            </div>
          }
          <div>
            <EducationRecordForm
              isOpen={isModalOpenArray[index]}
              onClose={() => closeModal(index)}
              formState={formStates}
              index={index}
              certificates={certificates}
              degrees={degrees}
              handleUpdateChange={handleUpdateChange}
              handleDateChangeUpdate={handleDateChangeUpdate}
              updateEduRecord={updateEduRecord}
              id={selectedRecordId}
            />
          </div>
        </div>
      ))}


    </div>
  )
  // ... your existing code for displaying/editing records
};

export default EducationalRecordDisplay;
