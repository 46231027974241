import React, { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { post, get } from "../../utility/fetch";
import NokForm from "./NokForm";
import DependantForm from "./DependantForm";
import { feedback } from "../modal/feedback";
import DisplayNok from "./DisplayNok";
import DisplayDependant from "./DisplayDependant";
import DisplayDependantEdit from "./DisplayDependantEdit";
import DisplayNokEdit from "./DisplayNokEdit";

const Relatives = (props) => {
  const id = props?.ID?.toString()
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateDependant, setSelectedDateDependant] = useState(null);
  const initialFormDataNok = {
    nokName: props?.dataNOK?.nokName || props?.dataNOK[0]?.nokName || "",
    nokAddress: props?.dataNOK?.nokAddress || props?.dataNOK[0]?.nokAddress || "",
    nokRelation: props?.dataNOK?.nokRelation || props?.dataNOK[0]?.nokRelation || "",
    nokDoB: props?.dataNOK?.nokDoB || props?.dataNOK[0]?.nokDoB || "",
    nokMobile: props?.dataNOK?.nokMobile || props?.dataNOK[0]?.nokMobile || "",
  }
  const initialFormDataDependant = {
    depName: props?.dataDEP?.depName || props?.dataDEP[0]?.depName || "",
    depAddress: props?.dataDEP?.depAddress || props?.dataDEP[0]?.depAddress || "",
    depRelation: props?.dataDEP?.depRelation || props?.dataDEP[0]?.depRelation || "",
    depDoB: props?.dataDEP?.depDoB || props?.dataDEP[0]?.depDoB || "",
    depMobile: props?.dataDEP?.depMobile || props?.dataDEP[0]?.depMobile || "",
  }

  const [formDataNok, setFormDataNok] = useState(initialFormDataNok);

  const [formDataDependant, setFormDataDependant] = useState(initialFormDataDependant);
  const [nokRecord, setNokRecord] = useState({});

  const [dependantRecord, setDependantRecord] = useState({});


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
    if (props?.review === false && (props?.dataDEP?.depName === '' || props?.dataNOK?.nokName === '')) {
      fetchNokRecord(id);
      fetchDepRecord(id);
    } else if (props?.staffId && (props?.dataDEP?.depName === '' || props?.dataNOK?.nokName === '')) {
      console.log('staff id on mount')
      fetchNokRecord(props?.staffId);
      fetchDepRecord(props?.staffId);
    }
  }, [dependantRecord, nokRecord]);

  const mergedFormData = { ...initialFormDataDependant, ...initialFormDataNok };
  useEffect(() => {
    props?.handleFormData(mergedFormData);
  }, []);

  
  useEffect(() => {
    const mergedFormData = { ...nokRecord, ...dependantRecord };
    console.log(mergedFormData)
    props?.handleFormData(mergedFormData);
  }, [nokRecord,dependantRecord]);

  console.log(formDataDependant, formDataNok, mergedFormData, props?.dataDEP, props?.dataNOK)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
    if (props?.dataDEP) {
      setDependantRecord({...initialFormDataDependant, id: props?.dataDEP[0]?.id })
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
    if (props?.dataNOK) {
      setNokRecord({...initialFormDataNok, id: props?.dataNOK[0]?.id  })
    }
  }, []);
  console.log(props?.staffId,)

  const postNOK = async (data) => {
    try {
      const response = await post('nok', data);
      console.log('Next of kin created successfully:', response, id);
      if (response && props?.review === false) {
        await fetchNokRecord(id)
      } else {
        fetchNokRecord(props?.staffId)

      }
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('failed to update perfonal info', response)
    }
  }

  const fetchNokRecord = async (id) => {
    try {
      const response = await get(`nok/${id}`);
      const Record = response;
      console.log('data', Record)

      if (props?.review === true && props?.review !== undefined && props?.canEdit === false) {
        setNokRecord(props?.dataNOK)
        console.log('data update view only ', Record)
        return
      } else if (props?.review === true && props?.review !== undefined && props?.canEdit === true) {
        console.log('data update ', Record)

        setNokRecord(response)
      } else {
        console.log('onboard ', Record)
        setNokRecord(response)
      }

      if (Record) {
        setNokRecord(Record);
      }
    } catch (response) {

      console.error('Error fetching educational records:', response);
    }
  };

  const fetchDepRecord = async (id) => {
    try {
      const response = await get(`dependants/${id}`);
      const Record = response;
      console.log('dep data', Record)

      if (props?.review === true && props?.review !== undefined && props?.canEdit === false) {
        setDependantRecord(props?.dataDEP)
        return
      } else if (props?.review === true &&  props?.canEdit === true) {
        setDependantRecord(response)
      } else {

        setDependantRecord(response)
      }

      // if (Record) {
      //   setDependantRecord(Record);
      // }
    } catch (response) {

      console.error('Error fetching educational records:', response);
    }
  };

  const CreateDependant = async (data) => {
    try {
      const response = await post('dependants', data);
      console.log('Next of kin created successfully:', response);
      if (response && props?.review === false) {
        await fetchDepRecord(id)
      } else {
        fetchDepRecord(props?.staffId)

      }
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
      setFormDataDependant(initialFormDataDependant)
    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('failed to update perfonal info', response)
    }
  }

  const handleInputChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "nokNumber") {
      // Validate if the value is a number and has a length of 11
      if (/^(\+?\d{1,3})?\d{11}$/.test(value)) {
        setFormDataNok((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
        console.log('mobile', selectedValue)
      } else {
        console.error("Invalid input for phone number");
        feedback({
          title: "Failed",
          text: "Invalid input for Mobile Number",
          iconType: "error",
        });
      }
    } else {
      if (props?.review === true) {
        setFormDataNok((prevData) => ({
          ...prevData,
          [field]: selectedValue,
          staffID: props?.staffId
        }));
        console.log('mobile', formDataNok)
      } else {
        setFormDataNok((prevData) => ({
          ...prevData,
          [field]: selectedValue,
          staffID: id
        }));
      }
    };
  }

  const handleInputChangeDependants = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "depNumber") {
      // Validate if the value is a number and has a length of 11
      if (/^(\+?\d{1,3})?\d{11}$/.test(value)) {
        setFormDataDependant((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
      } else {
        feedback({
          title: "Failed",
          text: "Invalid input for Mobile Number",
          iconType: "error",
        });
        console.error("Invalid input for phone number");
      }
    } else {
      // For other fields, proceed without validation
      if (props?.review === true) {
        setFormDataDependant((prevData) => ({
          ...prevData,
          [field]: selectedValue,
          staffID: props?.staffId
        }));
        console.log('mobile', formDataDependant)
      } else {
        setFormDataDependant((prevData) => ({
          ...prevData,
          [field]: selectedValue,
          staffID: id
        }));
      }
    }
  };


  const isFormValid = (data) => {
    const missingFields = [];

    // Check if all required fields are filled
    Object.entries(data).forEach(([fieldName, value]) => {
      // Exclude middleName and mothersMaidenName fields
      if (fieldName === "remarks") {
        return; // Skip to the next iteration
      }

      if (value === "" || value === null || value === undefined) {
        missingFields.push(fieldName);
      } else if (fieldName === "nokMobile" || fieldName === "depMobile") {
        // Validate nin and mobile fields with exactly 11 digits
        if (!/^(\+?\d{1,3})?\d{11}$/.test(value)) {
          missingFields.push(fieldName);
        }
      }
    });

    console.log("Missing fields for NOK:", missingFields);

    return { isValid: missingFields.length === 0, missingFields };
  };


  const handleDateChange = (date) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setFormDataNok((prevData) => ({
      ...prevData,
      nokDoB: formattedDateWithoutTimestamp,
    }));

    setSelectedDate(date);
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };

  const handleDateChangeDependant = (date) => {
    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setFormDataDependant((prevData) => ({
      ...prevData,
      depDoB: formattedDateWithoutTimestamp,
    }));

    setSelectedDateDependant(date);
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };


  const handleDependant = (e) => {
    e.preventDefault();

    console.log('mobile', formDataDependant)


    const validationResult = isFormValid(formDataDependant);

    if (validationResult?.isValid) {
      props?.handleFormDataDeps(formDataDependant);
      CreateDependant(formDataDependant)
      console.log('Dependant submitted:', formDataDependant)
      setFormDataDependant(initialFormDataDependant);
    } else {
      const missingFieldsMessage = `Missing fields: ${validationResult?.missingFields.join(', ')}`;
      console.log("Please fill in all required fields before submitting. " + missingFieldsMessage);
      // Display feedback with missing fields
      feedback({
        title: "Validation Failed",
        text: "Please fill in all required fields before submitting. " + missingFieldsMessage,
        iconType: "warning",
      });
    }
  }

  const handleNok = (e) => {
    e.preventDefault();

    const validationResult = isFormValid(formDataNok);

    if (validationResult?.isValid) {
      props?.handleFormData(formDataNok);
      postNOK(formDataNok);
      console.log('NOK submitted:', formDataNok)
      setFormDataNok(initialFormDataNok);
    } else {
      const missingFieldsMessage = `Missing fields: ${validationResult?.missingFields.join(', ')}`;
      console.log("Please fill in all required fields before submitting. " + missingFieldsMessage);
      // Display feedback with missing fields
      feedback({
        title: "Validation Failed",
        text: "Please fill in all required fields before submitting. " + missingFieldsMessage,
        iconType: "warning",
      });
    }
  }




  return (

    <div className="form-step" data-step="6">

      <div className="flex flex-h-center space-between">
        <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Next of Kin</h3>
        <div className="float-right m-t-20 m-b-20">
          {/* <button className="save-drafts">Add more Next Of Kin</button> */}
        </div>
      </div>

      {props?.review !== true && props?.review !== undefined &&
        <div>
          <NokForm
            handleDateChange={handleDateChange}
            selectedDate={selectedDate}
            handleInputChange={handleInputChange}

          />
          <p className="m-t-20 search-text">Please clear input after adding one Next Of Kin and enter new values to add another qualification</p>
          <div className="m-t-20">
            <button onClick={handleNok} className="submit-btn m-b-20">Add Next Of Kin</button>
          </div>

          <div className="m-t-20 m-b-20">


            <DisplayNok
              nokRecord={nokRecord}
              setNokRecord={setNokRecord}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}
              disabled={true}

            />
          </div>
        </div>
      }


      {props?.review === true && props?.canEdit === true &&
        <div>

          <div className="m-t-20 m-b-20">
            <DisplayNokEdit
              nokRecord={nokRecord }
              setNokRecord={setNokRecord}
              disabled={true}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}
            />
          </div>

          <NokForm
            handleDateChange={handleDateChange}
            selectedDate={selectedDate}
            handleInputChange={handleInputChange}

          />
          <p className="m-t-20 search-text">Please clear input after adding one Next Of Kin and enter new values to add another qualification</p>
          <div className="m-t-20">
            <button onClick={handleNok} className="submit-btn m-b-20">Add Next Of Kin</button>
          </div>


        </div>
      }

      {props?.review === true && props?.canEdit !== true &&
        <div>
          <div className="m-t-20 m-b-20">
            <DisplayNok
              nokRecord={nokRecord}
              setNokRecord={setNokRecord}
              review={props?.review}
              disabled={props?.disabled}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}
            />
          </div>
        </div>
      }

      <div className="flex flex-v-center space-between">
        <h3 className=" m-t-60 m-b-30 bold-text bottom-border">Dependant(Spouse,Children)</h3>
        <div className="float-right m-l-90 m-t-20 m-b-20">
        </div>
        {/* <button className="save-drafts">Add more</button> */}
      </div>
      {props?.review !== true && props?.review !== undefined &&
        <div>
          <div>
            <DependantForm
              handleDateChangeDependant={handleDateChangeDependant}
              selectedDateDependant={selectedDateDependant}
              handleInputChangeDependant={handleInputChangeDependants}
            />

          </div>
          <p className="m-t-20 search-text">Please clear input after adding a Dependent and enter new values to add another qualification</p>
          <div className="m-t-20">
            <button onClick={handleDependant} className="submit-btn m-b-20">Add Dependant</button>
          </div>
          <div className="m-t-20 m-b-20">
            <DisplayDependant
              depRecord={dependantRecord}
              setDepRecord={setDependantRecord}
              handleFormData={props?.handleFormData}
              canEdit={props?.canEdit}
              disabled={true}

            />
          </div>
        </div>
      }

      {props?.review === true && props?.canEdit === true &&
        <div>
          <div>

            <div className="m-t-20 m-b-20">
              <DisplayDependantEdit
                depRecord={dependantRecord }

                setDepRecord={setDependantRecord}
                handleFormData={props?.handleFormData}
                canEdit={props?.canEdit}
                disabled={true}
              />
            </div>

            <DependantForm
              handleDateChangeDependant={handleDateChangeDependant}
              selectedDateDependant={selectedDateDependant}
              handleInputChangeDependant={handleInputChangeDependants}
            />

          </div>
          <p className="m-t-20 search-text">Please clear input after adding a Dependent and enter new values to add another qualification</p>
          <div className="m-t-20">
            <button onClick={handleDependant} className="submit-btn m-b-20">Add Dependant</button>
          </div>

        </div>
      }
      {props?.review === true && props?.canEdit !== true &&
        <div>
          <div className="m-t-20 m-b-20">
            <DisplayDependant
              depRecord={dependantRecord}
              setDepRecord={setDependantRecord}
              review={props?.review}
              disabled={props?.disabled}
              handleFormData={props?.handleFormDataDeps}
              canEdit={props?.canEdit}

            />
          </div>
        </div>
      }
      {props?.download !== true &&
        <div className="flex space-between">
          <button className="col-5 submit-btn prev-step" onClick={props?.handleBack}>Previous</button>
          <button className="col-5  submit-btn next-step" onClick={props?.handleNext}>Next</button>
        </div>

      }
    </div>

  )
}

export default Relatives;