import { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get, post } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import TextAreaInputField from "../inputs/TextAreaInputField";
import Cookies from "js-cookie";
import InitiateOnboarding from "../layouts/InitiateOnboarding";
import CustomDatePicker from "../inputs/CustomDatePicker";
import axios from "axios";

const Bio = (props) => {
    const userId = Cookies.get("userId");
    const [selectedDate, setSelectedDate] = useState(null);
    const [states, setStates] = useState([]);
    const [ID, setID] = useState([])
    const [toApprovers, setToApprovers] = useState([]);
    const [throughAppovers, setThroughApprovers] = useState([])
    const [rin, setRin] = useState(null);
    const [rinData, setRinData] = useState({});
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchStates()
        if (props?.data) {
            props.onIDUpdate(props?.data?.userId);
        }

        setFormData({
            lastName: rinData?.lastName || props?.data?.lastName || props?.payload?.lastName || "",
            firstName: rinData?.firstName || props?.data?.firstName || props?.payload?.firstName || "",
            middleName: rinData?.middleName || props?.data?.middleName || props?.payload?.middleName || "",
            title: rinData?.title || props?.data?.title || props?.payload?.title || "",
            gender: rinData?.gender || props?.data?.gender || props?.payload?.gender || "",
            dateOfBirth: props?.data?.dateOfBirth || props?.data?.dob || props?.payload?.dateOfBirth || "",
            stateId: props?.data?.state?.id || props?.data?.stateId || props?.payload?.stateId || 0,
            placeOfBirth: props?.data?.placeOfBirth || props?.payload?.placeOfBirth || "",
            maritalStatus: rinData?.maritalStatus || props?.data?.maritalStatus || props?.payload?.maritalStatus || "",
            motherMaidenName: props?.data?.motherMaidenName || props?.data?.mothersMaidenName || props?.payload?.mothersMaidenName || "",
            rin: rinData?.residencyId || props?.data?.rin || props?.employeeRIN || props?.data?.employeeRIN || props?.payload?.rin || '',
            userId: Number(userId),
            onboardToApprovers: toApprovers,
            onboardThroughApprovers: throughAppovers,
        })


        window.scrollTo(0, 0);
        document.title = 'EDSG - Test';
    }, [props?.data, rinData, throughAppovers, toApprovers]);

    useEffect(() => {
        props?.handleFormData(formData);

    }, [formData])

    const fetchResidencyData = async (id) => {
        try {
            const response = await axios.get(`https://edogoverp.com/residencylookup/api/residency/FetchResident?residencyid=${id}`)
            console.log(response)
            if (response.status === 200) {
                setRinData(response?.data)
                props?.setRinData(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    console.log(formData, props?.data)
    console.log(toApprovers, throughAppovers)


    const postData = async (data) => {
        if (toApprovers.length <= 0) {
            feedback({
                title: "Failed",
                text: ' Please add at least one to approver',
                iconType: "error",
            });
            return
        }

        try {
            setLoading(true);
            const response = await post('bio/create-employee', data);
            console.log(response)
            const id = response?.id
            props.handleFormData(data);
            if (response?.code === 1) {
                props.onIDUpdate(response?.data);
                props.handleNext();
                feedback({
                    title: "Success",
                    text: response?.message,
                    iconType: "success",
                });
                setLoading(false);
            } else {
                feedback({
                    title: "Cannot Proceed",
                    text: response?.message,
                    iconType: "error",
                });
                setLoading(false);


            }
            console.log(id)
            console.log('response:', response)
        } catch (response) {
            feedback({
                title: "Failed",
                text: response?.message,
                iconType: "error",
            });
            console.error('failed to update perfonal info', response)
            setLoading(false);
        }
    }

    const fetchStates = async () => {
        await get('state')
            .then(response => {
                setStates(response?.data);
            })
            .catch(error => {
                console.error('Error fetching states', error)
            })
    }

    console.log('states', states)
    const handleDateChange = (date) => {
        const formattedDateWithoutTimestamp = date
            ? new Date(date).toLocaleDateString()
            : "";

        setFormData((prevData) => ({
            ...prevData,
            dateOfBirth: formattedDateWithoutTimestamp,
        }));



        setSelectedDate(date);
        console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);

    };

    const handleInputChange = (field, value) => {
        const selectedValue = value && value.target ? value.target.value : value;
        if (field === "rin") {
            // Validate if the value is a number and has a length of 11
            if (!isNaN(selectedValue) && selectedValue.length <= 11) {
                setFormData((prevData) => ({
                    ...prevData,
                    [field]: selectedValue,
                }));
                props?.handleFormData({ [field]: selectedValue });
                setRin(selectedValue);
                console.log(field, ': ', selectedValue)
                if (selectedValue.length === 11) {
                    fetchResidencyData(selectedValue)
                }
            } else {
                // Display an error or handle the invalid input accordingly
                console.error("Invalid input for rin");
                feedback({
                    title: "Failed",
                    text: `Invalid input for ${field}`,
                    iconType: "error",
                });
            }
        } else {
            // For other fields, proceed without validation
            setFormData((prevData) => ({
                ...prevData,
                [field]: selectedValue === "" ? "" : selectedValue,
            }));
        }
    };




    const isFormValid = () => {
        const missingFields = [];

        // Check if all required fields are filled
        Object.entries(formData).forEach(([fieldName, value]) => {
            // Exclude middleName and mothersMaidenName fields
            if (fieldName === "middleName" || fieldName === "motherMaidenName") {
                return; // Skip to the next iteration
            }

            if (value === "" || value === null || value === undefined) {
                missingFields.push(fieldName);
            } else if (fieldName === "nin" || fieldName === "mobile") {
                // Validate nin and mobile fields with exactly 11 digits
                if (!/^\d{11}$/.test(value)) {
                    missingFields.push(fieldName);
                }
            }
        });

        console.log("Missing fields:", missingFields);

        return { isValid: missingFields.length === 0, missingFields };
    }


    const titleOptions = [
        { value: "ALH", label: "ALHAJI" },
        { value: "ALJ", label: "ALHAJA" },
        { value: "ARCH", label: "ARCHITECT" },
        { value: "BARO", label: "BARONESS" },
        { value: "BARR", label: "BARRISTER" },
        { value: "CHIEF", label: "CHIEF" },
        { value: "COL", label: "COLONEL" },
        { value: "COMDR", label: "COMMANDER" },
        { value: "COMM", label: "COMMRADE" },
        { value: "CPTN", label: "CAPTAIN" },
        { value: "DEAC", label: "DEACON" },
        { value: "DR", label: "DOCTOR" },
        { value: "ELDER", label: "ELDER" },
        { value: "ENGR", label: "ENGINEER" },
        { value: "EVAN", label: "EVANGELIST" },
        { value: "EXC", label: "EXCELLENCY" },
        { value: "HIGH", label: "HIGH CHIEF" },
        { value: "HON", label: "HONOURABLE/HONBL" },
        { value: "HRH", label: "HIS ROYAL HIGHNESS" },
        { value: "JUST", label: "JUSTICE" },
        { value: "LADY", label: "LADY" },
        { value: "LCOM", label: "LT.COMMANDER" },
        { value: "LORD", label: "LORD" },
        { value: "MADAM", label: "MADAM" },
        { value: "MAJOR", label: "MAJOR" },
        { value: "MAST", label: "MASTER" },
        { value: "MAZI", label: "MAZI" },
        { value: "MES", label: "MESSERS" },
        { value: "MISS", label: "MISS" },
        { value: "MIST", label: "MISTER" },
        { value: "MR", label: "MR" },
        { value: "MRS", label: "MRS" },
        { value: "MM", label: "MR & MRS" },
        { value: "MS", label: "MS" },
        { value: "OBA", label: "OBA" },
        { value: "OTUN", label: "OTUNBA" },
        { value: "PAST", label: "PASTOR" },
        { value: "PRINCE", label: "PRINCE" },
        { value: "PROF", label: "PROFESSOR" },
        { value: "REV", label: "REVEREND" },
        { value: "SEKH", label: "SHEIKH" },
        { value: "SENT", label: "SENATOR" },
        { value: "SIR", label: "SIR" },
    ];

    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },

    ];

    const maritalStatus = [
        { value: 'married', label: 'Married' },
        { value: 'single', label: 'Single' },
        { value: 'divorced', label: 'Divorced' },
        { value: 'widow', label: 'Widow' },
        { value: 'widower', label: 'Widower' },
        { value: 'undisclosed', label: 'Undisclosed' },
    ]

    const submitBio = (e) => {
        if (props?.review) {
            props?.handleNext()
            return
        }
        const payload = {
            ...formData,
            userId: throughAppovers[0]?.approverId,
            onboardToApprovers: toApprovers,
            onboardThroughApprovers: throughAppovers,
        }
        console.log(toApprovers, throughAppovers, payload);
        e.preventDefault();
        const { isValid, missingFields } = isFormValid();
        if (isValid) {
            postData(payload);
            console.log("Form submitted:", payload);
            console.log('handleFormData:',)
        } else {
            if (missingFields) {
                const missingFieldNames = missingFields.map(fieldName => {
                    // Convert camelCase field names to sentence case
                    const formattedFieldName = fieldName.replace(/([A-Z])/g, ' $1').toLowerCase();
                    return formattedFieldName;
                }).join(", ");
                const feedbackMessage = `Please fill in all required fields (${missingFieldNames}) before submitting.`;
                feedback({
                    title: "Form not complete",
                    text: feedbackMessage,
                    iconType: "warning",
                });
                console.log("Please fill in all required fields before submitting.");
            } else {
                console.log("Missing fields array is undefined.");
                // Handle the situation where missingFields array is undefined
            }
        }
    };


    return (

        <div className="form-step" data-step="1">
            {!props?.review &&
                <InitiateOnboarding
                    setToApprovers={setToApprovers}
                    setThroughApprovers={setThroughApprovers}
                />

            }
            <div className="m-b-20">
                <label >Rin</label>
                <InputField
                    placeholder='Rin'
                    name='Rin'
                    required
                    onChange={(value) => handleInputChange("rin", value)}
                    value={rin || formData?.rin || props?.data?.rin}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label >Last Name</label>
                <InputField
                    placeholder='Last Name'
                    required
                    name='Lastname'
                    onChange={(value) => handleInputChange("lastName", value)}
                    value={formData?.lastName}
                    disabled={true}
                />
            </div>
            <div className="m-b-20">
                <label >First Name</label>
                <InputField
                    placeholder='First Name'
                    required
                    name='Firstname'
                    onChange={(value) => handleInputChange("firstName", value)}
                    value={formData?.firstName}
                    disabled={true}
                />
            </div>
            <div className="m-b-20">
                <label >Middle Name(Optional)</label>
                <InputField
                    placeholder='MiddleName'
                    name='Middle Name'
                    onChange={(value) => handleInputChange("middleName", value)}
                    value={formData?.middleName}
                    disabled={props?.disabled}

                />
            </div>

            <div className="m-b-20">
                <label >Title</label>
                <Dropdown
                    options={titleOptions}
                    placeholder='Select Title'
                    required
                    name='Title'
                    onChange={(value) => handleInputChange("title", value)}
                    selectedValue={formData?.title?.toUpperCase()}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label >Gender</label>
                <Dropdown
                    options={genderOptions}
                    placeholder='Select Gender'
                    required
                    name='Gender'
                    onChange={(value) => handleInputChange("gender", value)}
                    selectedValue={formData?.gender?.toLowerCase() === 'm' ? 'Male' : formData?.gender?.toLowerCase() === 'f' ? 'Female' : formData?.gender}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label >Date Of Birth</label>
                <CustomDatePicker
                    className="input-field"
                    placeholderText="Date Of Birth"
                    selected={selectedDate}
                    required
                    isClearable
                    dateFormat="dd/MM/yyyy"
                    onChange={handleDateChange}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={200}
                    value={selectedDate || formData?.dateOfBirth}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label > State Of Birth</label>
                <Dropdown
                    options={states}
                    placeholder='Select State Of Birth'
                    required
                    name='State Of Birth'
                    onChange={(value) => handleInputChange("stateId", Number(value))}
                    labelKey="name"
                    valueKey="id"
                    selectedValue={formData?.stateId}
                    disabled={props?.disabled}


                />
            </div>
            {props?.review !== true &&
                <div>
                    <div className="m-b-20">
                        <label >Place Of Birth</label>
                        <InputField
                            placeholder='Place Of Birth'
                            required
                            name='Place Of Birth'
                            onChange={(value) => handleInputChange("placeOfBirth", value)}
                            value={formData?.placeOfBirth}
                            disabled={props?.disabled}

                        />
                    </div>
                </div>}

            <div className="m-b-20">
                <label >Marital Status</label>
                <Dropdown
                    options={maritalStatus}
                    placeholder='Select Marital Status'
                    required
                    name='Marital Status'
                    onChange={(value) => handleInputChange("maritalStatus", value)}
                    selectedValue={formData?.maritalStatus?.toLowerCase()}
                    disabled={props?.disabled}

                />
            </div>
            <div className="m-b-20">
                <label >Mother's Maiden Name(Optional)</label>
                <InputField
                    placeholder="Mother's Maiden Name"

                    name="Mother's Maiden Name"
                    onChange={(value) => handleInputChange("motherMaidenName", value)}
                    value={formData?.motherMaidenName}
                    disabled={props?.disabled}

                />
            </div>
            {props?.download !== true &&
                < div className="flex flex-right">
                    <button className="col-5 submit-btn next-step" onClick={submitBio}>Next</button>
                </div>
            }
        </div >


    )
}

export default Bio;