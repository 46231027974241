import { useState, useEffect } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get, put } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import TextAreaInputField from "../inputs/TextAreaInputField";
import SelectInput from "../inputs/SelectInput";

const EmploymentRecordsEdit = (props) => {
  const id = props?.ID

  const [formData, setFormData] = useState({
    dateOfFirstApp: props?.payload?.dateOfFirstApp || props?.data?.dateOfFirstApp || "",
    fileNumber: props?.payload?.fileNumber || props?.data?.fileNumber || "",
    gradeOfFirstApp: props?.payload?.gradeOfFirstApp || props?.data?.gradeOfFirstApp || "",
    stepOfFirstApp: props?.payload?.stepOfFirstApp || props?.data?.stepOfFirstApp || "",
    firstAppCadre: props?.payload?.firstAppCadre || props?.payload?.firstAppCadre?.value || props?.data?.firstAppCadre || "",
    staffID: props?.payload?.staffID || props?.data?.staffId || props?.data?.staffID || "",
    dateOfConfirmation: props?.payload?.dateOfConfirmation || props?.data?.dateOfConfirmation || "" || null,
    currentCadre: props?.payload?.currentCadre || props?.payload?.currentCadre?.value || props?.data?.currentCadre || "",
    currentLevel: props?.payload?.currentLevel || props?.data?.currentLevel || "",
    currentStep: props?.payload?.currentStep || props?.data?.currentStep || "",
    dateOfPresentPosition: props?.payload?.dateOfPresentPosition || props?.data?.dateOfPresentPosition || "",
    mdaId: props?.payload?.mdaId || props?.payload?.mda?.id || props?.data?.mdaId || props?.data?.mda?.id || "",
    email: props?.payload?.email || props?.data?.email || "",
    officialEmail: props?.payload?.officialEmail || props?.data?.officialEmail || "",
    bankId: props?.payload?.bankId || '',
    salaryStructureId: props?.payload?.salaryStructureId || '',
    salaryStructureByTypeId: props?.payload?.salaryStructureByTypeId || '',
    salaryAccountNumber: props?.payload?.salaryAccountNumber || ""
  });


  useEffect(() => {
    if (props?.data && !formData?.bankId) {
      setFormData({
        dateOfFirstApp: props?.data?.dateOfFirstApp || "",
        fileNumber: props?.data?.fileNumber || "",
        gradeOfFirstApp: props?.data?.gradeOfFirstApp || "",
        stepOfFirstApp: props?.data?.stepOfFirstApp || "",
        firstAppCadre: props?.data?.firstAppCadre || props?.data?.firstAppCadre?.value || "",
        staffID: props?.data?.staffId || props?.data?.staffID || "",
        dateOfConfirmation: props?.data?.dateOfConfirmation || "" || null,
        currentCadre: props?.data?.currentCadre || props?.data?.currentCadre?.value || "",
        currentLevel: props?.data?.currentLevel || "",
        currentStep: props?.data?.currentStep || "",
        dateOfPresentPosition: props?.data?.dateOfPresentPosition || "",
        mdaId: props?.data?.mda || props?.data?.mda?.id || "",
        email: props?.data?.email || "",
        officialEmail: props?.data?.officialEmail || "",
        bankId: props?.data?.bankId || '',
        salaryStructureId: props?.data?.salaryStructureId || '',
        salaryStructureByTypeId: props?.data?.salaryStructureByTypeId || '',
        salaryAccountNumber: props?.data?.salaryAccountNumber || ""
      }
      );
    }
    if (!formData?.bankId) {
      setFormData({
        dateOfFirstApp: props?.payload?.dateOfFirstApp || props?.data?.dateOfFirstApp || "",
        fileNumber: props?.payload?.fileNumber || props?.data?.fileNumber || "",
        gradeOfFirstApp: props?.payload?.gradeOfFirstApp || props?.data?.gradeOfFirstApp || "",
        stepOfFirstApp: props?.payload?.stepOfFirstApp || props?.data?.stepOfFirstApp || "",
        firstAppCadre: props?.payload?.firstAppCadre || props?.payload?.firstAppCadre?.value || props?.data?.firstAppCadre || "",
        staffID: props?.payload?.staffID || props?.data?.staffId || props?.data?.staffID || "",
        dateOfConfirmation: props?.payload?.dateOfConfirmation || props?.data?.dateOfConfirmation || "" || null,
        currentCadre: props?.payload?.currentCadre || props?.payload?.currentCadre?.value || props?.data?.currentCadre || "",
        currentLevel: props?.payload?.currentLevel || props?.data?.currentLevel || "",
        currentStep: props?.payload?.currentStep || props?.data?.currentStep || "",
        dateOfPresentPosition: props?.payload?.dateOfPresentPosition || props?.data?.dateOfPresentPosition || "",
        mdaId: props?.payload?.mdaId || props?.payload?.mda?.id || props?.data?.mdaId || props?.data?.mda?.id || "",
        email: props?.payload?.email || props?.data?.email || "",
        officialEmail: props?.payload?.officialEmail || props?.data?.officialEmail || "",
        bankId: props?.payload?.bankId || props?.data?.bankId || '',
        salaryStructureId: props?.payload?.salaryStructureId || props?.data?.salaryStructureId || '',
        salaryStructureByTypeId: props?.payload?.salaryStructureByTypeId || props?.data?.salaryStructureByTypeId || '',
        salaryAccountNumber: props?.payload?.salaryAccountNumber || props?.data?.salaryAccountNumber || ""
      });
    }


    window.scrollTo(0, 0);
    document.title = 'EDSG - Test';
  }, [props?.data]);

  const [selectedDate, setSelectedDate] = useState('');
  const [mda, setMda] = useState();
  const [SalaryStructureList, setSalaryStructureList] = useState([]);
  const [connfirmation, setConfrimation] = useState('')
  const [datePresent, setDatePresent] = useState('')
  const [salaryStructureTypeList, setSalaryStructureTypeList] = useState('')
  const [SalaryStructureType, setSalaryStructureType] = useState('')
  const [Banks, setBanks] = useState([])
  const [Bank, setBank] = useState('')
  const [SalaryStructure, setSalaryStructure] = useState('')
  const [email, setEmail] = useState('')
  const [officialEmail, setOfficialEmail] = useState('')
  const [mdaOptions, setMdaOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [currentCadre, setCurrentCadre] = useState('')
  const [firstAppCadre, setFirstAppCadre] = useState('')
  const [presMda, setPresMda] = useState('')



  console.log('id in emp rec', id)
  useEffect(() => {
    fetchOptions('mdaId');
  }, []);

  useEffect(() => {
    fetchOptions('roles');
    fetchData();
    banks()
    salaryStructureType()
    salaryStructureByTypeId(formData?.salaryStructureByTypeId)
  }, []);

  useEffect(() => {
    props?.handleFormData(formData)
    console.log(props?.payload, formData)
  }, [formData])


  const fetchOptions = async (field) => {
    let url;
    if (field === 'mdaId') {
      url = 'mda';
    } else if (field === 'currentCadre' || field === 'firstAppCadre' || field === 'roles') {
      url = 'roles';
    }
    await get(url)
      .then(response => {
        if (field === 'mdaId') {
          setMdaOptions(response?.data);
        } else if (field === 'currentCadre' || field === 'firstAppCadre' || field === 'roles') {
          setRoleOptions(response?.data);
        }
      })
      .catch(error => {
        console.error(`Error fetching ${field}`, error);
      });
  };

  const fetchData = async () => {
    const currentCadreData = await roleById(formData?.currentCadre);
    const firstAppCadreData = await roleById(formData?.firstAppCadre);
    const presMdaData = await mdaById(formData?.mdaId);
    const bankData = await banksById(formData?.bankId)
    if (formData?.salaryStructureId !== '') {
      const salaryStructureData = await salaryStructureById(formData?.salaryStructureId)
      setSalaryStructure(salaryStructureData)
      console.log(salaryStructureData,)
    }
    const salaryStructureTypeData = await salaryStructureTypeById(formData?.salaryStructureByTypeId);

    console.log(currentCadreData, presMdaData, salaryStructureTypeData, formData.mdaId)

    // setCurrentCadre(currentCadreData);
    // setFirstAppCadre(firstAppCadreData);
    setPresMda(presMdaData);
    setBank(bankData)

    setSalaryStructureType(salaryStructureTypeData)
  };

  const roleById = async (id) => {
    try {
      const response = await get(`role/${id}`)
      console.log('response', response);

      return { value: response?.data?.id, label: response?.data?.name }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const mdaById = async (id) => {
    if (isNaN(id)) {
      return;
    }
    try {
      const response = await get(`mda/${id}`)
      console.log('response', response);

      return { value: response?.data?.id, label: response?.data?.name }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const banksById = async (id) => {
    if (isNaN(id)) {
      return;
    }
    try {
      const response = await get(`Banks/${id}`)
      console.log('response', response);

      return { value: response?.data?.id, label: response?.data?.name }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const banks = async () => {

    try {
      const response = await get(`Banks`)
      console.log('response', response);
      if (response.code === 1) {
        setBanks(response?.data);
      }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const salaryStructureTypeById = async (id) => {
    if (isNaN(id)) {
      return;
    }
    try {
      const response = await get(`Salary/Structure/Type/${id}`)
      console.log('response', response);

      return { value: response?.data?.id, label: response?.data?.structureType }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const salaryStructureType = async () => {

    try {
      const response = await get(`Salary/Structure/Type`)
      console.log('response', response);

      if (response.code === 1) {
        setSalaryStructureTypeList(response.data);
      }



    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const salaryStructureByTypeId = async (id) => {
    if (isNaN(id)) {
      return;
    }
    try {
      const response = await get(`Salary/Structure/TypeId/${id}`)
      console.log('response', response);

      if (response?.code === 1) {
        setSalaryStructureList(response?.data)
      }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const salaryStructureById = async (id) => {
    if (isNaN(id)) {
      return;
    }
    try {
      const response = await get(`Salary/Structure/${id}`)
      console.log('response', response);

      if (response?.code === 1) {
        // setSalaryStructure({value: response?.data?.id, label: `${response?.data?.level} - ${response?.data?.step}`})
        return { value: response?.data?.id, label: `${response?.data?.level} - ${response?.data?.step} (${response?.data?.type})` }
      }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }



  const putEmploymentRecord = async (data) => {
    console.log(id)
    try {
      const response = await put(`bio/employmentRecordsEdit/${id}`, data)
      console.log('personalInfo', data);
      console.log('response', response);
      props?.handleNext();
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });

    } catch (response) {
      feedback({
        title: "Failed",
        text: response?.message,
        iconType: "error",
      });
      console.error('failed to update perfonal info', response)
    }
  }

  const handleInputChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;

    console.log(value)

    if (field === "email") {
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));
      setEmail(selectedValue);

    } else if (field === "officialEmail") {
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));
      setOfficialEmail(selectedValue);

    } else if (field === 'firstAppCadre') {
      console.log(selectedValue)
      fetchOptions(field);
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue?.label.toString(),
      }));
      setFirstAppCadre(selectedValue?.label.toString())

    } else if (field === 'currentCadre') {
      fetchOptions(field);
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue?.label.toString(),
      }));
      console.log(selectedValue)
      setCurrentCadre(selectedValue?.label.toString())


    } else if (field === 'mdaId') {
      console.log(selectedValue)
      fetchOptions(field);
      setPresMda(selectedValue)

      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(selectedValue?.value),
      }));

    } else if (field === 'bankId') {
      console.log(selectedValue)

      setBank(selectedValue)

      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(selectedValue?.value),
      }));

    } else if (field === 'salaryStructureByTypeId') {
      console.log(selectedValue)

      setSalaryStructureType(selectedValue)
      salaryStructureByTypeId(selectedValue.value)
      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(selectedValue?.value),
      }));

    } else if (field === 'salaryStructureId') {
      console.log(selectedValue)

      setSalaryStructure(selectedValue)

      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(selectedValue?.value),
      }));

    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));

    }
  };


  const isFormValid = () => {
    // Check if all required fields are filled
    const isValid = Object.entries(formData).every(([fieldName, value]) => {
      console.log(`Field: ${fieldName}, Value: ${value}, Type: ${typeof value}`);

      if (fieldName === "email" || fieldName === "officialEmail") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(value)) {
          props?.showNotification("Email format not correct!");
          return false;
        }
      }

      if (typeof value === 'string') {
        return value.trim() !== '';
      } else {
        // For non-string values, consider the value valid if it exists
        return value !== undefined && value !== null;
      }
    });

    console.log("Form validity:", isValid);

    return isValid;
  };


  console.log(formData)

  const handleSubmit = (e) => {
    if (props?.review !== true) {
      e.preventDefault();
      console.log("Form submitted:", formData);
      putEmploymentRecord(formData)
      props?.handleFormData(formData);
      console.log(props?.handleFormData(formData))


    } else {
      props?.handleNext()
    }
  };


  const mdaBySearch = async (querry) => {

    try {
      const response = await get(`mda?query=${querry}`)
      console.log('response', response);

      if (response.code === 1) {
        setMdaOptions(response?.data)
      }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const roleBySearch = async (querry) => {

    try {
      const response = await get(`roles?rolename=${querry}`)
      console.log('response', response);

      if (response.code === 1) {
        setRoleOptions(response?.data)
      }


    } catch (response) {

      console.error('failed to update perfonal info', response)
    }
  }

  const handleSelectInputChange = (field, value) => {
    if (field === 'mdaId') {
      console.log(value);


      mdaBySearch(value);

    } else if (field === 'currentCadre') {
      console.log(value);


      roleBySearch(value);
    } else {
      console.log(value);


      roleBySearch(value);
    }
  };

  const handleSelectInput = (field, value) => {
console.log(value)
    if (field === 'mdaId') {
      fetchOptions(field);
      setPresMda(value)


      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(value?.value),
      }));
      console.log(value);
      setMdaOptions(prevMDocs => [...prevMDocs, value]);

    } else if (field === 'currentCadre') {
      fetchOptions(field);
      setCurrentCadre(value)


      setFormData((prevData) => ({
        ...prevData,
        [field]: (value),
      }));
      console.log(value);
      setRoleOptions(prevMDocs => [...prevMDocs, value]);
    } else {
      fetchOptions(field);
      setFirstAppCadre(value)
  
  
      setFormData((prevData) => ({
        ...prevData,
        [field]: (value),
      }));
      console.log(value);
      setRoleOptions(prevMDocs => [...prevMDocs, value]);
    }

  };

  const handleDateChange = (field, date) => {

    const formattedDateWithoutTimestamp = date
      ? new Date(date).toLocaleDateString()
      : "";

    setFormData((prevData) => ({
      ...prevData,
      [field]: formattedDateWithoutTimestamp,
    }));
    switch (field) {
      case "dateOfFirstApp":
        setSelectedDate(date);

        break;
      case "dateOfConfirmation":
        setConfrimation(date)
        break;
      case "dateOfPresentPosition":
        setDatePresent(date)
        break
      default:
        break;
    }
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };





  const jobLevels = [
    { value: "Graduate Trainee", label: "Graduate Trainee" },
    { value: "Level 1", label: "Level 1" },
    { value: "Level 2", label: "Level 2" },
    { value: "Level 3", label: "Level 3" },
    { value: "Level 4", label: "Level 4" },
    { value: "Level 5", label: "Level 5" },
    { value: "Level 6", label: "Level 6" },
    { value: "Level 7", label: "Level 7" },
    { value: "Level 8", label: "Level 8" },
    { value: "Level 9", label: "Level 9" },
    { value: "Level 10", label: "Level 10" },
    { value: "Level 11", label: "Level 11" },
    { value: "Level 12", label: "Level 12" },
    { value: "Level 13", label: "Level 13" },
    { value: "Level 14", label: "Level 14" },
    { value: "Level 15", label: "Level 15" },
    { value: "Level 16", label: "Level 16" },
    { value: "Level 17", label: "Level 17" },
    { value: "Level 1 (Non Civil)", label: "Level 1 (Non Civil)" },
    { value: "Level 2 (Non Civil)", label: "Level 2 (Non Civil)" },
    { value: "Level 3 (Non Civil)", label: "Level 3 (Non Civil)" },
    { value: "Level 4 (Non Civil)", label: "Level 4 (Non Civil)" },
    { value: "Level 5 (Non Civil)", label: "Level 5 (Non Civil)" },
    { value: "Level 6 (Non Civil)", label: "Level 6 (Non Civil)" },
    { value: "Level 7 (Non Civil)", label: "Level 7 (Non Civil)" },
    { value: "Level 8 (Non Civil)", label: "Level 8 (Non Civil)" },
    { value: "Level 9 (Non Civil)", label: "Level 9 (Non Civil)" },
    { value: "Level 10 (Non Civil)", label: "Level 10 (Non Civil)" },
    { value: "Level 11 (Non Civil)", label: "Level 11 (Non Civil)" },
    { value: "Level 12 (Non Civil)", label: "Level 12 (Non Civil)" },
    { value: "Level 13 (Non Civil)", label: "Level 13 (Non Civil)" },
    { value: "Level 14 (Non Civil)", label: "Level 14 (Non Civil)" },
    { value: "Level 15 (Non Civil)", label: "Level 15 (Non Civil)" },
    { value: "Level 16 (Non Civil)", label: "Level 16 (Non Civil)" },
    { value: "Level 17 (Non Civil)", label: "Level 17 (Non Civil)" },
    { value: "JS 1", label: "JS 1" },
    { value: "JS 2", label: "JS 2" },
    { value: "JS 3", label: "JS 3" },
    { value: "JS 4", label: "JS 4" },
    { value: "JS 5", label: "JS 5" },
    { value: "JS 6", label: "JS 6" },
    { value: "JS 7", label: "JS 7" },
    { value: "JS 8", label: "JS 8" },
    { value: "JS 9", label: "JS 9" },
    { value: "JS 10", label: "JS 10" },
    { value: "Consolidated", label: "Consolidated" }
  ];


  const steps = [
    { value: "Step 1", label: "Step 1" },
    { value: "Step 2", label: "Step 2" },
    { value: "Step 3", label: "Step 3" },
    { value: "Step 4", label: "Step 4" },
    { value: "Step 5", label: "Step 5" },
    { value: "Step 6", label: "Step 6" },
    { value: "Step 7", label: "Step 7" },
    { value: "Step 8", label: "Step 8" },
    { value: "Step 9", label: "Step 9" },
    { value: "Step 10", label: "Step 10" },
    { value: "Step 11", label: "Step 11" },
    { value: "Step 12", label: "Step 12" },
    { value: "Step 13", label: "Step 13" },
    { value: "Step 14", label: "Step 14" },
    { value: "Step 15", label: "Step 15" },
    { value: "Step 16", label: "Step 16" },
    { value: "Step 17", label: "Step 17" },
    { value: "Consolidated", label: "Consolidated" }
  ];

  const isPS_HR = () => {
    if (props?.authStatus === 'ps-hr') {
      return true
    }
    else {
      return false
    }
  }

  const canEdit = isPS_HR()

  console.log(currentCadre, firstAppCadre, mda, props.payload)
  return (
    <div className="form-step" data-step="3">

      <div className="m-b-20">
        <label >Date of First Appointment into the Service</label>
        <DatePicker className="input-field p-r-5 p-l-5"
          selected={selectedDate}
          dateFormat="dd/MM/yyyy"
          isClearable={props?.review === false}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={200}
          placeholderText="Date of First Appointment into the Service"
          disabled={props?.authStatus !== 'ps-hr'}

          required
          value={formData?.dateOfFirstApp}
          onChange={(value) => handleDateChange("dateOfFirstApp", value)}
        />
      </div>
      <div className="m-b-20">
        <label >Personal File No</label>
        <InputField
          placeholder='Personal File No'
          required
          disabled={props?.authStatus !== 'ps-hr'}

          onChange={(value) => handleInputChange("fileNumber", value)}
          value={formData?.fileNumber}

        />
      </div>
      <div className="m-b-20">
        <div className="col-7 no-padding no-margin">
          <label >Salary Grade Level of First Appointment</label>
          <Dropdown
            options={jobLevels}
            placeholder='Select Salary Grade Level of First Appointment'
            disabled={props?.authStatus !== 'ps-hr'}

            required
            onChange={(value) => handleInputChange("gradeOfFirstApp", value)}
            selectedValue={formData?.gradeOfFirstApp}

          />
        </div>
        <div className="col-5 no-p-r no-margin">
          <label >Step on First Appointment</label>
          <Dropdown
            options={steps}
            placeholder='Select Step'
            required
            onChange={(value) => handleInputChange("stepOfFirstApp", value)}
            disabled={props?.authStatus !== 'ps-hr'}

            selectedValue={formData?.stepOfFirstApp}
          />
        </div>
      </div>
      {props?.authStatus === 'ps-hr' &&
        <div className="m-b-20">
          <label >Cadre on First Appointment</label>
          <SelectInput
            options={roleOptions}
            placeholder='Select Cadre on First Appointment'
            className="dropdown-input"
            required
            onChange={(value) => handleSelectInput("firstAppCadre", value)}
            value={firstAppCadre || formData?.firstAppCadre}
            onInputChange={(value) => handleSelectInputChange("firstAppCadre", value)}
            labelKey="name"
            valueKey="id"
          />
        </div>
      }
      <div className="m-b-20">
        <div className="col-5 no-padding no-margin">
          <label >Staff Id</label>
          <InputField
            placeholder='Employee No'
            required
            onChange={(value) => handleInputChange("staffID", value)}
            disabled={props?.authStatus !== 'ps-hr'}

            value={formData?.staffID}
          />
        </div>
        <div className="col-7 no-p-r no-margin">
          <label >Date of Confirmation of Appointment(When Available)</label>
          <DatePicker className="input-field p-r-5 p-l-5"
            selected={connfirmation}
            dateFormat="dd/MM/yyyy"
            isClearable={props?.review === false}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={200}
            placeholderText="Date of Confirmation of Appointment"
            disabled={props?.authStatus !== 'ps-hr'}

            required
            onChange={(value) => handleDateChange("dateOfConfirmation", value)}
            value={formData?.dateOfConfirmation}
          />
        </div>
      </div>
      <div className="m-b-20">
        {props?.authStatus === 'ps-hr' &&

          <div className="col-7 no-padding no-margin">
            <label >Present Substantive Position</label>
            <SelectInput
              options={roleOptions}
              placeholder='Select Present Substantive Position'
              className="dropdown-input"
              required
              onChange={(value) => handleSelectInput("currentCadre", value)}
              disabled={!canEdit}
              value={currentCadre || formData?.currentCadre}
              onInputChange={(value) => handleSelectInputChange("currentCadre", value)}
              labelKey="name"
              valueKey="id"
            />
          </div>
        }
        {props?.authStatus === 'ps-hr' &&

          <div className="col-5 no-p-r no-margin">
            <label >Grade Level(Current)</label>
            <Dropdown
              options={jobLevels}
              placeholder='Grade Level'
              disabled={props?.authStatus !== 'ps-hr'}

              required
              onChange={(value) => handleInputChange("currentLevel", value)}
              selectedValue={formData?.currentLevel}

            />
          </div>

        }

      </div>
      {props?.authStatus === 'ps-hr' &&

        <div className="m-b-20">
          <label >Current Step</label>
          <Dropdown
            options={steps}
            placeholder='Select Step'
            disabled={props?.authStatus !== 'ps-hr'}

            required
            onChange={(value) => handleInputChange("currentStep", value)}
            selectedValue={formData?.currentStep}

          />
        </div>

      }
      <div className="m-b-20">
        <label >Date of Present Substantive Position</label>
        <DatePicker className="input-field p-r-5 p-l-5"
          selected={datePresent}
          dateFormat="dd/MM/yyyy"
          isClearable={props?.review === false}
          placeholderText="Date of Present Substantive Position"
          onChange={(value) => handleDateChange("dateOfPresentPosition", value)}
          value={formData?.dateOfPresentPosition}
          disabled={props?.authStatus !== 'ps-hr'}

          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={200}
          required
        />
      </div>
      {props?.authStatus === 'ps-hr' &&

        <div className="m-b-20">
          <label >Present MDA of Posting</label>
          <SelectInput
            options={mdaOptions}
            placeholder='Select Present MDA of Posting'
            required
            value={presMda || mda || { value: formData?.mdaId?.id, label: formData?.mdaId?.name }}
            onChange={(value) => handleSelectInput("mdaId", value)}
            className="dropdown-input"
            labelKey="name"
            disabled={!canEdit}

            onInputChange={(value) => handleSelectInputChange("mdaId", value)}
            valueKey="id"
          />
        </div>

      }
      <div className="m-b-20">
        <label >Salary Account Number</label>
        <InputField
          placeholder='Salary Account Number'
          disabled={props?.authStatus !== 'ps-hr'}
          required

          onChange={(value) => handleInputChange("salaryAccountNumber", value)}
          value={formData?.salaryAccountNumber}

        />
      </div>
      {props?.authStatus === 'ps-hr' &&

        <div className="m-b-20">
          <label >Bank</label>
          <SelectInput
            options={Banks}
            placeholder='Select'
            required
            value={Bank || formData?.bankId}
            onChange={(value) => handleInputChange("bankId", value)}
            className="dropdown-input"
            labelKey="name"
            disabled={!canEdit}

            valueKey="id"
          />
        </div>

      }
      {props?.authStatus === 'ps-hr' &&

        <div className="m-b-20">
          <label >Salary Structure Type</label>
          <SelectInput
            options={salaryStructureTypeList}
            placeholder='Select '
            required
            value={SalaryStructureType}
            onChange={(value) => handleInputChange("salaryStructureByTypeId", value)}
            className="dropdown-input"
            labelKey="name"
            disabled={!canEdit}

            valueKey="id"
          />
        </div>

      }

      {props?.authStatus === 'ps-hr' &&

        <div className="m-b-20">
          <label >Salary Structure (Level & Step)</label>
          <SelectInput
            options={SalaryStructureList}
            placeholder='Select '
            required
            value={SalaryStructure || formData?.salaryStructureId}
            onChange={(value) => handleInputChange("salaryStructureId", value)}
            className="dropdown-input"
            labelKey="name"
            disabled={!canEdit}

            valueKey="id"
          />
        </div>

      }
      <div className="m-b-20">
        <label >Personal Email</label>
        <InputField
          placeholder='Personal Email'
          required
          onChange={(value) => handleInputChange("email", value)}
          disabled={props?.disabled}

          value={formData?.email}
          type="email"
        />
      </div>
      <div className="m-b-20">
        <label >Official Email (EDSG Email)</label>
        <InputField
          placeholder='Official Email (EDSG Email)'
          required
          disabled={props?.authStatus !== 'ps-hr'}

          onChange={(value) => handleInputChange("officialEmail", value)}
          value={formData?.officialEmail}
          type="email"

        />
      </div>

      <div className="flex space-between">
        <button className="col-5 submit-btn prev-step" onClick={props?.handleBack}>Previous</button>
        <button className="col-5  submit-btn next-step" onClick={handleSubmit}>Next</button>
      </div>

    </div>

  )
}


export default EmploymentRecordsEdit;