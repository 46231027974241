import React from "react";

const DeleteConfirmationModal = ({ closeModal, confirmDelete }) => {
  return (
    <div className="modal ">
      <div className="modal-contents  ">
        <span className="close m-b-10" onClick={closeModal}>&times;</span>
        <h2 className="m-t-20">Confirm Deletion</h2>
        <p>Are you sure you want to delete this record?</p>
        <div className="col-2  m-t-10 flex space-between center-text">
          <button className="btn" onClick={confirmDelete}>Yes</button>
          <button className="btn m-l-10" onClick={closeModal}>No</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
