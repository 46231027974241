import { useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextAreaInputField from "../inputs/TextAreaInputField";
import { get, put, del } from "../../utility/fetch";
import { feedback } from "../modal/feedback";
import UpdateDependant from "./UpdateDependant";
import UpdateDependantEdit from "./UpdateDependantEdit";

const DisplayDependantEdit = ({ userId, setDepRecord, depRecord, index, disabled, review, handleFormData, canEdit }) => {
  const [updateForm, setUpdateForm] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [mobile, setMobile] = useState('');

  useEffect(() => {
    if (Array.isArray(depRecord)) {
      if (depRecord?.length > 0) {
        setSelectedDate(depRecord[0]?.depDoB ? new Date(depRecord[0]?.depDoB) : null);
        setUpdateForm({ ...depRecord[0] });
      } else {
        setUpdateForm([]);
      }
    } else {
      if (depRecord) {
        setSelectedDate(depRecord?.depDoB ? new Date(depRecord?.depDoB) : null);
        setUpdateForm({ ...depRecord });
      } else {
        setUpdateForm({});
      }
    }
  }, [depRecord]);
  

  const toggleEdit = (e) => {
    e.preventDefault();
    setUpdateForm({ ...depRecord });
  };

  const openModal = (id) => {
    setSelectedRecordId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isFormValid = () => {
    // Check if all required fields are filled
    const isValid = Object.entries(updateForm).every(([fieldName, value]) => {
      console.log(`Field: ${fieldName}, Value: ${value}, Type: ${typeof value}`);
      if (fieldName === "depMobile" ) {
        // Validate nin and mobile fields with exactly 11 digits
        if (/^\d{11}$/.test(value)) {
          return true;
        } else {
          feedback({
            title: "Failed",
            text: "Please enter a valid phone number",
            iconType: "error",
          });
          return false;
        }
      }
      return true;
    });
    console.log("Form validity:", isValid);
    return isValid;
  };

  console.log(updateForm, depRecord)

  const updateRecord = async (e, id) => {
    e.preventDefault();
    try {
      const response = await put(`dependants/${id}`, updateForm);
      setDepRecord(response?.data);
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
      closeModal();
    } catch (error) {
      feedback({
        title: "Failed",
        text: error.message,
        iconType: "error",
      });
    }
  };

  const deleteRecord = async (e, id) => {
    e.preventDefault();
    try {
      const response = await del(`dependants/${id}`);
      setDepRecord({});
      setUpdateForm({})
      feedback({
        title: "Success",
        text: response?.message,
        iconType: "success",
      });
    } catch (error) {
      feedback({
        title: "Failed",
        text: error.message,
        iconType: "error",
      });
    }
  };

  const handleDateChangeUpdate = (field, date) => {
    const formattedDateWithoutTimestamp = date ? new Date(date).toLocaleDateString() : "";
    setUpdateForm((prevData) => ({
      ...prevData,
      depDoB: formattedDateWithoutTimestamp,
    }));
    setSelectedDate(date);
    handleFormData(updateForm);
    console.log("Formatted Date without Timestamp:", formattedDateWithoutTimestamp);
  };

  const handleUpdateChange = (field, value) => {
    const selectedValue = value && value.target ? value.target.value : value;
    if (field === "depMobile") {
      if (!isNaN(selectedValue) && selectedValue.length <= 11) {
        console.log("mobile", selectedValue);
        setUpdateForm((prevData) => ({
          ...prevData,
          [field]: selectedValue,
        }));
        setMobile(selectedValue);
      } else {
        console.error("Invalid input for depMobile");
        feedback({
          title: "Failed",
          text: "Invalid input for Mobile Number",
          iconType: "error",
        });
      }
    } else {
      setUpdateForm((prevData) => ({
        ...prevData,
        [field]: selectedValue,
      }));
    }
    console.log("Formatted Date without Timestamp:", selectedValue);

    handleFormData(updateForm);
  };

  const relationship = [
    { value: "Brother", label: "Brother" },
    { value: "Cousin", label: "Cousin" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Father in law", label: "Father in law" },
    { value: "Mother", label: "Mother" },
    { value: "Mother in law", label: "Mother in law" },
    { value: "Nephew", label: "Nephew" },
    { value: "Niece", label: "Niece" },
    { value: "Sister", label: "Sister" },
    { value: "Son", label: "Son" },
    { value: "Spouse", label: "Spouse" },
    { value: "Uncle", label: "Uncle" },
    { value: "Granddaughter", label: "Granddaughter" },
    { value: "Grandson", label: "Grandson" },
    { value: "Grandfather", label: "Grandfather" },
    { value: "Grandmother", label: "Grandmother" },
  ];

  return (
    <div>
      {Array.isArray(depRecord) ? (
        <div className="qualification-form m-t-30">
          <div className="m-b-20 m-t-30">
            <label>Name</label>
            <InputField
              placeholder="Name"
              required
              value={depRecord?.length > 0 ? depRecord[0]?.depName : ''}
              onChange={(value) => handleUpdateChange("depName", value)}
              disabled={disabled}
            />
          </div>
      
          <div className="m-b-20">
            <label>Address</label>
            <InputField
              placeholder="Address"
              onChange={(value) => handleUpdateChange("depAddress", value)}
              required
              value={depRecord?.length > 0 ? depRecord[0]?.depAddress : ''}
              disabled={disabled}
            />
          </div>
      
          <div className="m-b-20">
            <div className="col-7 no-padding no-margin">
              <label>Relationship</label>
              <InputField
                placeholder="Select Relationship"
                onChange={(value) => handleUpdateChange("depRelation", value)}
                required
                value={depRecord?.length > 0 ? depRecord[0]?.depRelation : ''}
                disabled={disabled}
              />
            </div>
            <div className="col-5 no-p-r no-margin">
              <label>Date Of Birth</label>
              <DatePicker
                className="input-field p-r-5 p-l-5"
                placeholderText="Date Of Birth"
                selected={selectedDate}
                required
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown={true}
                onChange={(date) => handleDateChangeUpdate("depDoB", date)}
                scrollableYearDropdown
                yearDropdownItemNumber={200}
                value={depRecord?.length > 0 ? depRecord[0]?.depDoB : ''}
                disabled={disabled}
              />
            </div>
          </div>
      
          <div className="m-b-20">
            <label>Phone Number</label>
            <InputField
              placeholder="Phone Number"
              required
              value={depRecord?.length > 0 ? depRecord[0]?.depMobile : ''}
              disabled={disabled}
              onChange={(value) => handleUpdateChange("depMobile", value)}
            />
          </div>
          {canEdit && (
            <div>
              <div className="flex space-between">
                <button className="btn m-t-20 m-b-20" onClick={(e) => openModal(depRecord[0]?.id)}>
                  Edit
                </button>
                <button className="edit-btn m-t-20 m-b-20" onClick={(e) => deleteRecord(e, depRecord[0]?.id)}>
                  Remove Dependant
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="qualification-form m-t-30">
          <div className="m-b-20 m-t-30">
            <label>Name</label>
            <InputField
              placeholder="Name"
              required
              value={depRecord?.depName || ''}
              onChange={(value) => handleUpdateChange("depName", value)}
              disabled={disabled}
            />
          </div>
  
          <div className="m-b-20">
            <label>Address</label>
            <InputField
              placeholder="Address"
              onChange={(value) => handleUpdateChange("depAddress", value)}
              required
              value={depRecord?.depAddress || ''}
              disabled={disabled}
            />
          </div>
  
          <div className="m-b-20">
            <div className="col-7 no-padding no-margin">
              <label>Relationship</label>
              <InputField
                placeholder="Select Relationship"
                onChange={(value) => handleUpdateChange("depRelation", value)}
                required
                value={depRecord?.depRelation || ''}
                disabled={disabled}
              />
            </div>
            <div className="col-5 no-p-r no-margin">
              <label>Date Of Birth</label>
              <DatePicker
                className="input-field p-r-5 p-l-5"
                placeholderText="Date Of Birth"
                selected={selectedDate}
                required
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown={true}
                onChange={(date) => handleDateChangeUpdate("depDoB", date)}
                scrollableYearDropdown
                yearDropdownItemNumber={200}
                value={depRecord?.depDoB || null}
                disabled={disabled}
              />
            </div>
          </div>
  
          <div className="m-b-20">
            <label>Phone Number</label>
            <InputField
              placeholder="Phone Number"
              required
              value={depRecord?.depMobile || ''}
              disabled={disabled}
              onChange={(value) => handleUpdateChange("depMobile", value)}
            />
          </div>
  
          {canEdit && (
            <div>
              <div className="flex space-between">
                <button className="btn m-t-20 m-b-20" onClick={(e) => openModal(depRecord?.id)}>
                  Edit
                </button>
                <button className="edit-btn m-t-20 m-b-20" onClick={(e) => deleteRecord(e, depRecord?.id)}>
                  Remove Dependant
                </button>
              </div>
            </div>
          )}
        </div>
      )}
  
      {isModalOpen && (
        <div>
          <UpdateDependantEdit
            isOpen={isModalOpen}
            onClose={closeModal}
            formState={updateForm}
            relationship={relationship}
            isFormValid={isFormValid}
            handleUpdateChange={handleUpdateChange}
            handleDateChangeUpdate={handleDateChangeUpdate}
            updateRecord={updateRecord}
            id={selectedRecordId}
          />
        </div>
      )}
    </div>
  );
  };

export default DisplayDependantEdit;
